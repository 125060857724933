<template>
  <div class="titleBox">
    <div>{{ title }}</div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";

export default {
  props: ["title"],
  setup() {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
    });
    onMounted(() => {
      _this.chooseCss();
    });
    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.titleBox {
  flex-shrink: 0;
  margin-top: 20px;
  height: 16px;
  background: linear-gradient(to right, rgba(69, 122, 255, 0.2) 0% ,rgba(69, 122, 255, 0) 100%);
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: rgba(34, 226, 255, 1);
  position: relative;
  div {
    position: absolute;
    left: 9px;
    top: -2.5px;
  }
}
.titleBox::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 4px;
  height: 100%;
  background: rgba(34, 226, 255, 1);
}
</style>
