<template>
  <div class="other_info">
    <div class="lan duan">
      <div class="intr">
        <div>学历：</div>
        <div>{{ list.education || '--' }}</div>
      </div>
      <div class="intr">
        <div>毕业院校：</div>
        <div>{{ list.graduatedSchool || '--' }}</div>
      </div>
    </div>
    <div class="lan duan">
      <div class="intr">
        <div>职业类别：</div>
        <div>{{ list.jobType || '--' }}</div>
      </div>
    </div>
    <div class="lan duan">
      <div class="intr">
        <div>单位名称：</div>
        <div>{{ list.companyName || '--' }}</div>
      </div>
    </div>
    <div class="lan duan">
      <div class="intr">
        <div>单位地址：</div>
        <div>{{ list.jobAddress || '--' }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted, reactive, toRefs
} from "vue";

export default {
  props: ['list'],
  setup() {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
    });
    onMounted(() => {
      _this.chooseCss()
    });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
.intr {
  width: fit-content;
  margin-right: 35px;
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 24px;

  div:nth-child(1) {
    color: var(--ssintr);
    flex-shrink: 0;
  }

  div:nth-child(2) {
    color: var(--ssallInfo);
  }
}

.duan {
  display: flex;
}

.other_info {
  position: relative;
  z-index: 9;
  margin-top: 13px;

  >div {
    line-height: 25px;
    box-sizing: border-box;
  }
}
</style>