<template>
  <div class="zhezhao">
    <div class="allContent">
      <div class="pptBtn" v-if="info.ppt" @click="checkPpt">查看附件</div>
      <img class="box_op_back" src="@/assets/static/1111.png" />
      <img class="box_op_clear" @click="clearinfo()" src="@/assets/images/d6.png" />
      <div class="borderLine">
        <div class="contentTitle">{{ info.title }}</div>
        <div class="descText">{{ info.releaseTime || info.createTime }} &nbsp;&nbsp; {{ info.name || info.publisher }}
        </div>
        <el-scrollbar v-if="state.isMounted" class="box_op_content_data" :always="true">
          <div v-html="info.content || ''"></div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>



<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
  reactive,
  computed,
} from "vue";

export default {
  props: ["info"],
  setup(props,context) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
      isMounted: false,
    });
    const clearinfo = () => {
      _this.$store.commit("index/set", { val: false, key: "detailshow" });
    };

    const checkPpt = () => {
      _this.$store.commit("index/set", { val: 'https://view.officeapps.live.com/op/view.aspx?src=' + props.info.ppt, key: "pptUrl" });
    }

    onMounted(() => {
      _this.chooseCss()
      _this.$nextTick(() => {
        state.isMounted = true
      })
    });
    return {
      state,
      clearinfo,
      checkPpt
    };
  },
};
</script>

<style lang="scss" scoped>
.pptBtn {
  width: 80px;
  height: 24px;
  background: var(--farmers-number-type-label);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  color: var(--servicesPin-typeBtn1);
  border: 0;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  text-align: center;
  line-height: 24px;
}

.zhezhao {
  position: fixed;
  left: 0;
  top: 0;
  background: var(--zhezhao);
  z-index: 16;
  width: 100vw;
  height: 100vh;
}

.allContent {
  width: 800px;
  height: 840px;
  background: var(--servicesPin-typeBtn1);
  border: 1px solid var(--allContent);
  border-radius: 5px;
  position: absolute;
  left: 50%;
  top: 160px;
  z-index: 12;
  transform: translate(-50%, 0);

  .box_op_back {
    position: absolute;
    left: -34px;
    top: -55px;
  }

  .borderLine {
    width: 790px;
    height: 830px;
    border: 1px solid var(--borderLine);
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .contentTitle {
      font-size: 36px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: var(--contentTitle);
      margin-top: 25px;
      padding: 0 40px;
    }

    .descText {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: var(--ssintr);
      line-height: 18px;
      margin-top: 25px;
    }

    .box_op_content_data {
      flex-grow: 1;
      overflow: hidden;
      box-sizing: border-box;
      padding: 20px;

      div {
        font-size: 14px;
        color: var(--box_op_content_data);
      }

      // img {
      //   width: 100%;

      // }
      img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }

  .box_op_clear {
    width: 40px;
    height: 40px;
    object-fit: contain;
    position: absolute;
    right: -20px;
    top: -20px;
    cursor: pointer;
  }
}
</style>