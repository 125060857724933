<template>
  <swiper v-if="type == '默认' && ((videos && videos.length > 0) || (images && images.length > 0))" :slides-per-view="1"
    :space-between="0" class="moren-swiper" @swiper="onSwiper" @slideChange="onSlideChange"
    :pagination="{ clickable: true }" :navigation="{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }">
    <swiper-slide v-for="(val, key) in videos" :key="key + 'Swiper'">
      <video-player :playsinline="true" :options="onOpen(val)" class="video-content video-player vjs-custom-skin">
      </video-player>
    </swiper-slide>
    <swiper-slide v-for="(val, key) in images" :key="key + 'Swiper'">
      <img :src="val" />
    </swiper-slide>

    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
  <swiper class="leadershipCare_asd" v-if="type == '列表' && images && images.length > 0" :slides-per-view="'auto'"
    :space-between="30" :autoplay="{
      delay: 1000,
    }" @swiper="onSwiper" @slideChange="onSlideChange" @mouseenter="mouseenterAuto" @mouseleave="mouseleaveAuto">
    <swiper-slide style="width:auto;height: 100%;" v-for="(val, key) in images" :key="key + 'Swiper'">
      <img :src="(val.indexOf('http') != -1 ? val : + val) +
        '?imageView2/2/h/500'
        " @click="() => {
    $store.commit('index/set', {
      key: 'viewer',
      val: {
        list: images,
        key: key,
      },
    });
  }
    " />
    </swiper-slide>
  </swiper>
  
  <swiper v-if="type == '自动' && ((videos && videos.length > 0) || (images && images.length > 0))"
    :slides-per-view="perview" :space-between="10" class="moren-swiper" :autoplay="true"  @swiper="onSwiper"
    @slideChange="onSlideChange" :pagination="{ clickable: true }">
    <swiper-slide v-for="(val, key) in videos" :key="key + 'Swiper'">
      <video-player :playsinline="true" :options="onOpen(val)" class="video-content video-player vjs-custom-skin">
      </video-player>
    </swiper-slide>

    <swiper-slide v-for="(val, key) in images" :key="key + 'Swiper'">
      <img :src="val" />
    </swiper-slide>
  </swiper>
  <swiper v-if="type == '视频图片' && perview && ((videos && videos.length > 0) || (images && images.length > 0))"
    :slides-per-view="perview" :space-between="10" class="moren-swiper" :autoplay="true"  @swiper="onSwiper"
    @slideChange="onSlideChange" :pagination="{ clickable: true }">
    <swiper-slide v-for="(val, key) in images" :key="key + 'Swiper'" >
      <img v-if="val.indexOf('offset') == -1" @click="setVideoUrl(val)" :src="val" />
      <img v-else :src="val" />
      <img @click="setVideoUrl(val.split('?vframe/jpg/offset/1')[0])" class="playBtn" v-if="val.indexOf('offset') != -1" src="@/assets/dy/播放.png" alt="" srcset="">
    </swiper-slide>
  </swiper>

  <swiper v-if="type == '图片' && (images && images.length > 0)" :slidesPerView="perview" :space-between="10"
    class="moren-swiper" :autoplay='{
      "delay": 2500,
      "disableOnInteraction": false
    }' :loop="true" @swiper="onSwiper" @slideChange="onSlideChange" :pagination="{ clickable: true }">
    <swiper-slide v-for="(val, key) in images" :key="key + 'Swiper'">
      <img :src="val" />

    </swiper-slide>
  </swiper>
</template>

<script>
// Swiper
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
//这里导入对应样式
import "swiper/components/pagination/pagination.scss";
import { handleError } from "vue";
//导入后在SwiperCore.use([])里注册
SwiperCore.use([Pagination, Navigation, Autoplay]);

export default {
  name: "SwiperImage",
  components: { Swiper, SwiperSlide },
  props: {
    images: {
      type: Array,
      default: [],
    },
    videos: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: "默认",
    },
    perview: {
      default: "1",
    },
    autoplay: {
      default: false,
    },

  },
  data() {
    return {
      swiper: false,
    };
  },
  // watch: {
  //   autoplay: {
  //     handler(newVal, oldVal) {

  //       console.log(newVal, this.type)
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },

  methods: {

    setVideoUrl(url){
      this.$emit('setVideoUrl',url)
    },  
    mouseenterAuto() {
      // alert('mouseenterAuto');
      this.swiper.autoplay.start();
    },
    mouseleaveAuto() {
      // alert('mouseleaveAuto');
      this.swiper.autoplay.stop();
    },
    onSwiper(swiper) {
      console.log('onSwiper');
      this.swiper = swiper;
      if (!this.autoplay) {
        this.swiper.autoplay.stop();
      }

    },
    onSlideChange() {
      console.log('slide change');
    },
    onOpen(url) {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        // techOrder: ["flash"],
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: url,
          },
        ],
        //你的封面地址
        poster: "",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.playBtn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 42px !important;
  height: 42px !important;
}

.moren-swiper {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.leadershipCare_asd {
  width: 100%;
  height: 290px;
  box-sizing: border-box;
  padding: 10px 0;

  img {
    width: auto;
    height: 100%;
    // object-fit: contain;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  z-index: 9;
  background: url("../assets/images/箭头.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  opacity: 0.7;
}

.swiper-button-prev {
  left: 20px;
  transform: translate(0, -50%);
}

.swiper-button-next {
  right: 20px;
  transform: translate(0, -50%) rotateY(180deg);
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-disabled {
  opacity: 0.7 !important;
  cursor: not-allowed;
}
</style>
