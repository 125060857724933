<template>
  <div class="navigation"
    :style="navradio == 4 ? 'bottom: 20px;top: unset;left: 50%;transform: translate(-50%, 0px);width:auto;' : ''">
    <!-- <img class="positionImg" src="@/assets/images/a06.png" alt="" /> -->
    <Icons name="hf-5" class="positionImg" />
    <div class="posi" :style="navs.length > 2 ? '' : 'direction: unset;'">
      <span v-for="(nav, idx) in navs.length > 2
        ? navs.slice().reverse()
        : navs.slice()" :key="idx + nav" class="navSpan" :style="
    (navs.length > 2 && idx == 0) ||
      (navs.length <= 2 && idx == navs.length - 1)
      ? 'color:#cbede9'
      : ''
  ">{{ nav.label }}
        <span v-if="idx != navs.length - 1">{{ navs.length > 2 ? "<" : ">" }} </span>
        </span>
    </div>
    <img class="returnImg" v-if="navs.length > 1" @click="returnTo()" src="@/assets/images/a07.png" alt="" />
    <!-- <div class="searchBox" @click="switchListType()">
      <img
        :src="
          $route.path == '/CockpitFir'
            ? require('@/assets/images/a02.png')
            : require('@/assets/images/a04.png')
        "
        alt=""
      />
    </div> -->
  </div>
</template>

<script>
// VUE
import { reactive, getCurrentInstance, onMounted, computed } from "vue";
import Icons from "./Icons";
export default {
  components: {
    Icons,
  },
  name: "Navigation",
  computed: {
    listType() {
      return this.$store.state.index.listType;
    },
    navradio() {
      return this.$store.state.index.navradio;
    },
  },
  data() {
    return {
      returnImg: require("@/assets/images/a07.png"),
    };
  },
  props: ["navs"],
  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    let state = reactive({}); // 数据

    const returnNav = (val) => {
      context.emit("returnNav", val);
    };
    const returnTo = () => {
      context.emit("returnTo", true);
    };
    const switchListType = () => {
      if (_this.$route.path == "/CockpitFir") {
        _this.$router.push("/DeepGrid");
      }
      if (_this.$route.path == "/DeepGrid") {
        _this.$router.push("/CockpitFir");
      }
    };

    onMounted(() => {
      _this.chooseCss()
    });

    return {
      // 变量
      state,

      // 方法
      returnNav,
      switchListType,
      returnTo,
    };
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  position: fixed;
  left: 20px;
  top: 30px;
  height: 40px;
  width: 360px;
  padding: 10px 20px;
  box-sizing: border-box;
  z-index: 25;
  border: 1px solid var(--navigation);
  background: linear-gradient(90deg,
      var(--navigation1) 0%,
      var(--navigation2) 100%);

  // opacity: 0.75;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: var(--ssname);
  cursor: pointer;

  // transition: all 0.3;
  .posi {
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;
    // .navSpan:nth-child(1) {
    //   color: #cbede9;
    // }
  }

  .positionImg {
    width: 17px;
    object-fit: contain;
    margin-right: 10px;
    color: rgba(34, 226, 255, 1);
  }

  .returnImg {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }

  .searchBox {
    width: 44px;
    height: 44px;
    border: 1px solid var(--ssrtCiecle);
    background: linear-gradient(0deg,
        var(--searchBox) 0%,
        var(--ssrtCiecle) 100%);
    box-shadow: 0px 10px 40px 0px var(--infolist2);
    // opacity: 0.5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -70px;
  }
}
</style>
