<template>
  <section>
    <div class="left-background"></div>
    <div class="right-background"></div>
   
  </section>
</template>

<script>
export default {
  name: "Background",
  data() {
    return {};
  },
  computed: {
    left() {
      return this.$store.state.index.left;
    },
    right() {
      return this.$store.state.index.right;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>

.left-background,
.right-background {
  position: absolute;
  top: 0;
  width: 500px;
  height: 100vh;
  pointer-events: none;
  z-index: 5;
  transition: background-image 0.3s ease-in;
  pointer-events: none;
  transition: all 0.3s;
  background: linear-gradient(
    90deg,
    var(--ssleftBack1) 0%,
    var(--ssleftBack2) 100%
  );
  // background: linear-gradient(90deg, rgba(12, 25, 39, 1) 0%, rgba(12, 25, 39, 0.7) 50%,rgba(12, 25, 39, 0) 100%);
}

.left-background {
  left: 0;
}

.right-background {
  right: 0;
  transform: rotateY(180deg);
}

.left-lineback,
.right-lineback {
  position: fixed;
  bottom: 0;
  height: 100vh;
  width: 748px;
  z-index: 6;
  pointer-events: none;
  opacity: 0.5;
}

.left-lineback {
  left: 960px;
  transform: translate(-110%, 0);
}

.right-lineback {
  right: 960px;
  transform: translate(110%, 0) rotateY(180deg);
}

.bottom-background {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 6;
  background-image: url("../../assets//images/b2.png");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  pointer-events: none;
}
</style>
