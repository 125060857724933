<template>
  <div class="oneIcon">
    <!-- <img src="@/assets/static/ttt.png" alt="" /> -->
    <div class="backDiv"></div>
    <div class="TopLine"></div>
    <div class="bottomLine"></div>
    <div class="border2">{{ name }}</div>
    <div class="border1">{{ name }}</div>
  </div>
</template>

<script>
import {  getCurrentInstance,
  onBeforeMount,
  onMounted,reactive, toRefs } from "vue";

export default {
  props: ["name"],
  setup() {
    const state = reactive({
      count: 0,
    });
    onMounted(() => {
            _this.chooseCss()
        });
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
.oneIcon {
  width: 40%;
  height: 40px;
  box-sizing: border-box;
  margin: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .backDiv {
    width: calc(100% - 20px);
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    height: 30px;
    transform: translate(-50%, -50%);
    background: linear-gradient(
      to right,
      var(--ssprogress1) 0%,
      var(--backDiv) 50%,
      var(--ssprogress1) 100%
    );
  }
  .TopLine {
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      to right,
      var(--ssprogress1) 0%,
      var(--backDiv1) 50%,
      var(--ssprogress1) 100%
    );
  }

  .bottomLine {
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to right,
      var(--ssprogress1) 0%,
      var(--backDiv1) 50%,
      var(--ssprogress1) 100%
    );
  }

  img {
    width: 100%;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .border2 {
    width: fit-content;
    height: -moz-fit-content;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: var(--servicesPin-typeBtn1);
    background-image: linear-gradient(to right, var(--border2) 0%, var(--farPeoplePin) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: absolute;
    z-index: 2;
  }
  .border1 {
    width: fit-content;
    height: fit-content;
    font-size: 24px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: var(--servicesPin-typeBtn1);
    -webkit-text-stroke: 2px var(--servicesPin-typeBtn1);
    text-stroke: 2px var(--servicesPin-typeBtn1);
    position: absolute;
    background: linear-gradient(0deg, var(--farPeoplePin) 0%, var(--border2) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>