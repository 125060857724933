<template>
  <div class="DialogBoxTitle">
    <div class="backBox"></div>
    <div class="dialogTitle">{{ title }}</div>
    <div class="bottomLine"></div>
    <div class="houseInfo">
      <div class="oneRow" v-if="menpai">
        <span>门牌数</span>
        <span>{{ menpai }}</span>
      </div>
      <div class="oneRow" v-if="house">
        <span>户数</span>
        <span>{{ house }}</span>
      </div>
      <div class="oneRow" v-if="people">
        <span>人数</span>
        <span>{{ people }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, getCurrentInstance } from "vue";

export default {
  props: ["title", "menpai", "house", "people"],
  setup() {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
    });
    onMounted(() => {
      _this.chooseCss();
    });
    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.DialogBoxTitle {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  .dialogTitle {
    font-size: 36px;
    font-family: YouSheBiaoTiHei;
    font-weight: 400;
    color: rgba(1, 180, 255, 1);
    background: linear-gradient(to right, #22e2ff 0%, #a6ffef 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .backBox {
    width: 380px;
    height: 19px;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    background: var(--linearGradient14);
  }
  .bottomLine {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #22e2ff 0%, #a6ffef 100%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    z-index: -1;
  }
  .houseInfo {
    display: flex;
    margin-right: 50px;
    .oneRow {
      margin-left: 30px;
      display: flex;
      align-items: center;
      span:nth-child(1) {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(86, 119, 130, 1);
      }
      span:nth-child(2) {
        font-size: 30px;
        font-family: Square722;
        font-weight: bold;
        font-style: italic;
        color: rgba(34, 226, 255, 1);
        margin-bottom: 4px;
      }
    }
  }
}
</style>
