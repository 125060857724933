<template>
  <div class="DialogBox">
    <div class="insetBox">
      <div class="kuang"></div>
      <div class="kuang1"></div>
      <div class="kuang2"></div>
      <div class="kuang3"></div>
      <img
        class="closeBtn"
        @click="closeDialog()"
        src="@/assets/images/d6.png"
        alt=""
        srcset=""
      />
      <DialogBoxTitle />
      <div class="addressInfo">
        <div class="addressName">位置：</div>
        <div class="address">某某镇</div>
        <div class="address">-某某社区</div>
        <div class="address">-某某网格</div>
      </div>
      <DetailButtons :address="state.address" />
      <div class="houseAndPeopleInfo">
        <div class="houseInfoDetail">
          <div class="itemTitle">
            房屋信息
            <div class="bottomLine"></div>
          </div>
          <div class="infoDetailBox">
            <DetailTitle title="扩展信息" style="margin-bottom: 20px" />
            <div class="intr">
              <div>省市区县：</div>
              <div>福建省福州市</div>
            </div>
            <div class="intr">
              <div>行政管辖：</div>
              <div>某某街道东昇居委会</div>
            </div>
            <div class="intr">
              <div>社区民警：</div>
              <div>姜超(13799134014)</div>
            </div>
            <div class="intr">
              <div>地址编码：</div>
              <div>BFC58870-4</div>
            </div>
            <DetailTitle title="网格采集房屋信息" style="margin-bottom: 20px" />
            <div class="intr">
              <div>房主姓名：</div>
              <div>福建省福州市</div>
            </div>
            <div class="intr">
              <div>房主电话：</div>
              <div>福建省福州市</div>
            </div>
            <div class="intr">
              <div>房屋类型：</div>
              <div>福建省福州市</div>
            </div>
            <div class="intr">
              <div>住户户数：</div>
              <div>福建省福州市</div>
            </div>
            <div class="intr">
              <div>住户人数：</div>
              <div>福建省福州市</div>
            </div>
            <div class="intr">
              <div>房屋面积：</div>
              <div>福建省福州市</div>
            </div>
            <div class="intr">
              <div>所属街道：</div>
              <div>福建省福州市</div>
            </div>
            <div class="intr">
              <div>网格负责人：</div>
              <div>福建省福州市</div>
            </div>
            <div class="intr">
              <div>房屋编号：</div>
              <div>福建省福州市</div>
            </div>
          </div>
        </div>
        <div class="peopleDetail">
          <div class="itemTitle">
            人员信息
            <div class="bottomLine"></div>
          </div>
          <div class="listAndInfo">
            <div class="infoDetailBox">
              <transition name="el-zoom-in-top">
                <area-and-list
                  @getInfo="getInfo"
                  @getList="getList"
                  @getArea="(val) => (state.area = val)"
                  :area="state.area"
                  :page="state.page"
                  :last_page="state.last_page"
                  :array="state.list"
                  :id="false"
                />
              </transition>
            </div>
            <div class="infoDetailBox">
              <Info
                :titles="['党员', '残疾人员', '低保户']"
                :title="state.title"
                @swaitchTitle="
                  (item) => {
                    state.title = item;
                  }
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive,getCurrentInstance ,onMounted } from "vue";
import DialogBoxTitle from "@/components/DialogBoxTitle";
import DetailButtons from "@/components/DetailButtons";
import DetailTitle from "@/components/DetailTitle";
import AreaAndList from "@/components/AreaAndList";
import Info from "@/components/Info";
export default {
  components: { DialogBoxTitle, DetailButtons, DetailTitle, AreaAndList, Info },
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      address: [
        "东街1号",
        "东街2号",
        "东街3号",
        "东街4号",
        "东街5号",
        "东街6号",
        "东街7号",
      ],
      type: "",
      area: 1,
      page: 1,
      last_page: 10,
      list: [
        {
          id: 1,
          domicileaddress: "金山园聚远景56号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "户主",
          name: "老王",
          gender: "男",
          age: 33,
        },
        {
          id: 2,
          domicileaddress: "金山园聚远景56号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "父亲",
          name: "老王他爹",
          gender: "男",
          age: 33,
        },
        {
          id: 3,
          domicileaddress: "金山园聚远景56号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "儿子",
          name: "老王儿子",
          gender: "男",
          age: 33,
        },
        {
          id: 4,
          domicileaddress: "金山园聚远景57号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "户主",
          name: "老陈",
          gender: "男",
          age: 33,
        },
        {
          id: 5,
          domicileaddress: "金山园聚远景57号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "父亲",
          name: "老陈他爹",
          gender: "男",
          age: 33,
        },
        {
          id: 6,
          domicileaddress: "金山园聚远景57号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "儿子",
          name: "老陈儿子",
          gender: "男",
          age: 33,
        },
        {
          id: 7,
          domicileaddress: "金山园聚远景58号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "户主",
          name: "老陈",
          gender: "男",
          age: 33,
        },
        {
          id: 8,
          domicileaddress: "金山园聚远景58号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "父亲",
          name: "老陈他爹",
          gender: "男",
          age: 33,
        },
        {
          id: 9,
          domicileaddress: "金山园聚远景58号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "儿子",
          name: "老陈儿子",
          gender: "男",
          age: 33,
        },
        {
          id: 10,
          domicileaddress: "金山园聚远景59号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "户主",
          name: "老陈",
          gender: "男",
          age: 33,
        },
        {
          id: 11,
          domicileaddress: "金山园聚远景59号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "父亲",
          name: "老陈他爹",
          gender: "男",
          age: 33,
        },
        {
          id: 12,
          domicileaddress: "金山园聚远景59号",
          avatar:
            "https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg",
          label: "儿子",
          name: "老陈儿子",
          gender: "男",
          age: 33,
        },
      ],
      title: "党员",
    });
    const closeDialog = () => {
      ctx.emit("closeDialog", false);
    };
    onMounted(() => {
            _this.chooseCss()
        });
    return {
      state,
      // 方法
      closeDialog,
    };
  },
};
</script>

<style lang="scss" scoped>
.DialogBox {
  width: 100vh;
  // height: 800px;
  position: fixed;
  background: var(--DialogBox);
  border: 1px solid var(--DialogBox1);
  box-shadow: 0px 20px 50px 0px var(--DialogBox2);
  border-radius: 5px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  padding: 5px;
  box-sizing: border-box;
  .insetBox {
    width: 100%;
    height: 100%;
    border: 1px solid var(--numBox);
    padding: 20px 25px;
    box-sizing: border-box;
    position: relative;
    .kuang {
      width: 30px;
      height: 30px;
      object-fit: contain;
      position: absolute;
      left: -1px;
      top: -1px;
      background-image: url("../assets/images/d4.png");
      background-repeat: no-repeat;
      background-size: contain;
    }
    .kuang1 {
      width: 30px;
      height: 30px;
      object-fit: contain;
      position: absolute;
      right: -1px;
      top: -1px;
      background-image: url("../assets/images/d4.png");
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(90deg);
    }
    .kuang2 {
      width: 30px;
      height: 30px;
      object-fit: contain;
      position: absolute;
      right: -1px;
      bottom: -1px;
      background-image: url("../assets/images/d4.png");
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(180deg);
    }
    .kuang3 {
      width: 30px;
      height: 30px;
      object-fit: contain;
      position: absolute;
      left: -1px;
      bottom: -1px;
      background-image: url("../assets//images/d4.png");
      background-repeat: no-repeat;
      background-size: contain;
      transform: rotate(270deg);
    }
    .closeBtn {  z-index: 2;
      position: absolute;
      width: 30px;
      height: 30px;
      z-index: 5;
      top: -10px;
      right: -25px;
      cursor: pointer;
    }
    .addressInfo {
      margin-top: 20px;
      margin-left: 4px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      .addressName {
        color: var(--ssaddress);
      }
      .address {
        color: var(--ssprogress2);
      }
    }
    .houseAndPeopleInfo {
      width: 100%;
      display: flex;
      margin-top: 20px;
      justify-content: space-between;
      .itemTitle {
        width: 100%;
        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: var(--servicesPin-typeBtn);
        position: relative;
        background: var(--linearGradient13);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        .bottomLine {
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 1px;
          background: var(--linearGradient13);
          z-index: -1;
        }
      }
      .houseInfoDetail {
        width: 320px;
        display: flex;
        flex-direction: column;
        .infoDetailBox {
          margin-top: 9px;
          width: 100%;
          height: 564px;
          background: var(--specialBack);
          border-radius: 5px;
          padding: 0 10px 20px 10px;
          box-sizing: border-box;
        }
        .intr {
          width: fit-content;
          margin-right: 35px;
          display: flex;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 30px;
          div:nth-child(1) {
            color: var(--ssintr);
            flex-shrink: 0;
          }
          div:nth-child(2) {
            color: var(--ssallInfo);
          }
        }
      }
      .peopleDetail {
        margin-left: 20px;
        display: flex;
        flex: 1;
        flex-direction: column;
        .listAndInfo {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .infoDetailBox {
            margin-top: 9px;
            width: 100%;
            height: 564px;
            background: var(--specialBack);
            border-radius: 5px;
            padding: 0 10px 20px 10px;
            box-sizing: border-box;
            display: flex;
            overflow: hidden;
            flex-direction: column;
          }
          .infoDetailBox:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
</style>