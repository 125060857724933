import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import moment from "moment"
// UI
// import ElementPlus from 'element-plus';
// import 'element-plus/lib/theme-chalk/index.css';

import echartsGL from '../node_modules/echarts-gl/dist/echarts-gl.min.js'
import ElementPlus from "element-plus";
import zhLocal from "element-plus/lib/locale/lang/zh-cn";
import 'element-plus/lib/theme-chalk/index.css';
// import "element-plus/theme-chalk/index.css";
// import ZkTable from 'vue-table-with-tree-grid'

import dialogDrag from '@/utils/dialogDrag.js' // 地址就是dialogDrag.js在位置

import Vant from "vant";
import "vant/lib/index.css";

// Video
// import VideoPlayer from "vue-video-player/src";
// import "vue-video-player/src/custom-theme.css";
// import "video.js/dist/video-js.css";
// import "videojs-flash"; //引入才能播放rtmp视屏
// import "videojs-contrib-hls"; //引入才能播放rtmp视屏


import axios from 'axios'
import dataV from '@jiaminghi/data-view'
// DIY CSS
import "@/styles/index.scss";
// DIY Function
import {
    functions
} from "@/utils";

// import waterfall from 'vue-waterfall2'

// import {VueMasonryPlugin} from 'vue-masonry';

const app = createApp(App);
// app.use(VueMasonryPlugin);
app.use(store);
app.use(Vant);
app.use(dialogDrag)
// app.use(ZkTable);
app.use(dataV);
// app.use(VideoPlayer);
app.use(router);
app.use(ElementPlus, {
    locale: zhLocal
});
app.config.globalProperties.$axios = axios
app.config.globalProperties.$moment = moment
app.config.globalProperties.$echartsGL = echartsGL;
app.mount("#app");

for (var key in functions) app.config.globalProperties[key] = functions[key];