<template>
  <div class="infolist grow">
    <el-scrollbar style="max-height: 100%; height: auto; flex-shrink: 0" v-if="state.isMounted">
      <div class="grow">
        <DetailTitle title="基础信息" />
        <EconomicsSpecial
          v-if="state.list"
          :user="state.list"
          :holdShow="holdShow"
        />
        <DetailTitle title="紧急联系人" />
        <Contact v-if="state.list" :list="state.list" />
        <DetailTitle title="扩展信息" />
        <Extend v-if="state.list" :list="state.list" />
        <DetailTitle title="报道党员信息" v-if="$route.path == '/PartyReport'" />
        <ReportInfo v-if="state.list && $route.path == '/PartyReport'" :list="state.list" />
        <DetailTitle title="医疗保健队详情" v-if="$route.path == '/CureAndBring'" />
        <ReportInfo v-if="state.list && $route.path == '/CureAndBring'" :list="state.list" />
        <SpecialTitle
          v-if="state.titles && state.title && $route.path != '/PartyReport'"
          :titles="state.titles"
          :party="party"
          :title="state.title"
          @swaitchTitle="swaitchTitle"
        />
        <div class="allInfo" v-if="state.list && $route.path != '/PartyReport'">
          <div
            class="intr"
            v-for="(item, idx) in state.list.attributeList[state.title]"
            :key="item + idx"
          >
            <div>{{ item.fieldName }}：</div>
            <div>{{ item.fieldValue }}</div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
  reactive,
  computed,
  watch
} from "vue";
import DetailTitle from "./DetailTitle";
import EconomicsSpecial from "./EconomicsSpecial";
import Extend from "./Extend";
import ReportInfo from "./ReportInfo";

import Contact from "./Contact";
import SpecialTitle from "./SpecialTitle";
import { getMemberDetail1 } from "@/api";

export default {
  components: { EconomicsSpecial, DetailTitle, Contact, Extend, SpecialTitle ,ReportInfo},
  props: {
    holdShow: {
      default: true,
      type: Boolean,
    },
    titles: {
      default: [],
      type: Array,
    },
    title: {
      default: "",
      type: String,
    },
    list: {
      default: {},
      type: Object,
    },
    party: {
      default: false,
      type: Boolean,
    },
    typeId:false
  },
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
      list: false,
      title: false,
      titles: false,
      isMounted:false
    });
    watch(
      () => props.list,
      (val) => {
        if (val) {
          state.titles = [];
          let id = val.userId ? val.userId : val.id;
          getMemberDetail1({ id,typeId:props.typeId }).then((res) => {
            state.list = res.data;
            if (res.data.attributeList) {
              for (const key in res.data.attributeList) {
                state.titles.push(key);
              }
              state.title = state.titles[0];
            }
            // console.log(state.titles, state.title);
          });
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    const swaitchTitle = (item) => {
      state.title = item;
      ctx.emit("swaitchTitle", item);
    };
    onMounted(() => {
      _this.chooseCss()
      _this.$nextTick(() => {
        state.isMounted = true
      })
    });
    return {
      state,
      swaitchTitle,
    };
  },
};
</script>

<style lang="scss" scoped>
.infolist {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.allInfo {
  width: 100%;
  display: flex;
  margin-top: 14px;
  flex-direction: column;
}
.intr {
  width: fit-content;
  margin-right: 35px;
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 24px;
  div:nth-child(1) {
    color: var(--ssintr);
    flex-shrink: 0;
  }
  div:nth-child(2) {
    color: var(--ssallInfo);
  }
}
.duan {
  display: flex;
}
.other_info {
  position: relative;
  z-index: 9;
  margin-top: 13px;
  > div {
    line-height: 25px;
    box-sizing: border-box;
  }
}
.specialInfo {
  height: 16px;
  background: var(--specialInfo);
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 16px;
  display: flex;
  margin-top: 30px;
  div:nth-child(1) {
    margin-left: 9px !important;
  }
  .activeText {
    color: var(--oneItem);
    margin-left: 17px;
    cursor: pointer;
  }
  .disActiveText {
    color: var(--topLine1);
    margin-left: 17px;
    cursor: pointer;
  }
}
</style>