<template>
  <!-- 菜单 -->
  <transition name="el-fade-in-linear">
    <keep-alive>
      <LeftMenu
        v-if="
          globalData.menuArr &&
          globalData.menuArr.length > 0 &&
          state.routeArr.includes(path)
        "
      />
    </keep-alive>
  </transition>
  <div class="allBtn reformUav">
    <div
      :class="state.idx == idx ? 'activeItem' : `disItem`"
      v-for="(item, idx) in menu"
      :key="item + idx"
      @click.stop="chooseThis(item, idx)"
      @mouseleave.stop="leaveFun(idx)"
      @mouseenter.stop="enterFun(idx)"
    >
      <span id="xianzhi">{{ item.name }}</span>
      <transition
        name="el-fade-in-linear"
        v-if="item.children && item.children.length > 0 && !item.isSidebar"
      >
        <div
          class="secMenu"
          :style="
            idx == 0
              ? 'left:-10px;'
              : idx == menu.length - 1
              ? 'right:-20px;'
              : 'left:unset;'
          "
          v-if="state.idx == idx"
        >
          <div class="centerMenu">
            <div class="centerMenu">
              <img src="@/assets/menu/ml.png" alt="" />
            </div>
          </div>
          <div
            class="centerBg"
            @click.stop="chooseThisSec(secItem, i, item)"
            v-for="(secItem, i) in item.children"
            :key="i + secItem"
          >
            <div class="secMuneItem" v-if="state.Secidx == secItem.id">
              <div class="activeSpan">{{ secItem.name }}</div>
              <img class="imgFit" src="@/assets/menu/menu11111.png" alt="" />
            </div>
            <div class="secDisItem" v-else>
              <div class="disSpan">{{ secItem.name }}</div>
            </div>
          </div>
          <div class="centerMenu">
            <div class="centerMenu">
              <img class="ry" src="@/assets/menu/mr.png" alt="" />
            </div>
          </div>
          <div class="treBox"></div>
          <img
            :style="idx == 0 ? 'left:10%;' : 'left:50%;'"
            class="circleB"
            src="@/assets/menu/menu1111.png"
            alt=""
          />
        </div>
      </transition>
    </div>
    <!-- <div style="width: 10px;"></div> -->
  </div>
</template>

<script>
import LeftMenu from "./LeftMenu";
import { Throttle, Debounce } from "@/utils";
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
  reactive,
  watch,
  computed,
  inject,
} from "vue";

export default {
  components: {
    LeftMenu,
  },
  setup(props, context) {
    const globalData = inject("globalData");
    const _this = getCurrentInstance().proxy;
    const menu = computed(() => _this.$store.state.index.menu);
    const partyUrl = computed(() => _this.$store.state.index.partyUrl);
    const importantUrl = computed(() => _this.$store.state.index.importantUrl);
    const path = computed(() => _this.$route.path);
    // console.log(menu.value)
    const state = reactive({
      arr: [],
      idx: 0,
      nowidx: 0,
      nowSecidx: 0,
      Secidx: 0,
      historyMenu: [],
      routeArr: [
        "/Overview",
        "/VillageDivision",
        "/NowScenic",
        "/PersonagesRecords",
        "/GrassrootsOrganizations",
        "/Education",
        "/LifeStyle",
        "/ChronicleEvent",
      ],

      mouseStatus: false,
    });
    // watch(
    //   () => state.idx,
    //   (val) => {
    //     console.log(val);
    //   },
    //   { deep: true, immediate: true }
    // );
    const leaveFun = (idx) => {
      if (state.mouseStatus == "leave") return;
      console.log("leaveFunleaveFun", state.idx);
      state.mouseStatus = "leave";
      if (state.nowSecidx == state.Secidx) {
        state.idx = state.nowidx;
      }
    };
    const enterFun = (idx) => {
      if (state.mouseStatus == "enter") return;
      console.log("enterFunenterFun", idx);
      state.mouseStatus = "enter";
      state.nowidx = state.idx;
      state.nowSecidx = state.Secidx;
      state.idx = idx;
    };
    const chooseThis = (item, idx) => {
      // console.log(item, idx)
      state.idx = idx;
      context.emit("setSecMenu", item);
      if (item.isSidebar) {
        state.Secidx = 0;
        _this.$router.push(globalData.menuArr[0].url);
        return;
      }
      _this.$store.commit("index/set", { val: 2, key: "navshow" });
      let flg = false;
      if (state.historyMenu.length > 0) {
        state.historyMenu.forEach((e) => {
          if (e.name == item.name) {
            flg = true;
            item = e;
          }
        });
      }
      if (!flg) {
        state.historyMenu.push(item);
      }

      let flag = false;
      let href = window.location.href;
      if (item.children && item.children.length > 0) {
        item.children.forEach((e, index) => {
          // _this.setCookie('secMenuName', e.secMenu, 7)
          // 刷新时使用 若是菜单里的  转跳菜单路由
          if (e.url && href.indexOf(e.url) != -1) {
            flag = true;
            chooseThisSec(e, index, item);
          }
          // 页面刷新时候方法  是党群的路由  就把菜单设置成党群网格
          if (
            item.name == "党建引领" &&
            (href.indexOf("/PartyMap") != -1 ||
              href.indexOf("/PartyTalent") != -1 ||
              href.indexOf("/RedAlert") != -1 ||
              href.indexOf("/PartyReport") != -1 ||
              href.indexOf("/Alliance") != -1)
          ) {
            if (e.name == "党群网格") {
              flag = true;
              e.visite = true;
              state.Secidx = e.id;
            }
          }
          if (
            item.name == "综治平安" &&
            href.indexOf("/ImportantPlace") != -1
          ) {
            if (e.name == "重点部件场所") {
              flag = true;
              e.visite = true;
              state.Secidx = e.id;
            }
          }
        });
        if (flag) return;

        item.children.forEach((e, index) => {
          if (e.visite == true) {
            flag = true;
            chooseThisSec(e, index, item);
          }
        });
        if (flag) return;

        chooseThisSec(item.children[0], 0, item);
      }
    };
    const chooseThisSec = (secItem, idx, item) => {
      // console.log(secItem, idx, item)
      _this.setCookie("secName", secItem.name);
      item.children.forEach((e, index) => {
        e.visite = false;
      });
      item.children[idx].visite = true;
      // console.log(item)
      state.Secidx = item.children[idx].id;
      _this.$router.push(secItem.url);
    };

    onMounted(() => {
      let href = window.location.href;

      menu.value.forEach((e, idx) => {
        if (e.children && e.children.length > 0 && !e.isSidebar) {
          e.children.forEach((el, i) => {
            if (href.indexOf(el.url) != -1 && el.url) {
              chooseThis(e, idx);
            }
            // if (
            //   href.indexOf("/PartyMap") != -1 ||
            //   href.indexOf("/PartyTalent") != -1 ||
            //   href.indexOf("/RedAlert") != -1 ||
            //   href.indexOf("/PartyReport") != -1 ||
            //   href.indexOf("/Alliance") != -1
            // ) {
            //   if (e.name == "党建引领") {
            //     chooseThis(e, idx);
            //   }
            // }

            if (href.indexOf("/ImportantPlace") != -1) {
              if (e.name == "综治平安") {
                chooseThis(e, idx);
              }
            }
          });
        } else if (e.isSidebar) {
          globalData.menuArr = e.children;
        }
      });
    });
    return {
      state,
      menu,
      path,
      globalData,
      partyUrl,
      importantUrl,
      chooseThis,
      chooseThisSec,
      leaveFun,
      enterFun,
    };
  },
};
</script>

<style lang="scss" scoped>
.treBox {
  width: 100%;
  height: 20px;
  position: absolute;
  top: 33px;
  background: transparent;
  flex-shrink: 0;
}

.allBtn {
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  bottom: 20px;
  z-index: 11;
  transform: translate(-50%, 0%);
  display: flex;
  align-items: center;

  > div {
    margin-right: 10px;
    transition: all 0.3s;
    cursor: pointer;
  }

  * {
    transition: all 0.3s;
  }

  .disItem {
    padding: 0 5px;
    width: 100px;
    height: 32px;
    // background: var(--disItem);
    // border: 1px solid var(--disItem1);
    // border-radius: 10px;
    // transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/menu/menu111.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    span {
      opacity: 0.75;
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: var(--servicesPin-typeBtn1);
      white-space: nowrap;
      // text-shadow: 0px 2px 2px rgba(16, 105, 255, 0.2),
      //   0px 4px 8px rgba(16, 105, 255, 0.4);
      // -webkit-text-stroke: 1px var(--oneTitle);
      // text-stroke: 1px var(--oneTitle);
      background: var(--linearGradient10);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .disItem:hover {
    position: relative;
    padding: 0 5px;
    width: 120px;
    height: 40px;
    // background: var(--disItem);
    // border: 1px solid var(--disItem1);
    // border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/menu/menu11.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    span {
      white-space: nowrap;
      font-size: 30px;
      font-family: 045-SSYiFeiTi;
      font-weight: 400;
      color: var(--servicesPin-typeBtn1);
      // text-shadow: 0px 2px 2px var(--numBox),
      //   0px 4px 8px var(--infolist2);
      // -webkit-text-stroke: 1px var(--oneTitle);
      // text-stroke: 1px var(--oneTitle);
      background: var(--linearGradient11);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .activeItem {
    transition: all 0.3s;
    position: relative;
    padding: 0 5px;
    width: 134px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/menu/menu1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    > span {
      font-size: 30px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: var(--servicesPin-typeBtn1);
      line-height: 41px;
      white-space: nowrap;
      background: var(--linearGradient11);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      padding: 0 5px;
    }
  }

  .secMenu {
    transition: all 0.3s;
    position: absolute;
    top: -48px;
    display: flex;
    align-items: flex-end;

    .ry {
      transform: rotateY(180deg);
    }

    .circleB {
      object-fit: none;
      position: absolute;
      // bottom: -11px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    .centerMenu {
      width: auto;
      height: auto;
    }

    .centerBg {
      width: auto;
      background-image: url("../../assets/menu/mm.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 34px;
      padding: 0 18px;
      box-sizing: border-box;
      margin-bottom: 4px;

      .secMuneItem {
        display: flex;
        flex-direction: column;
        position: relative;
        align-content: center;

        .activeSpan {
          white-space: nowrap;
          font-size: 18px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          color: var(--servicesPin-typeBtn1);
          // text-shadow: 0px 2px 2px var(--numBox), 0px 4px 8px var(--ssrtCiecle);
          // -webkit-text-stroke: 1px #FBC545;
          // text-stroke: 1px #FBC545;

          background: var(--linearGradient11);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .secDisItem {
        display: flex;
        flex-direction: column;

        .disSpan {
          opacity: 0.75;
          white-space: nowrap;
          font-size: 16px;
          font-family: Source Han Sans SC;
          font-weight: bold;
          color: var(--servicesPin-typeBtn1);
          // -webkit-text-stroke: 1px #FBC545;
          // text-stroke: 1px #FBC545;
          background: linear-gradient(
            0deg,
            var(--secDisItem) 0%,
            var(--secDisItem1) 100%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
.space10 {
  width: 10px;
  height: 46px;
  flex-shrink: 0;
}
.imgFit {
  object-fit: none;
}
</style>
