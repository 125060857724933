<template>
  <div class="vl_statistics">
    <div class="bg_user_info">
      <div class="relationBtn" v-if="holdShow && user.hostRelation">{{user.hostRelation}}</div>
      <div class="bg_user_info_data">
        <el-image
          class="bg_user_info_img"
          :src="user.avatar"
          fit="cover"
        ></el-image>
        <div class="bg_user_info_info">
          <div class="bg_user_info_name">
            <div class="bg_user_info_text">
              <div>{{ user.name || "--" }}</div>
              <div>
                <span v-if="user.gender || user.sex">{{
                  user.gender || user.sex || "--"
                }}</span>
                <span v-if="user.age">/</span>
                <span v-if="user.age">{{ user.age || "--" }}岁</span>
                <!-- <span v-if="user.nativePlaceName">/</span>
                <span v-if="user.nativePlaceName">{{
                  user.nativePlaceName || "--"
                }}</span> -->
              </div>
            </div>
          </div>

          <div class="intr">
            <div></div>
            <div v-if="user.contact || user.phone || user.mobile">
              联系电话：{{
                user.contact || user.phone || user.mobile || "--"
              }}
            </div>
          </div>
        </div>
      </div>

      <div class="other_info">
        <div class="lan duan">
          <div class="intr" v-if="user.birthday">
            <div>出生年月：</div>
            <div>{{ user.birthday || "--" }}</div>
          </div>
          <div class="intr" v-if="user.maritalCode">
            <div>婚姻状态：</div>
            <div>{{ user.maritalCode || "--" }}</div>
          </div>
        </div>
        <div class="lan duan">
          <div class="intr" v-if="user.religion">
            <div>宗教信仰：</div>
            <div>{{ user.religion || "--" }}</div>
          </div>
          <div class="intr" v-if="user.political ">
            <div>政治面貌：</div>
            <div>{{ user.political || "--" }}</div>
          </div>
        </div>
        <div class="lan duan">
          <!-- <div class="intr" v-if="user.politicalcode">
            <div>政治面貌：</div>
            <div>{{ user.politicalcode || "--" }}</div>
          </div> -->
          <div class="intr">
            <div>籍贯：</div>
            <div>{{ user.nativePlaceName || "--" }}</div>
          </div>
          <div class="intr">
            <div>备注：</div>
            <div>{{ user.remark || "--" }}</div>
          </div>
        </div>
        <div class="lan duan">
          <div class="intr">
            <div>户籍详址：</div>
            <div>{{ user.hostAddress || "--" }}</div>
          </div>
        </div>
        <div class="lan duan">
          <div class="intr">
            <div>现居详址：</div>
            <div>{{ user.address || "--" }}</div>
          </div>
        </div>
        <div class="lan duan">
          <div class="intr" >
            <div>办公地：</div>
            <div>{{ user.jobAddress || "--" }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
  reactive,
  computed,
} from "vue";
export default {
  props: {
    user: {
      type: Object,
      default: {},
    },
    holdShow: {
      default: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    onMounted(() => {
            _this.chooseCss()
        });
        // mounted() { this.chooseCss() },
    // console.log(props.user);
  },
};
</script>

<style lang="scss" scoped>
.vl_statistics {
  flex-shrink: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.015);
  box-sizing: border-box;
  position: relative;

  .bg_user_info {
    position: relative;
    z-index: 9;
    .relationBtn {
      z-index: 10;
      background: var(--ssrtCiecle);
      border-radius: 5px;
      padding: 0px 8px;
      box-sizing: border-box;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: var(--nameAndInfo1);
      line-height: 24px;
      position: absolute;
      right: 4px;
      top: 10px;
    }
    .bg_user_info_data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;
      height: 80px;
      .bg_user_info_img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 15px;
      }
      .bg_user_info_info {
        flex-grow: 1;
        .bg_user_info_name {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-bottom: 10px;
          .bg_user_info_text {
            display: flex;
            align-items: flex-end;
            div:nth-child(1) {
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: var(--sspeoplename);
              margin-right: 10px;
            }
            div:nth-child(2) {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: var(--ssintr);
            }
          }
        }
      }
    }
  }
  .other_info {
    position: relative;
    z-index: 9;
    // margin-bottom: 20px;
    > div {
      // height: 25px;
      line-height: 25px;
      // padding-left: 15px;
      box-sizing: border-box;
    }
  }
}

.intr {
  width: fit-content;
  margin-right: 35px;
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;

  div:nth-child(1) {
    color: var(--ssintr);
    flex-shrink: 0;
  }
  div:nth-child(2) {
    color: var(--ssallInfo);
  }
}

// .lan:nth-child(odd) {
//   background: rgba(38, 68, 142, 0.1);
// }
.duan {
  display: flex;
  flex-wrap: wrap;
}
</style>
