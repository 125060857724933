<template>
  <router-view />
</template>

<script>
import { provide, reactive, getCurrentInstance, onMounted } from "vue";

import { globalData } from "@/utils/global.js";
export default {
  name: "App",
  setup() {
    const _this = getCurrentInstance().proxy;
    const state = reactive({});

    provide("globalData", globalData); //provide

    // onMounted(() => {
    //   var oDate = new Date(); //创建日期对象
    //   oDate.setDate(oDate.getDate() + 365); //设置过期时间
    //   document.cookie =
    //     "access-token" +
    //     "=" +
    //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiI0NjQ1MDYiLCJyb2xlX25hbWUiOiLkuJzlhrLmnZHlpKflsY_nrqHnkIblkZgiLCJwb3N0X2lkIjoiMTcyNjA3OTU5OTQ4MTMwNzEzNyIsInVzZXJfaWQiOiIxNzI2MTM2OTA1MjQxNzQzMzYxIiwicm9sZV9pZCI6IjE3MjYxMzQyODU3OTgyMzIwNjUiLCJ1c2VyX25hbWUiOiJkY3NxIiwibmlja19uYW1lIjoi5Lic5Yay56S-5Yy6IiwiZGV0YWlsIjp7InR5cGUiOiJ3ZWIifSwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsImRlcHRfaWQiOiIxNzI2MDc5NTk5NDQ3NzUyNzA1IiwiYWNjb3VudCI6ImRjc3EiLCJjbGllbnRfaWQiOiJzYWJlciIsImV4cCI6MTcwODE2MDU4MiwibmJmIjoxNzAwMzg0NTgyfQ.hKCM74CcdjJKcqECipk2xI_FbHWcTuTP3KHZBoJJOfzGr19B1lNphQU85JjCH892V0qg-j0Wk28ymnS7X8cK5Q" +
    //     ";expires=" +
    //     oDate.toGMTString(); //设置cookie的名称，数值，过期时间
    //   let refresh_token =  JSON.stringify({
    //           data: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ1c2VyX2lkIjoiMTcyNjEzNjkwNTI0MTc0MzM2MSIsInJvbGVfaWQiOiIxNzI2MTM0Mjg1Nzk4MjMyMDY1IiwidG9rZW5fdHlwZSI6InJlZnJlc2hfdG9rZW4iLCJkZXB0X2lkIjoiMTcyNjA3OTU5OTQ0Nzc1MjcwNSIsImNsaWVudF9pZCI6InNhYmVyIiwiZXhwIjoxNzE1OTQ1NzEzLCJuYmYiOjE3MDAzOTM3MTN9.quN0ePaeutxyUAPp07WCke3OP1y6rhSqqRT6v5Kv1YXeP4lbg4CRChqq0-cGDrjFid4RDT8j1pOB6EPm2qPEHw',
    //           dataTime: new Date().getTime(),
    //         })
    //   document.cookie =
    //     "refresh-token" +
    //     "=" +
    //     refresh_token +
    //     ";expires=" +
    //     oDate.toGMTString(); //设置cookie的名称，数值，过期时间
    // });

    return {
      state,
    };
  },
};
</script>
