<template>
  <section
    class="groupbutton"
    :style="[
      {
        right:
          path == '/Sea'
            ? showRight
              ? '400px'
              : '10px'
            : right && twoList
            ? '840px'
            : right
            ? '410px'
            : chatterShow
            ? '335px'
            : '30px',
        bottom: path == '/Sea' ? '160px' : '',
      },
    ]"
  >
    <!-- <button class="groupbutton-button">
      <img :src="require('@/assets/images/a10.png')" />
      <div>热力图</div>
    </button> -->
    <button class="groupbutton-button" @click="flyHome()">
      <img :src="require('@/assets/images/a11.png')" />
      <div>复位</div>
    </button>
    <button class="groupbutton-button" @click="onClickDistance()">
      <img :src="require('@/assets/images/a08.png')" />
      <div>测距</div>
    </button>
    <button class="groupbutton-button" @click="onClickArea()">
      <img :src="require('@/assets/images/09.png')" />
      <div>测面</div>
    </button>
    <!-- <button class="groupbutton-button" @click="swaitchMap()">
      <img :src="require('@/assets/dy/地图.png')" />
      <div>切换</div>
    </button> -->

    <!-- <button v-if="path== '/Sea'" :style="nowWeatherShow ? 'border:1px solid white;' : ''" class="groupbutton-button" @click="swaitchWeather()">
      <img :src="require('@/assets/sea/icon/天气.png')" />
      <div>天气</div>
    </button>
    <button v-if="path== '/Sea'" :style="showAllViewShed ? 'border:1px solid white;' : ''" class="groupbutton-button"
      @click="showAllViewShedFun()">
      <img :src="require('@/assets/sea/icon/可视域.png')" />
      <div>可视域</div>
    </button> -->
  </section>
</template>

<script>
export default {
  name: "Groupbutton",
  computed: {
    nowWeather() {
      return this.$store.state.index.nowWeather;
    },
    right() {
      return this.$store.state.index.right;
    },
    city() {
      return this.getCookie("city");
    },
    chatterShow() {
      return this.$store.state.index.chatterShow;
    },
    path() {
      return this.$route.path;
    },
    twoList() {
      return this.$store.state.index.twoList;
    },
    View() {
      return this.$store.state.index.View;
    },
    showAllViewShed() {
      return this.$store.state.index.showAllViewShed;
    },
    showRight() {
      return this.$store.state.index.showRight;
    },
  },
  data() {
    return {
      mapShow: false,
      idx: 1,
      nowWeatherShow: false,
    };
  },
  watch: {
    // nowWeather: {
    //   handler(newValue, oldValue) {
    //     console.log(newValue, oldValue)
    //     if (!newValue || this.path != '/sea') return
    //     if (newValue.indexOf('小雨')) {
    //       window.rainEffect.speed = 6
    //     } else if (newValue.indexOf('中雨')) {
    //       window.rainEffect.speed = 15
    //     } else if (newValue.indexOf('大雨')) {
    //       window.rainEffect.speed = 23
    //     } else if (newValue.indexOf('大暴雨')) {
    //       window.rainEffect.speed = 40
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // },
    // path(val) {
    //   if (val != '/Sea') {
    //     window.fogEffect.enabled = false
    //     window.rainEffect.enabled = false
    //     window.snowEffect.enabled = false
    //     window.snowCover.enabled = false
    //   } else {
    //     if (this.nowWeatherShow && this.nowWeather) {
    //       if (this.nowWeather.indexOf('雨') != -1) {
    //         window.rainEffect.enabled = true
    //       }
    //       if (this.nowWeather.indexOf('雪') != -1) {
    //         window.snowEffect.enabled = true
    //         window.snowCover.enabled = true
    //       }
    //       if (this.nowWeather.indexOf('雾') != -1) {
    //         window.fogEffect.enabled = true
    //       }
    //     }
    //   }
    // }
  },
  created() {},
  mounted() {},
  methods: {
    // swaitchWeather() {
    //   this.nowWeatherShow = !this.nowWeatherShow
    //   window.fogEffect.enabled = false
    //   window.rainEffect.enabled = false
    //   window.snowEffect.enabled = false
    //   window.snowCover.enabled = false
    //   if (this.nowWeatherShow && this.nowWeather) {
    //     if (this.nowWeather.indexOf('雨') != -1) {
    //       window.rainEffect.enabled = true

    //     }
    //     if (this.nowWeather.indexOf('雪') != -1) {
    //       window.snowEffect.enabled = true
    //       window.snowCover.enabled = true
    //     }

    //     if (this.nowWeather.indexOf('雾') != -1) {
    //       window.fogEffect.enabled = true
    //     }
    //   }
    // },
    // showAllViewShedFun() {
    //   this.$store.commit("index/set", { val: !this.showAllViewShed, key: "showAllViewShed" });
    // },
    flyHome() {
      // map.flyHome();
      map.setCameraView(this.View);
    },
    flyToAll() {
      if (this.city == 1) {
        map.flyHome();
      } else {
        window.cityView.flyTo();
      }
    },
    swaitchMap() {
      this.idx += 1;
      map.getBasemaps().forEach((e) => {
        e.show = false;
        if (this.idx > 3) {
          this.idx = 1;
        }
        // if (this.idx == 1) {
        //   if (e.options.name == '谷歌影像' || e.options.name == '天地图影像') {
        //     e.show = true
        //   }
        // }

        if (this.idx == 1) {
          if (e.options.name == "谷歌影像") {
            e.show = true;
          }
        }
        if (this.idx == 2) {
          if (e.options.name == "天地图影像") {
            e.show = true;
          }
        }
        if (this.idx == 3) {
          if (e.options.name == "ArcGIS影像") {
            e.show = true;
          }
        }
      });
    },

    onClickPoint() {
      this.$emit("onClickPoint");
    },
    onClickArea() {
      this.$emit("onClickArea");
    },
    onClickDistance() {
      this.$emit("onClickDistance");
    },
    // clickType(i) {
    //   map.getDefaultContextMenu()[5].children[i].callback();
    // },
  },
};
</script>

<style lang="scss" scoped>
.groupbutton {
  position: fixed;
  // z-index: 10;
  right: 410px;
  bottom: 160px;
  transition: right 0.3s ease-in-out;
  z-index: 6;

  .groupbutton-button {
    width: 40px;
    height: 40px;
    background: rgba(32, 40, 53, 0.56);
    border: 1px solid #202835;
    border-radius: 10px;
    margin-top: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    div {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #6b8ea2;
      line-height: 12px;
      margin-top: 6px;
    }

    img {
      width: 16px;
      height: 16px;
    }
  }

  .groupbutton-button:hover {
    background: var(--groupbutton-button-hover);
  }

  // .groupbutton-button:nth-child(2) {
  //   margin-top: 30px;
  // }
}
</style>
