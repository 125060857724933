<template>
  <div class="lineTypeBox column hid mousePoint">
    <div
      class="topContent flexCenter divFlex"
      @click="
        () => {
          state.allContentShow = !state.allContentShow;
        }
      "
    >
      <img src="@/assets/sea/images/下载.png" alt="" />
      <svg
        :style="state.allContentShow ? '' : 'rotate:180deg'"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        data-v-ea893728=""
      >
        <path
          fill="currentColor"
          d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"
        ></path>
      </svg>
    </div>
    <div class="div10" v-if="state.allContentShow"></div>
    <!-- <el-scrollbar class="grow"> -->
    <el-collapse-transition>
      <div class="allItem column hid" v-show="state.allContentShow">
        <div
          class="bigItem"
          v-for="(item, idx) in state.arr"
          :key="idx + 'fsdgljkh'"
        >
          <div class="topname">
            <div class="flexBet">
              <span>{{ idx }}</span>
            </div>
            <el-switch
              active-color="rgba(69, 205, 245, 0.5)"
              @change="changeParent(item)"
              inactive-color="rgba(144, 156, 160, 1)"
              v-model="item.is"
            >
            </el-switch>
          </div>

          <div
            class="oneItem"
            v-for="(child, i) in item.children"
            :key="i + 'dfsgggjyf'"
          >
            <div class="flexBet">
              <div
                class="colorBox"
                :style="`background:${child.backGround};border:2px ${child.line} ${child.color}`"
              ></div>
              <span>{{ child.name }}</span>
            </div>
            <el-switch
              active-color="rgba(69, 205, 245, 0.5)"
              @change="changeChild(child)"
              inactive-color="rgba(144, 156, 160, 1)"
              v-model="child.is"
            >
            </el-switch>
          </div>
        </div>
      </div>
    </el-collapse-transition>
    <!-- </el-scrollbar> -->
  </div>
</template>

<script>
// VUE
import {
  h,
  ref,
  reactive,
  watch,
  computed,
  getCurrentInstance,
  onMounted,
  onActivated,
  onDeactivated,
} from "vue";

import { treeBg } from "@/api";
export default {
  components: {},
  props: [],

  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    let state = reactive({
      arr: "",
      value: false,
      // arr: {
      //     规划线: {
      //         children: [{
      //             line: 'dashed',
      //             name: '网格界线',
      //             is: true,
      //             backGround: 'transparent',
      //             color: 'rgba(255, 230, 21, 1)',
      //         },
      //         {
      //             line: 'dashed',
      //             name: '生态红线',
      //             is: false,
      //             backGround: 'transparent',
      //             color: 'rgba(255, 49, 73, 1)',
      //         },
      //         {
      //             line: 'solid',
      //             name: '航道',
      //             is: false,
      //             backGround: 'rgba(254, 174, 49, 0.5)',
      //             color: 'rgba(254, 174, 49, 1)',
      //         }],
      //         name: '规划线',
      //         is: true
      //     },
      //     规划区: {
      //         children: [
      //             {
      //                 line: 'solid',
      //                 name: '养殖区',
      //                 is: true,
      //                 backGround: 'transparent',
      //                 color: 'rgba(19, 201, 130, 1)',
      //             },
      //             {
      //                 line: 'solid',
      //                 name: '限养区',
      //                 is: false,
      //                 backGround: 'transparent',
      //                 color: 'rgba(69, 205, 245, 1)',
      //             },
      //             {
      //                 line: 'solid',
      //                 name: '禁养区',
      //                 is: false,
      //                 backGround: 'transparent',
      //                 color: 'rgba(255, 49, 73, 1)',
      //             }
      //         ],
      //         name: '规划区',
      //         is: true
      //     },
      // },
      allContentShow: true,
    }); // 数据
    const changeParent = (val) => {
      state.arr[val.name].children.forEach((item) => {
        item.is = val.is;
      });
      context.emit("setWms", state.arr);
    };
    const changeChild = (val) => {
      for (const key in state.arr) {
        let obj = state.arr[key].children.find((e) => {
          return e.name == val.name;
        });
        if (obj) {
          let flag = false;
          flag = state.arr[key].children.find((e) => {
            return e.is == true;
          });
          flag = flag ? true : false;
          state.arr[key].is = flag;
          context.emit("setWms", state.arr);
          return;
        }
      }
    };
    onActivated(() => {
      if (state.arr) {
        context.emit("setWms", state.arr);
      }
    });
    onMounted(async () => {
        console.log(state.arr,'8888888888888')
      treeBg().then((res) => {
        state.arr = res.data;
        context.emit("setWms", state.arr);
      });
    });

    return {
      // 变量
      state,
      //方法
      changeChild,
      changeParent,
    };
  },
};
</script>

<style lang="scss" scoped>
.lineTypeBox {
  // width: 150px;
  // height: 260px;
  box-sizing: border-box;
  background: rgba(2, 4, 23, 0.48);
  border: 1px solid #15154c;
  position: fixed;
  left: 400px;
  bottom: 10px;
  z-index: 31;
  display: flex;

  .topContent {
    position: relative;
    width: 100%;
    height: fit-content;
    min-width: 163px;
    img {
      width: 100%;
      height: 23px;
      position: absolute;
      z-index: -1;
    }

    svg {
      color: #169bd5;
      width: 18px;
      // box-shadow: 0px 0px 4px 0px rgba(69, 205, 245, 0.4), 0px 4px 8px 0px rgba(69, 205, 245, 0.3);
    }
  }

  .allItem {
    padding: 0px 15px 20px 10px;
    box-sizing: border-box;

    .bigItem {
      margin-top: 10px;

      .topname {
        width: 100%;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #45cdf5;
        display: flex;
        margin-top: 10px;
        justify-content: space-between;

        .flexBet {
          display: flex;
          align-items: center;
        }
      }

      .topname:first-child {
        margin: 0;
      }
    }

    .bigItem:first-child {
      margin: 0;
    }

    .oneItem {
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #c5c8d0;
      display: flex;
      margin-top: 10px;
      justify-content: space-between;

      span {
        margin: 0 8px;
      }

      .flexBet {
        display: flex;
        align-items: center;

        .colorBox {
          width: 16px;
          height: 16px;
          background: rgba(19, 201, 130, 0.5);
          border: 2px solid #13c982;
          margin-right: 6px;
        }
      }
    }
  }
}
</style>
