import {
  createRouter,
  createWebHashHistory
} from "vue-router";
import {
  getCookie,
  setCookie
} from "@/utils";
import {
  getTenant,
} from "@/api";
import axios from "axios";
let url
import store from '../store'
import Layout from "@/layout";
let tenantId = getCookie('tenantId');

let setInt1 = setInterval(() => {
  tenantId = getCookie('tenantId');
  if (tenantId) {
    clearInterval(setInt1);
  }
}, 300);
import {
  ElMessage
} from "element-plus";
export const routes = [{
    path: "/login",
    component: () => import("@/views/Login/login.vue"),
    meta: {
      title: "登录页",
    }
  },
  {
    path: "/",
    component: Layout,
    redirect: "/Overview",
    meta: {
      title: "东冲概览",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/Overview",
      component: () => import("@/views/Overview/index"),
      name: "东冲概览",
    }, ],
  },
  {
    path: "/VillageDivision",
    component: Layout,
    redirect: "/VillageDivision",
    meta: {
      title: "人文景观",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/VillageDivision",
      component: () => import("@/views/VillageDivision/index"),
      name: "人文景观",
    }, ],
  },
  {
    path: "/NowScenic",
    component: Layout,
    redirect: "/NowScenic",
    meta: {
      title: "此刻东冲",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/NowScenic",
      component: () => import("@/views/NowScenic/index"),
      name: "此刻东冲",
    }, ],
  },
  {
    path: "/SocietyShipCare",
    component: Layout,
    redirect: "/SocietyShipCare",
    meta: {
      secMenu: "基础网格",
      title: "辖区动态",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/SocietyShipCare",
      component: () => import("@/views/SocietyShipCare/index"),
      name: "辖区动态",
    }, ],
  },
  {
    path: "/PersonagesRecords",
    component: Layout,
    redirect: "/PersonagesRecords",
    meta: {
      title: "人物艺文志",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/PersonagesRecords",
      component: () => import("@/views/PersonagesRecords/index"),
      name: "人物艺文志",
    }, ],
  },

  {
    path: "/GrassrootsOrganizations",
    component: Layout,
    redirect: "/GrassrootsOrganizations",
    meta: {
      title: "党政建设",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/GrassrootsOrganizations",
      component: () => import("@/views/GrassrootsOrganizations/index"),
      name: "党政建设",
    }, ],
  },
  {
    path: "/Education",
    component: Layout,
    redirect: "/Education",
    meta: {
      title: "教育卫生",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/Education",
      component: () => import("@/views/Education/index"),
      name: "教育卫生",
    }, ],
  },

  {
    path: "/LifeStyle",
    component: Layout,
    redirect: "/LifeStyle",
    meta: {
      title: "风土民情",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/LifeStyle",
      component: () => import("@/views/LifeStyle/index"),
      name: "风土民情",
    }, ],
  },

  {
    path: "/ChronicleEvent",
    component: Layout,
    redirect: "/ChronicleEvent",
    meta: {
      title: "大事记",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/ChronicleEvent",
      component: () => import("@/views/ChronicleEvent/index"),
      name: "大事记",
    }, ],
  },

  {
    path: "/CockpitFir",
    component: Layout,
    redirect: "/CockpitFir",
    meta: {
      secMenu: "基础网格",
      title: "网格汇总",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/CockpitFir",
      component: () => import("@/views/CockpitFir/index"),
      name: "网格汇总",
    }, ],
  },

  {
    path: "/RealHouse",
    component: Layout,
    redirect: "/RealHouse",
    meta: {
      secMenu: "基础网格",
      title: "实有房屋",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/RealHouse",
      component: () => import("@/views/RealHouse/index"),
      name: "实有房屋",
    }, ],
  },
  {
    path: "/RealPeople",
    component: Layout,
    redirect: "/RealPeople",
    meta: {
      secMenu: "基础网格",
      title: "实有人口",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/RealPeople",
      component: () => import("@/views/RealPeople/index"),
      name: "实有人口",
    }, ],
  },
  {
    path: "/ImportantObj",
    component: Layout,
    redirect: "/ImportantObj",
    meta: {
      secMenu: "基础网格",
      title: "重点服务对象",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/ImportantObj",
      component: () => import("@/views/ImportantObj/index"),
      name: "重点服务对象",
    }, ],
  },
  {
    path: "/ChatterGrid",
    component: Layout,
    redirect: "/ChatterGrid",
    meta: {
      secMenu: "基础网格",
      title: "数说网格",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/ChatterGrid",
      component: () => import("@/views/ChatterGrid/index"),
      name: "数说网格",
    }, ],
  },

  {
    path: "/ChatterCure",
    component: Layout,
    redirect: "/ChatterCure",
    meta: {
      secMenu: "综治平安",
      title: "数说综治",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/ChatterCure",
      component: () => import("@/views/ChatterCure/index"),
      name: "数说综治",
    }, ],
  },
  {
    path: "/ImportantMan",
    component: Layout,
    redirect: "/ImportantMan",
    meta: {
      secMenu: "综治平安",
      title: "重点人员",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/ImportantMan",
      component: () => import("@/views/ImportantMan/index"),
      name: "重点人员",
    }, ],
  },
  {
    path: "/ImportantPart",
    component: Layout,
    redirect: "/ImportantPart",
    meta: {
      secMenu: "综治平安",
      title: "重点部件场所",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/ImportantPart",
      component: () => import("@/views/ImportantPart/index"),
      name: "重点部件场所",
    }, ],
  },

  {
    path: "/ImportantPlace",
    component: Layout,
    redirect: "/ImportantPlace",
    meta: {
      secMenu: "综治平安",
      title: "重点场所",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/ImportantPlace",
      component: () => import("@/views/ImportantPlace/index"),
      name: "重点场所",
    }, ],
  },

  {
    path: "/VideoReal",
    component: Layout,
    redirect: "/VideoReal",
    meta: {
      secMenu: "综治平安",
      title: "视频监控",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/VideoReal",
      component: () => import("@/views/VideoReal/index"),
      name: "视频监控",
    }, ],
  },

  {
    path: "/PartyDesc",
    component: Layout,
    redirect: "/PartyDesc",
    meta: {
      secMenu: "党建引领",
      title: "数说党建",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/PartyDesc",
      component: () => import("@/views/PartyDesc/index"),
      name: "数说党建",
    }, ],
  },

  {
    path: "/PartyGridSummary",
    component: Layout,
    redirect: "/PartyGridSummary",
    meta: {
      secMenu: "党建引领",
      title: "党群网格",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/PartyGridSummary",
      component: () => import("@/views/PartyGridSummary/index"),
      name: "党群网格",
    }, ],
  },

  {
    path: "/PartyMap",
    component: Layout,
    redirect: "/PartyMap",
    meta: {
      secMenu: "党建引领",
      title: "党建地图",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/PartyMap",
      component: () => import("@/views/PartyMap/index"),
      name: "党建地图",
    }, ],
  },
  {
    path: "/FishingRaft",
    component: Layout,
    redirect: "/FishingRaft",
    meta: {
      secMenu: "海上社区",
      title: "海上渔排",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/FishingRaft",
      component: () => import("@/views/FishingRaft/index"),
      name: "海上渔排",
    }, ],
  },
  {
    path: "/Uav",
    component: Layout,
    redirect: "/Uav",
    meta: {
      secMenu: "海上社区",
      title: "无人机巡查",
      requireAuth: true,
      keepAlive: true
    },
    children: [{
      path: "/Uav",
      component: () => import("@/views/Uav/index"),
      name: "无人机巡查",
    }, ],
  },

];

export const router = createRouter({
  history: createWebHashHistory(),
  routes
})

function datedifference(time) {
  //计算工作日方法：遍历这两个日期区间的每一个日期，获取他的getDay()
  //分别获取first和last的毫秒数(时间戳)
  let first = new Date().getTime();
  // console.log(first,time)
  var count = true;

  let cha = first - time;
  if (cha >= 5 * 60 * 1000) {
    count = false;
  }
  // console.log(cha)
  return count;
}

async function getAssece() {
  let time = false;
  let refresh_Token = getCookie('refresh-token')
  if (refresh_Token) {
    time = JSON.parse(refresh_Token.replace(/%22/g, '"').replace(/%2C/g, ','))
  }
  if (!time) return;
  let refresh_token = time.data;
  time = Number(time.dataTime);
  if (!datedifference(time)) {

    await axios.post(`${url}blade-auth/oauth/bg/token?tenantId=${tenantId}&refresh_token=${'bearer ' +  refresh_token}&grant_type=refresh_token&scope=all`, {
      params: {
        tenantId: "208797",
        refresh_token,
        grant_type: "refresh_token",
        scope: "all",
      },
    }, {
      headers: {
        'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
        'Tenant-Id': `${tenantId}`,
      }
    }).then((res) => {
      // console.log(res)
      setCookie("access-token", res.data.access_token, 7000);
      setCookie(
        "refresh-token",
        JSON.stringify({
          data: res.data.refresh_token,
          dataTime: new Date().getTime(),
        }),
        7000
      );
    });
  }
  setInterval(() => {
    let time = false;
    let refresh_Token = getCookie('refresh-token')
    if (refresh_Token) {
      time = JSON.parse(refresh_Token.replace(/%22/g, '"').replace(/%2C/g, ','))
    }
    if (!time) return;
    let refresh_token = time.data;
    time = Number(time.dataTime);
    if (!datedifference(time)) {
      axios.post(`${url}blade-auth/oauth/bg/token?tenantId=${tenantId}&refresh_token=${refresh_token}&grant_type=refresh_token&scope=all`, {
        params: {
          tenantId: "208797",
          refresh_token,
          grant_type: "refresh_token",
          scope: "all",
        },
      }, {
        headers: {
          'Authorization': 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
          'Tenant-Id': `${tenantId}`,
        }
      }).then((res) => {
        // console.log(res)
        setCookie("access-token", res.data.access_token, 7000);
        setCookie(
          "refresh-token",
          JSON.stringify({
            data: res.data.refresh_token,
            dataTime: new Date().getTime(),
          }),
          7000
        );
      });
    }
  }, 10000);
}

let countTime = 0;

//这里设置路由拦截
router.beforeEach(async (to, from, next) => {
  // console.log(router.getRoutes())

  let userInfo = getCookie('userInfo')
  let accessToken = getCookie('access-token')
  if (userInfo) {
    userInfo = decodeURIComponent(userInfo)
    userInfo = JSON.parse(userInfo.replace(/%22/g, '"').replace(/%2C/g, ','));
    // document.title = userInfo.jurisdiction;
    document.title = '数字东冲•智慧平台';
  }

  // console.log(userInfo)
  // alert(1)

  await getTenant({
    url: window.location.origin,
  }).then(res => {
    url = res.data.url + "/api/"
    tenantId = res.data.tenantId
    setCookie("tenantId", tenantId, 7000);
  })

  await getAssece();
  // alert(2)
  // let str = ""
  // if (userInfo && userInfo.jurisdiction) str += userInfo.jurisdiction + '·'
  // if (to.meta.secMenu ){} str += to.meta.secMenu + '·';
  // if(['/Detection', '/warningMap', '/SplitView', '/CarControl', '/StatisticAnalysis'].includes(to.path)){
  //   document.title = userInfo.jurisdiction;
  // }
  // else{
  //   document.title = str + '智慧网格平台'
  // }

  // -------------------------------------------------------------
  if (to.path == '/login') {
    next()
    return
  }

  console.log(accessToken)
  if (!accessToken || accessToken == 'undefined') {
    next('login')
  } else {
    // setTimeout(async () => {
    await store.dispatch('index/GenerateRoutes', {
      type: 1,
      url
    }).then(async accessRoutes => {
      // alert(2222)
      // console.log(accessRoutes)
      // let nowRoutes = router.getRoutes();
      // if (nowRoutes.length <= 1) {
      //   console.log(nowRoutes.length, 'nowRoutes.lengthnowRoutes.lengthnowRoutes.length')
      //   accessRoutes.forEach(e => {
      //     router.addRoute(e)
      //   })
      // }
      // console.log(to)
      // console.log(from)
      // alert(3)
      if (to.meta.requireAuth) { //判断是否需要登录验证
        // console.log(99999999999999999999)
        var access_token = getCookie("access-token"); //这里是登录页面缓存的token
        if (access_token) {
          // console.log(1)
          next();
        } else { //token不一样时候就返回登录页面
          // console.log(2)
          // alert(4)
          ElMessage({
            message: '请登录！！',
            type: 'error'
          });
          next({
            path: '/login', //返回登录界面
          })
        }
      } else {
        // console.log(88888888888888888)
        //如果不需要登录权限就直接跳转到该路由
        next();
      }
    })
    // }, 800);

  }


})

export default router