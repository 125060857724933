<template>
  <div class="specialInfo">
    <div @click="swaitchTitle(item)" :class="item == title
        ? 'activeText' + (party ? 'party' : '')
        : 'disActiveText' + (party ? 'party' : '')
      " v-for="item in Array.from(new Set(titles))" :key="item">
      {{ item }}
    </div>
    <!-- <swiper  :slides-per-view="2" :space-between="5">
      <swiper-slide v-for="item in titles" :key="item">
        <div @click="swaitchTitle(item)" :class="
          item == title
            ? 'activeText' + (party ? 'party' : '')
            : 'disActiveText' + (party ? 'party' : '')
        ">
          {{ item }}
        </div>
      </swiper-slide>

    </swiper> -->
  </div>
</template>
  
<script>
import DetailTitle from "./DetailTitle";
import EconomicsSpecial from "./EconomicsSpecial";
import Extend from "./Extend";
import Contact from "./Contact";
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted, reactive, toRefs
} from "vue";

import SwiperCore, { Autoplay, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Autoplay, Pagination, Scrollbar, A11y])

export default {
  components: {
    EconomicsSpecial, DetailTitle, Contact, Extend, Swiper, SwiperSlide,
  },
  props: ["titles", "title", "party"],
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 30,
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
        value2: false,
      },
    });
    const swaitchTitle = (item) => {
      console.log(item)
      ctx.emit("swaitchTitle", item);
    };
    onMounted(() => {
      _this.chooseCss()
    });
    return {
      state,
      swaitchTitle,
    };
  },
};
</script>
  
<style lang="scss" scoped>
.specialInfo {
  height: 16px;
  background: var(--specialInfo);
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 16px;
  display: flex;
  margin-top: 30px;

  div:nth-child(1) {
    // margin-left: 9px !important;
  }

  .activeText {
    color: rgba(34, 226, 255, 1);
    margin-left: 17px;
    cursor: pointer;
  }

  .disActiveText {
    color: rgba(34, 226, 255, 0.5);
    margin-left: 17px;
    cursor: pointer;
  }

  .activeTextparty {
    color: var(--ssprogress2);
    margin-left: 17px;
    cursor: pointer;
  }

  .disActiveTextparty {
    color: var(--ssrtCiecle);
    margin-left: 17px;
    cursor: pointer;
  }
}
</style>