<template>
  <div :class="party ? 'countyTitle partyTitle1' : 'countyTitle'">
    <div class="countyName" :style="party ? 'color:rgba(255, 230, 21, 1)' : ''">
      {{ name }}
    </div>
    <div class="countcount">
      <div class="peopleCounty" v-if="people">{{ people }}</div>
      <div class="peopleCounty" v-if="houses" style="margin-left: 24px">{{ houses }}</div>
      <div class="peopleCounty" v-if="project" style="margin-left: 24px"><span class="reform">改造项目:</span>{{ project }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleButton",
  props: {
    people: {
      type: String,
      default: "",
    },
    houses: {
      type: String,
      default: "",
    },
    warn: {
      type: String,
      default: "",
    },
    area: {
      type: String,
      default: "",
    },
    wisdom: {
      type: String,
      default: "",
    },
    party: {
      type: Boolean,
      default: false,
    },
    name: String,
    color: {
      type: String,
      default: "",
    },
    project:{
      type: String,
      default: "",
    }
  },
  mounted() {
    this.chooseCss()
  },
};
</script>

<style lang="scss" scoped>
.areaNum {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: var(--desc_name);
  line-height: 24px;
  margin-right: 29px;
}

.wisdomNum {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: var(--desc_name);
  line-height: 24px;
}

.areaTimes {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: var(--areaTimes);
  line-height: 30px;
}

.reform {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #5B7793;
}

.partyTitle1 {
  width: 100%;
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  background-image: url("../assets/static/位置1.png") !important;
  background-repeat: no-repeat;
  background-position: unset;
  margin: 20px 0 14px 0;
  height: 36px;
  cursor: pointer;
}

.countcount {
  margin-left: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 62px);
}</style>
