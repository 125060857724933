<template>
  <swiper v-if="type == '默认' && ((videos && videos.length > 0) ||(images && images.length > 0) )" :slides-per-view="1" :space-between="0" class="moren-swiper" @swiper="onSwiper"
    @slideChange="onSlideChange" :navigation="{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }">
    <swiper-slide v-for="(val, key) in videos" :key="key + 'Swiper'">
      <live-player :video-url="val" live="true" fluent stretch aspect="fullscreen" :autoplay="false" id="player01">
      </live-player>
    </swiper-slide>
    <swiper-slide v-for="(val, key) in images" :key="key + 'Swiper'">
      <img @click="
        () => {
          $store.commit('index/set', {
            key: 'viewer',
            val: {
              list: images,
              key: key,
            },
          });
        }
      " :src="val.indexOf('http') != -1 ? val : val" />
    </swiper-slide>

    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
  <swiper v-if="type == 'Two' && ((videos && videos.length > 0) ||(images && images.length > 0) )" :slides-per-view="2" :space-between="8" class="moren-swiper" @swiper="onSwiper"
    @slideChange="onSlideChange" :pagination="{ clickable: true }">
    <swiper-slide v-for="(val, key) in videos" :key="key + 'Swiper'">
      <live-player :video-url="val" live="true" fluent stretch aspect="fullscreen" :autoplay="false">
      </live-player>
    </swiper-slide>
    <swiper-slide v-for="(val, key) in images" :key="key + 'Swiper'">
      <img :src="val.indexOf('http') != -1 ? val : val" />
    </swiper-slide>

    <!-- <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div> -->
  </swiper>
  <swiper class="leadershipCare_asd" v-if="type == '列表' && images && images.length > 0" :slides-per-view="'auto'" :space-between="30" :autoplay="{
    delay: 1000,
  }" @swiper="onSwiper" @slideChange="onSlideChange" @mouseenter="mouseenterAuto" @mouseleave="mouseleaveAuto">
    <swiper-slide style="width: auto; height: 100%" v-for="(val, key) in images" :key="key + 'Swiper'">
      <img :src="(val.indexOf('http') != -1 ? val : val) + '?imageView2/2/h/270'" @click="
        () => {
          $store.commit('index/set', {
            key: 'viewer',
            val: {
              list: images,
              key: key,
            },
          });
        }
      " />
    </swiper-slide>
  </swiper>
  <swiper class="servicesPin-image-img" v-if="type == '详情' && images && images.length > 0" :slides-per-view="1" :space-between="10" :autoplay="{
    delay: 5000,
  }">
    <swiper-slide style="width: 100%; height: auto" v-for="(val, key) in images" :key="key + 'Swiper'">
      <img :src="(val.indexOf('http') != -1 ? val : val) + '?imageView2/2/h/270'" @click="
  () => {
    $store.commit('index/set', {
      key: 'viewer',
      val: {
        list: images,
        key: key,
      },
    });
  }
      " />
    </swiper-slide>
  </swiper>
</template>

<script>
// Swiper
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
//这里导入对应样式
import "swiper/components/pagination/pagination.scss";
//导入后在SwiperCore.use([])里注册
SwiperCore.use([Pagination, Navigation, Autoplay]);

export default {
  name: "SwiperImage",
  components: { Swiper, SwiperSlide },
  props: {
    images: {
      type: Array,
    },
    videos: {
      type: Array,
    },
    type: {
      type: String,
      default: "默认",
    },
  },
  data() {
    return {
      swiper: false,
    };
  },

  methods: {
    mouseenterAuto() {
      this.swiper.autoplay.start();
    },
    mouseleaveAuto() {
      this.swiper.autoplay.stop();
    },
    onSwiper(swiper) {
      this.swiper = swiper;
      this.swiper.autoplay.stop();
    },
    onSlideChange(e) {
      console.log(e)
      var player = document.getElementById("player01");
      if (player) {
        player.paused();
      }

    },
    onOpen(url) {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        // techOrder: ["flash"],
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: url,
          },
        ],
        //你的封面地址
        poster: "",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.moren-swiper {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.leadershipCare_asd {
  width: 100%;
  height: 290px;
  box-sizing: border-box;
  padding: 10px 0;

  img {
    width: auto;
    height: 100%;
    // object-fit: contain;
  }
}

.servicesPin-image-img {
  img {
    width: 100%;
    height: auto;
    border-radius: 3px;
    object-fit: cover;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  z-index: 9;
  background: url("../assets/images/箭头.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  opacity: 0.7;
}

.swiper-button-prev {
  left: 20px;
  transform: translate(0, -50%);
}

.swiper-button-next {
  right: 20px;
  transform: translate(0, -50%) rotateY(180deg);
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-disabled {
  opacity: 0.7 !important;
  cursor: not-allowed;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "" !important;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "" !important;
}
</style>
