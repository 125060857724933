<template>
    <div class="SpaceDiv" :style="`height: ${height}px;`">
       
    </div>
</template>
  
<script>

export default {
    name:'SpaceDiv',
    props:['height']
   
};
</script>
  
<style lang="scss" scoped>
.SpaceDiv{
    width: 100%;
    display: flex;
    flex-shrink: 0;
}
</style>
  