import axios from "axios";
import store from '@/store';
import router from '@/router';
import {
  ElMessage
} from "element-plus";
import QS from "qs";
import Cookies from 'js-cookie';

// http request拦截器 添加一个请求拦截器
axios.interceptors.request.use((config) => {
  let access_token = Cookies.get('access-token');
  if (config.url.indexOf("open.ys7.com/api") == -1 && config.url.indexOf("http://live.3dy.me:8080") == -1) {
    config.headers['Authorization'] = "Basic c2FiZXI6c2FiZXJfc2VjcmV0";

    // if(access_token && (config.url.indexOf('https://zhxp.3dy.me/') != -1 || config.url.indexOf('8080') != -1)){
    //   config.headers['Blade-Auth'] = 'bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJpc3N1c2VyIiwiYXVkIjoiYXVkaWVuY2UiLCJ0ZW5hbnRfaWQiOiIwNDcyNjAiLCJ1c2VyX25hbWUiOiLmnb7lsbHooZfpgZMiLCJvcGVuaWQiOiIiLCJkZWZhdWx0TWFpbiI6MCwidG9rZW5fdHlwZSI6ImFjY2Vzc190b2tlbiIsInJvbGVfbmFtZSI6IuadvuWxseihl-mBk-Wkp-WxjyIsImlzU2FuU2hhR3JpZCI6MCwicG9zdF9pZCI6IjE1ODY5NzA1MjQyNjEwODUxODUiLCJ1c2VyX2lkIjoiMTU4ODQ1MTQ1MjM2Mzc2MzcxMyIsInJvbGVfaWQiOiIxNjM0MDc2NTgyNDEwMjY0NTc3Iiwibmlja19uYW1lIjoi5p2-5bGx6KGX6YGTIiwiZGV0YWlsIjp7InR5cGUiOiJ3ZWIifSwiZGVwdF9pZCI6IjE1NzMxNTYxNDE0NTgyNzIyNTgiLCJhY2NvdW50Ijoic3NqZCIsImNsaWVudF9pZCI6InNhYmVyIiwiZXhwIjoxNzEwMTIyOTc2LCJuYmYiOjE3MDIzNDY5NzZ9.ljy6OJBWEgMwyUYIjNAkYEJY-H3JFmwPOFWm8ZPDFnY2MEslXqEm96ClDYwfgqrgK0MHw5EmOnVx0F_qDVe_iQ'
    // }else 
    if (access_token) {
      config.headers['Blade-Auth'] = 'bearer ' + access_token;
    }
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

//http response 拦截
axios.interceptors.response.use(res => {
  // console.log(res)
  //获取状态码
  const status = res.data.code || res.status;
  // const statusWhiteList = website.statusWhiteList || [];
  const message = res.data.msg || res.data.message  || '未知错误';
  //如果是401则跳转到登录页面
  // if (status === 401) {
  //   console.log(1111)
  // }
  // 如果请求为非200否者默认统一处理
  if (status != 200) {
     ElMessage({
      message: message,
      type: 'error'
    });
    return Promise.reject(new Error(message))
  }
  return res;
}, (error) => {
  // console.log(error)
  const status = error.response?.data.code?error.response?.data.code:error.response?.status;
  const message = error.response?.data?.msg ||  error.response?.msg ||error.response?.data.message || error.response?.message || '未知错误';

  if (status == 401) {
    ElMessage({
      message: message,
      type: "error",
      duration: 5 * 1000,
      offset: 120
    });
  store.dispatch('index/FedLogOut').then(() => router.push({path: '/login'}));
  }
  if (status != 200) {
    ElMessage({
      message: message,
      type: "error",
      duration: 5 * 1000,
      offset: 120
    });
  }
  return Promise.reject(new Error(error));
});


export function get(url, params = {}, headers = "") {
  let flag = false;
  flag = url.indexOf('wxpublicGrid') != -1;
  let hre = window.location.href;
  if (flag) {
    if (hre.indexOf("PartyGridSummary") != -1) {
      params.sign = 'dqwg'
    } else if (hre.indexOf("PartyMap") != -1) {
      params.sign = 'dqdt'
    } else if (hre.indexOf("PartyReport") != -1) {
      params.sign = 'dybd'
    } else if (hre.indexOf("RedAlert") != -1) {
      params.sign = 'hszd'
    } else if (hre.indexOf("Alliance") != -1) {
      params.sign = 'djlm'
    } else if (hre.indexOf("PartyTalent") != -1) {
      params.sign = 'rcdx'
    } else if (hre.indexOf("CockpitFir") != -1) {
      params.sign = 'wghz'
    } else if (hre.indexOf("RealPeople") != -1) {
      params.sign = 'syrk'
    }else if (hre.indexOf("RealHouse") != -1) {
      params.sign = 'syfw'
    }else if (hre.indexOf("ImportantObj") != -1) {
      params.sign = 'zdfwdx'
    }else if (hre.indexOf("ImportantMan") != -1) {
      params.sign = 'zdry'
    }else if (hre.indexOf("ImportantPart") != -1) {
      params.sign = 'zdbj'
    }else if (hre.indexOf("ImportantPlace") != -1) {
      params.sign = 'zdcs'
    }else if (hre.indexOf("VideoReal") != -1) {
      params.sign = 'spjk'
    }else if (hre.indexOf("EnterpriseGrid") != -1) {
      params.sign = 'qywg'
    }else if (hre.indexOf("ProvideAged") != -1) {
      params.sign = 'yldt'
    }else if (hre.indexOf("CureAndBring") != -1) {
      params.sign = 'yyjh'
    }else if (hre.indexOf("VideoLinkpage") != -1) {
      params.sign = 'wgld'
    }else if (hre.indexOf("LogBook") != -1) {
      params.sign = 'gztz'
    }else if (hre.indexOf("GridGovern") != -1) {
      params.sign = 'wgl'
    }else if (hre.indexOf("CrowdCalls") != -1) {
      params.sign = 'qzhs'
    }else if (hre.indexOf("DrugControl") != -1) {
      params.sign = 'sdgz'
    }
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      }, {
        headers
      })
      .then((res) => {
        // console.log(res.data)
        resolve(res.data);
      })
      .catch((err, res) => {
        reject(err.response);
      });
  });
}
export function post(url, params = {}, headers = "") {
  if (headers) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, {
          headers
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.data);
        });
    });
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}