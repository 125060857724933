<template>
  <div class="oneTitle">
    <div class="imgAndTitle">
      <div
        :class="state.nowTitle == item ? 'nowTitle' : 'disTitle'"
        v-for="item in titles"
        :key="item"
        @click="chooseThis(item)"
      >
        {{ item }}
      </div>
    </div>
    <div class="underline"></div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, onMounted, watch } from "vue";

export default {
  props: ["titles"],
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      nowTitle: 0,
    });
    watch(
      () => props.titles,
      (val) => {
        state.nowTitle = val[0];
      },
      {
        immediate: true,
      }
    );
    const chooseThis =(item)=>{
      state.nowTitle = item
      ctx.emit("chooseThis", item);
    };
    onMounted(() => {
            _this.chooseCss()
        });
    return {
      state,
      chooseThis
    };
  },
};
</script>

<style lang="scss" scoped>
.oneTitle {
  display: flex;
  flex-direction: column;
  position: relative;
  .backShadow {
    position: absolute;
    left: 0;
    top: 60%;
    transform: translate(0, -50%);
    width: 100%;
    height: 14px;
    z-index: -1;
    background: var(--linearGradient16);
  }
  .imgAndTitle {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      object-fit: contain;
      margin-right: 10px;
    }
    .nowTitle {
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: rgba(1, 180, 255, 1);
      background: linear-gradient(to right, #22E2FF 0%, #A6FFEF 100%);;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 16px;
      cursor: pointer;
    }
    .disTitle {
      cursor: pointer;
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: rgba(1, 180, 255, 1);
      opacity: 0.5;
      background: linear-gradient(to right #22E2FF 0%, #A6FFEF 100%);;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 16px;
    }
  }
  .underline {
    width: 340px;
    height: 1px;
    background: var(--linearGradient19);
  }
}
</style>