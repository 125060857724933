import {
  roleMenus
} from '@/api'
import Cookies from 'js-cookie'
import axios from 'axios'
import {
  routes
} from '@/router'
let
  state = {
    uid: '',
    right: false,
    left: false,
    detailshow: false,
    viewer: false,
    videoAndImageSwiper: false,
    View: {
      lat: 26.453043,
      lng: 119.864554,
      alt: 13051,
      heading: 3,
      pitch: -50
    },
    DayNight: '白天',
    videoUrl: false,
    playStatus: false,
    xunlan: false,
    playIndex: 1,
    scenicList: []
  },
  getters = {},
  mutations = {
    set(state, {
      key,
      val
    }) {
      state[key] = val;
    },
  },
  actions = {
    // 前端 登出
    FedLogOut({
      commit
    }) {
      return new Promise(resolve => {
        Cookies.remove('refresh_token');
        Cookies.remove('access_token');
        resolve()
      })
    },
    // roleMenus({
    //   type: 1
    // })
    GenerateRoutes({
      commit
    }, data) {
      console.log(data)
      return new Promise(resolve => {

        axios.get(data.url + 'router/role-menus', {
            params: {
              type: 1
            }
          })
          .then(response => {
            commit("set", {
              val: response.data.data,
              key: "menu"
            })
            // let accessedRoutes = []
            let accessedRoutes = [{
              path: "/login",
              component: () => import("@/views/Login/login.vue"),
              meta: {
                title: "登录页",
                requireAuth: false,
                keepAlive: true
              }
            }];
            response.data.data.forEach(one => {
              if (one.children && one.children.length > 0) {
                one.children.forEach(two => {
                  routes.forEach(thr => {
                    if (two.name == thr.meta.title) {
                      accessedRoutes.push(thr)
                    }
                  })
                })
              }
            })
            resolve(accessedRoutes)
          })
      })
    }
  };
export default {
  state,
  getters,
  mutations,
  actions
};