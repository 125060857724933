<template>
  <div class="divFlex">
    <div class="relative">
      <div
        class="jf_data_zd divFlex flexBet alignCenter"
        :style="iconUrl ? '' : `padding-left:0;`"
      >
        <div class="divFlex alignCenter">
          <img class="absolute zd_img" v-if="iconUrl" :src="iconUrl" alt="" />
          <div class="jf_data_name">{{ name }}</div>
        </div>
        <div class="divFlex alignCenter">
          <span class="jf_data_num">{{ num }}</span>
          <div class="jf_data_unit">{{ unit }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Count2",
  props: {
    type: {
      default: "dot",
    },
    background: {
      default: "",
    },
    name: {
      default: "",
    },
    num: {
      default: "",
    },
    iconUrl: {
      default: "",
    },
    unit: {
      default: "艏",
    },
  },
  components: {},

  data() {
    return {};
  },
  watch: {},
  computed: {},
  created() {},
  methods: {},

  async mounted() {},
  beforeUnmount() {},
};
</script>

<style lang="scss" scoped>
.jf_data_yz,
.jf_data_zd {
  margin-bottom: 13px;
  height: 24px;
  background: rgba(51, 102, 153, 0.2);
  padding-left: 7px;
  min-width: 140px;

  .dot {
    width: 10px;
    height: 10px;
    background: #00cabc;
    border-radius: 50%;
    margin-right: 8px;
    white-space: nowrap;
  }

  .jf_data_name {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #a6e5ff;
    margin-right: 8px;
    white-space: nowrap;
  }

  .jf_data_unit {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #96b8da;
    white-space: nowrap;
  }

  .jf_data_num {
    // font-size: 20px;
    // font-family: Rajdhani;
    // font-weight: 600;
    // font-style: italic;
    // color: #F6F6F6;
    // margin-right: 4px;
    white-space: nowrap;

    // width: 33px;
    // height: 15px;
    font-size: 24px;
    font-family: Rajdhani;
    font-weight: 600;
    // font-style: italic;
    color: #f6f6f6;
    line-height: 24px;
    background: linear-gradient(to left, #a6ffef 0%, #22e2ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.jf_data_zd {
  padding-left: 16px;
  box-sizing: border-box;

  .zd_img {
    left: -9px;
  }
}
</style>
