<template>
  <swiper v-if="type == '默认'" :slides-per-view="1" :space-between="0" class="moren-swiper" @swiper="onSwiper"
    @slideChange="onSlideChange" :pagination="{ clickable: true }" :navigation="{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }">
    <swiper-slide v-for="(val, key) in images" :key="key + 'Swiper'">
      <img :src="val" />
    </swiper-slide>
    <template v-if="videos && videos.length > 0">
      <swiper-slide v-for="(val, key) in videos" :key="key + 'Swiper'">
        <live-player :ref="'ref' + key" :data-id="val" :video-url="val" live="true" fluent stretch aspect="fullscreen"
          :id="idStr + key">
        </live-player>
      </swiper-slide>
    </template>


    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
  <swiper class="leadershipCare_asd" v-if="type == '列表'" :slides-per-view="'auto'" :space-between="30" :autoplay="{
    delay: 1000,
  }" @swiper="onSwiper" @slideChange="onSlideChange" @mouseenter="mouseenterAuto" @mouseleave="mouseleaveAuto">
    <swiper-slide style="width:auto;height: 100%;" v-for="(val, key) in images" :key="key + 'Swiper'">
      <img :src="
        (val.indexOf('http') != -1 ? val : + val)
      " @click="
  () => {
    $store.commit('index/set', {
      key: 'viewer',
      val: {
        list: images,
        key: key,
      },
    });
  }
" />
    </swiper-slide>
  </swiper>
  <swiper v-if="type == '自动'" :slides-per-view="perview" :space-between="10" class="moren-swiper" @swiper="onSwiper"
    @slideChange="onSlideChange" :pagination="{ clickable: true }">
    <swiper-slide v-for="(val, key) in videos" :key="key + 'Swiper'">
      <video-player :playsinline="true" :options="onOpen(val)" class="video-content video-player vjs-custom-skin">
      </video-player>
    </swiper-slide>
    <swiper-slide v-for="(val, key) in images" :key="key + 'Swiper'">
      <img :src="val" />
    </swiper-slide>
  </swiper>
</template>

<script>
// Swiper
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";
//这里导入对应样式
import "swiper/components/pagination/pagination.scss";
//导入后在SwiperCore.use([])里注册
SwiperCore.use([Pagination, Navigation, Autoplay]);

export default {
  name: "SwiperImage",
  components: { Swiper, SwiperSlide },
  props: {
    images: {
      type: Array,
      default: [],
    },
    videos: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: "默认",
    },
    idStr: {
      type: String,
      default: "player",
    },
    perview: {
      default: "auto",
    },
    swiperIndex: {
      default: 0,
    },
  },
  data() {
    return {
      swiper: false,
    };
  },
  watch: {
    swiperIndex(val) {
      this.swiper.slideToLoop(val, 500, false);
    }
  },
  activated() {
  },
  mounted() { this.chooseCss()
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'visible') {
        console.log("标签页恢复可见, 继续播放...");
      } else {
        console.log("标签页不可见, 停止播放...");
      }
    });
  },

  methods: {
    mouseenterAuto() {
      this.swiper.autoplay.start();
    },
    mouseleaveAuto() {
      this.swiper.autoplay.stop();
    },
    onSwiper(swiper) {
      console.log(swiper)
      this.swiper = swiper;
      this.swiper.autoplay.stop();
    },
    onSlideChange(swiper) {
      // console.log(swiper.activeIndex, this.images.length)
      var player1 = document.getElementById(this.idStr + (swiper.previousIndex - this.images.length));
      var player2 = document.getElementById(this.idStr + (swiper.activeIndex - this.images.length));
      if (player1 && player1.getAttribute("live")) {
        player1.setAttribute("video-url", "");

      }
      if (player2 && player2.getAttribute("live")) {
        player2.setAttribute("video-url", this.videos[swiper.activeIndex - this.images.length]);
        player2.getVueInstance().play();
        player2.getVueInstance().setMuted(true);
      }

    },
    onOpen(url) {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: false,
        muted: false,
        loop: false,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        // techOrder: ["flash"],
        sources: [
          {
            //类型
            type: "video/mp4",
            //url地址
            src: url,
          },
        ],
        //你的封面地址
        poster: "",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.moren-swiper {
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.leadershipCare_asd {
  width: 100%;
  height: 290px;
  box-sizing: border-box;
  padding: 10px 0;

  img {
    width: auto;
    height: 100%;
    // object-fit: contain;
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  z-index: 9;
  background: url("../assets/images/箭头.png") no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  opacity: 0;
}

.swiper-button-prev {
  left: 20px;
  transform: translate(0, -50%);
}

.swiper-button-next {
  right: 20px;
  transform: translate(0, -50%) rotateY(180deg);
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-disabled {
  opacity: 0 !important;
  cursor: not-allowed;
}
</style>
