<template>
  <div class="btns">
    <div
      @click="clickBtn(item)"
      :class="btnIndex == idx ? 'activeBtn' : 'disBtn'"
      v-for="(item, idx) in btnArr"
      :key="item + idx"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, onMounted, watch } from "vue";
export default {
  props: {
    btnArr: {
      default: [],
    },
    btnIndex: {
      default: "",
    },
  },
  components: {},
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      nowTitle: 0,
    });

    const clickBtn = (item) => {
      ctx.emit("clickBtn", item);
    };
    onMounted(() => {});
    return {
      state,
      clickBtn,
    };
  },
};
</script>

<style lang="scss" scoped>
.btns {
  // padding: 0 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
  cursor: pointer;

  .activeBtn {
    width: 80px;
    box-sizing: border-box;
    height: 26px;
    background: rgba(34, 226, 255, 0.8);
    border: 1px solid rgba(34, 226, 255, 1);
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #f0f4f1;
  }

  .disBtn {
    width: 80px;
    box-sizing: border-box;
    height: 26px;
    background: rgba(34, 226, 255, 0.24);
    border: 1px solid rgba(34, 226, 255, 0.3);
    border-radius: 5px;

    background: rgba(34, 226, 255, 0.8);
    border: 1px solid #22e2ff;
    opacity: 0.3;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #f0f4f1;
  }
}
</style>
