<template>
  <div class="oneCountBox">
    <div class="aaa">
      <div class="num">{{ num }}
      </div>
      <span v-if="count" class="count">{{ count }}</span>
      <span v-if="per" class="per">{{ per }}</span>

    </div>
    <div class="name">{{ name }}</div>
    <template v-for="item in smallData?smallData:[]" :key="item">
      <div class="aaa" style="margin-top:10px ;">
        <div class="smallNum">{{ item.num }}
        </div>
      </div>
      <div class="smallName">{{ item.name }}</div>
    </template>
  </div>
</template>

<script>
import { reactive, toRefs ,onMounted,getCurrentInstance} from "vue";

export default {
  props: ["num", "name", "count", 'per',"smallData"],
  setup() {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
    });
    onMounted(() => {
      _this.chooseCss()
    });

    return {
      state
    };
  },
};
</script>

<style lang="scss" scoped>
.aaa {
  display: flex;
  width: 100%;
  align-items: flex-end;

  .smallNum {
    font-size: 36px;
    font-family: Rajdhani;
    font-weight: 600;
    font-style: italic;
    color: var(--smallNum);
  }
}

.smallName {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: var(--nameAndInfo1);
}

.count {
  font-style: normal;
  background: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: var(--valligeCountPintextRow);
}

.per {
  font-size: 36px;
  font-family: Rajdhani;
  font-weight: 600;
  color: var(--oneItem);
  line-height: 36px;
  background: var(--linearGradient9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 7px;

}
</style>