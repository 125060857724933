<template>
  <div class="oneTitle">
    <div :class="'backShadow' + (party ? 'party' : '')"></div>
    <div class="imgAndTitle">
      <!-- <Icons v-if="iconShow && !wid" name="dingwei" :class="party ? 'icon4' : 'icon3'" />
      <Icons v-if="iconShow && wid" name="dingwei" class="icon2" /> -->
      <img v-if="iconShow && !wid" src="@/assets/dc/357.png" alt="" />
      <div
        :class="
          state.nowTitle == item
            ? 'nowTitle' + (party ? 'party' : '')
            : 'disTitle' + (party ? 'party' : '')
        "
        v-for="item in titles"
        :key="item"
        @click="chooseThis(item)"
        :style="[
          { fontSize: size ? size + 'px' : '24px' },
          { lineHeight: size ? size + 'px' : '24px' },
        ]"
      >
        {{ item }}
      </div>
    </div>
    <div :class="'underline' + (party ? 'party' : '')"></div>

    <div class="knowTimes divFlex" v-if="times">
      <span>识别次数</span>
      <span>{{ times }}</span>
    </div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, onMounted, watch } from "vue";
import Icons from "./Icons";
export default {
  props: {
    titles: {
      default: "",
    },
    party: {
      default: "",
    },
    size: {
      default: "",
    },
    iconShow: {
      default: true,
    },
    wid: {
      default: false,
    },
    times:false
  },
  components: {
    Icons,
  },
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      nowTitle: 0,
    });
    watch(
      () => props.titles,
      (val) => {
        state.nowTitle = val[0];
      },
      {
        immediate: true,
      }
    );
    const chooseThis = (item) => {
      state.nowTitle = item;
      ctx.emit("chooseThis", item);
    };
    onMounted(() => {
      _this.chooseCss();
    });
    return {
      state,
      chooseThis,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon3 {
  width: 24px;
  height: 30px;
  color: var(--oneItem);
  margin-right: 10px;
}

.icon2 {
  width: 18px;
  height: 16px;
  color: var(--oneItem);
  margin-right: 5px;
}

.icon4 {
  width: 24px;
  height: 30px;
  color: rgba(255, 203, 80, 1);
  margin-right: 10px;
}

.oneTitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .backShadow {
    position: absolute;
    left: 0;
    top: 60%;
    transform: translate(0, -50%);
    width: 100%;
    height: 14px;
    z-index: 0;
    background: linear-gradient(
      to right,
      rgba(166, 255, 239, 0.1) 0%,
      rgba(166, 255, 239, 0) 100%
    );
  }

  .backShadowparty {
    position: absolute;
    left: 0;
    top: 60%;
    transform: translate(0, -50%);
    width: 100%;
    height: 14px;
    z-index: 1;
    background: var(--linearGradient17);
  }

  .imgAndTitle {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      object-fit: contain;
      margin-right: 10px;
    }

    .nowTitle {
      white-space: nowrap;
      font-size: 24px;
      line-height: 24px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: rgba(1, 180, 255, 1);
      background: linear-gradient(to right, #22e2ff 0%, #a6ffef 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 16px;
      cursor: pointer;
      transition: all 0.3s;
    }

    .disTitle {
      white-space: nowrap;
      cursor: pointer;
      font-size: 20px !important;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: rgba(1, 180, 255, 1);
      opacity: 0.5;
      background: linear-gradient(to right, #22e2ff 0%, #a6ffef 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 16px;
      transition: all 0.3s;
    }

    .nowTitleparty {
      white-space: nowrap;
      font-size: 24px;
      line-height: 24px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: var(--servicesPin-typeBtn);
      background: var(--linearGradient18);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 16px;
      cursor: pointer;
      transition: all 0.3s;
    }

    .disTitleparty {
      white-space: nowrap;
      cursor: pointer;
      font-size: 20px !important;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: var(--servicesPin-typeBtn);
      opacity: 0.5;
      background: var(--linearGradient18);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 16px;
      transition: all 0.3s;
    }
  }

  .underline {
    width: 100%;
    max-width: 100%;
    height: 1px;
    background: linear-gradient(0deg, #22e2ff 0%, #a6ffef 100%);
    opacity: 0.3;
  }

  .underlineparty {
    width: 340px;
    max-width: 100%;
    height: 1px;
    background: var(--linearGradient20);
  }
}

.knowTimes {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #567782;
  position: absolute;
  right: 0;
  
  span:nth-child(2) {
    font-size: 24px;
    font-family: Square722;
    font-weight: bold;
    font-style: italic;
    color: #22e2ff;
    line-height: 24px;
  }
}
</style>
