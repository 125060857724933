import {
  reactive,
  nextTick,
  watch,
  ref
} from "vue";
// import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export const globalData = reactive({
  menuArr: [],
  rightStatus: false,
  socketData:{}
});