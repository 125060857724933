<template>
    <div class="titleBox">
        <img class="icon" src="@/assets/sea/icon/标题.png" alt="">
        <span class="text">{{ title }}</span>
        <div class="bottomLine"></div>
        <div class="rightDiv"></div>
    </div>
</template>
  
<script>
// VUE
import {
    h,
    ref,
    reactive,
    watch,
    computed,
    getCurrentInstance,
    onMounted,
    onActivated,
    onDeactivated,
} from "vue";

// Components


export default {
    components: {

    },
    props: ['title'],

    setup(props, context) {
        const _this = getCurrentInstance().proxy;
        let state = reactive({

        }); // 数据


        onMounted(async () => {

        });

        return {
            // 变量
            state,
            //方法
        };
    },
};
</script>
  
<style lang="scss" scoped>
.titleBox {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    .icon {
        width: 20px;
        object-fit: contain;
        margin: 0 10px 0 -10px;
    }

    .text {
        font-size: 24px;
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        color: #FFFFFF;
        background: linear-gradient(to right, #22E2FF 0%, #0090FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .bottomLine {
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(0, 144, 255, 0)  0%, rgba(0, 144, 255, 1) 100%);
        opacity: 0.4;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .rightDiv {
        width: 5px;
        height: 24px;
        background: linear-gradient(0deg, #0090FF 0%, rgba(0, 144, 255, 0.3) 100%);
        opacity: 0.2;
        position: absolute;
        bottom: 0;
        right: 0;
    }
}
</style>
  