<template>
  <div class="bigDiv">
    <!-- <el-drawer v-model="show"  :with-header="false" direction="ltr" size="100%" :modal="false"> -->
    <svg
      @click="clearYSY()"
      class="box_camera_clear"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"
      ></path>
    </svg>
    <!-- v-if="cameraType" -->
    <div class="box_camera">
      <div class="box_camera_content" id="videContainer_Box" ref="viewtools">
        <div
          id="videContainer"
          style="width: 100%; height: 100%; margin-top: -48px"
        ></div>
        <PresuppositionView
          v-if="camera.type == '2'"
          :setUp="true"
          :array="viewArr"
          :token="token"
          :cameraInfo="camera"
          type="2"
          @setViewArray="(val) => (viewArr = val)"
          @setiStreamType="setiStreamType"
        />
        <Viewcamera
          style="bottom: 50px"
          v-if="isHui && camera.type == '2'"
          :token="token"
          :cameraInfo="camera"
        />
      </div>
      <div class="box_camera_datas">
        <div class="box_camera_name">
          <!-- <img v-if="camera.type == '1'" src="@/assets/static/f6.png" />
          <img v-if="camera.type == '2'" src="@/assets/static/f5.png" /> -->
          {{ camera.type == "1" ? "AI智能感知摄像机" : "高位球机" }}
          <!-- {{ camera.type == "1" ? "定点直播" : "高位球机" }} -->
        </div>
        <div class="box_camera_data">
          设备名称：<span>{{ camera.name || camera.equipmentName }}</span>
        </div>
        <div class="box_camera_data">
          设备型号：<span>{{ camera.serialNumber }}</span>
        </div>
        <div class="box_camera_data">
          设备位置：<span>{{ camera.address }}</span>
        </div>
        <div class="box_camera_data">
          是否在线：<span>{{ camera.status == "2" ? "是" : "否" }}</span>
        </div>

        <button class="box_camera_button" @click="lishi()">
          {{ isHui ? "历史回放" : "实时直播" }}
        </button>
      </div>
    </div>

    <!-- <div class="rox_camera" v-else>
      <div class="box_camera_content">
        <div id="videContainer"></div>
      </div>
      <div class="box_camera_datas">
        <div class="box_camera_name">
          <img src="@/assets/static/f4.png" />高位全景摄像机
        </div>
        <div class="box_camera_data">
          设备名称：<span>{{ camera.equipmentName }}</span>
        </div>
        <div class="box_camera_data">
          设备位置：<span>{{ camera.address }}</span>
        </div>
        <div class="box_camera_data">
          是否在线：<span>{{ camera.status == "2" ? "是" : "否" }}</span>
        </div>
        <button class="box_camera_button" @click="lishi()">
          {{ isHui? "历史回放": "实时直播" }}
        </button>
      </div>
    </div> -->
    <!-- </el-drawer> -->
  </div>
</template>

<script>
import EZUIKit from "ezuikit-js";
import { Viewcamera } from "@/componentss";
import PresuppositionView from "./PresuppositionView.vue";
import { getMonitor, addressGet, getVideoAddress, createSeaPage } from "@/api";
// var window.player = null;
export default {
  name: "Ysy",
  components: { Viewcamera, PresuppositionView },
  props: ["camera"],
  watch: {},
  data() {
    return {
      show: true,
      isHui: true,
      token: false,
      cameraType: false,
      viewArr: [],
    };
  },
  mounted() {
    createSeaPage({ monitorId: this.camera.id }).then((res) => {
      // console.log(res)
      this.viewArr = res.data.records;
    });
    getMonitor().then((res) => {
      // console.log(res)
      this.token = res.data.accessToken;
      // console.log(this.camera)
      // console.log(this.camera.type != 3)
      this.cameraType = this.camera.type != "1";
      this.ec();
    });
  },
  setup(props, ctx) {
    const clearClick = (data) => {
      ctx.emit("clearClick", data);
    };
    return { clearClick };
  },
  methods: {
    async clearYSY(fun = false) {
      // console.log(window.player);
      // console.log(window.player.stop);
      // console.log(window.player.destroy);
      // console.log(this.isHui)
      // console.log(111111);
      if (window.player) {
        // console.log(2222);
        await window.player.stop().then(async (data) => {});
        await window.player.destroy();
        window.player = null;
      }

      console.log(3333);

      if (fun === false) {
        // console.log(444444444)
        this.show = false;
        setTimeout(() => {
          this.clearClick();
        }, 200);
      } else {
        // console.log(555555555);
        fun();
      }
    },

    lishi() {
      this.clearYSY(() => {
        // console.log(this.isHui);
        if (this.isHui) {
          const yy = new Date().getFullYear();
          const MM =
            new Date().getMonth() + 1 < 10
              ? "0" + (new Date().getMonth() + 1)
              : new Date().getMonth() + 1;
          const dd =
            new Date().getDate() < 10
              ? "0" + new Date().getDate()
              : new Date().getDate();
          const HH =
            new Date().getHours() < 10
              ? "0" + new Date().getHours()
              : new Date().getHours();
          const mm =
            new Date().getMinutes() < 10
              ? "0" + new Date().getMinutes()
              : new Date().getMinutes();
          const ss =
            new Date().getSeconds() < 10
              ? "0" + new Date().getSeconds()
              : new Date().getSeconds();
          addressGet({
            accessToken: this.token,
            deviceSerial: this.camera.serialNumber,
            channelNo: Number(this.camera.channelNumber),
            protocol: 1,
            quality: 1,
            startTime: yy + "-" + MM + "-" + dd + " 00:00:00",
            stopTime: yy + "-" + MM + "-" + dd + " " + HH + ":" + mm + ":" + ss,
            type: "2",
            code: this.camera.code,
          }).then((res) => {
            console.log(res);
            let dom = document.getElementsByClassName("box_camera_content")[0];
            let width = dom.offsetWidth;
            let height = dom.offsetHeight;
            new EZUIKit.EZUIKitPlayer({
              id: "videContainer", // 视频容器ID
              accessToken: this.token,
              url: res.data.url,
              // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
              template: "pcRec",
              // plugin: ['talk'], // 加载插件，talk-对讲
              width,
              height: height,
            });
          });
        } else {
          this.ec();
        }
        this.isHui = !this.isHui;
      });
    },
    ec() {
      getVideoAddress({
        serialNumber: this.camera.serialNumber,
        channelNo: Number(this.camera.channelNumber),
        protocol: 1,
        quality: 1,
        code: this.camera.code,
      }).then((rest) => {
        console.log(rest);
        // if (!rest.data.data.url) return false;
        if (!rest.data?.data?.url)
          return this.$message({
            message: rest.data.msg,
            type: "warning",
          });
        let dom = document.getElementsByClassName("box_camera_content")[0];
        let width = dom.offsetWidth;
        let height = dom.offsetHeight + 48;
        window.player = new EZUIKit.EZUIKitPlayer({
          id: "videContainer", // 视频容器ID
          accessToken: this.token,
          url: rest.data.data.url,
          // simple - 极简版; pcLive-pc直播；pcRec-pc回放；mobileLive-移动端直播；mobileRec-移动端回放;security - 安防版;voice-语音版;
          template: "pcLive",
          // plugin: ['talk'], // 加载插件，talk-对讲
          width,
          height: height,
        });
      });
    },
  },
  beforeDestroy() {
    this.clearYSY();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/default.scss";

.bigDiv {
  width: 1380px;
  // height: 844px;
  position: fixed;
  left: 0;
  top: 130px;
  z-index: 99;
  box-sizing: border-box;
}

.box_camera {
  width: 1380px;
  height: 816px;
  background: rgba(40, 40, 40, 1);
  box-shadow: 0px 5px 15px 0px rgba(36, 98, 112, 0.1);
  border-radius: 0px 20px 20px 0px;
  position: fixed;
  // left: 0;
  // top: 155px;
  box-sizing: border-box;
  padding: 10px;

  .box_camera_content {
    width: 1360px;
    height: 744px;
    background: #000;
    border-radius: 14px;
    overflow: hidden;
    position: relative;
  }
}

.rox_camera {
  width: 1380px;
  height: 640px;
  background: rgba(40, 40, 40, 1);
  box-shadow: 0px 5px 15px 0px rgba(36, 98, 112, 0.1);
  border-radius: 0 20px 20px 0;
  position: fixed;
  left: 0;
  top: 155px;
  // bottom: 200px;
  box-sizing: border-box;
  padding: 10px;

  .box_camera_content {
    width: 100%;
    height: 520px;
    background: #000;
    border-radius: 14px;
    overflow: hidden;
    position: relative;
  }
}

.box_camera_datas {
  margin: 0px auto 0 auto;
  max-width: 1500px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  // padding-right: 100px;
  .box_camera_name {
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: rgba(208, 242, 255, 1);

    img {
      width: 22px;
      height: 22px;
      margin-right: 5px;
    }
  }

  .box_camera_data {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(107, 142, 162, 1);
    line-height: 26px;

    span {
      color: rgba(166, 196, 207, 1);
    }
  }
}

.box_camera_clear {
  position: absolute;
  left: 1300px;
  top: 20px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 9;
  color: rgb(236, 87, 78);
  background: #fff;
  border-radius: 50%;
}

.box_camera_button {
  width: 160px;
  height: 40px;
  background: #22e2ff;
  border: 1px solid #22e2ff;
  box-shadow: 0px 5px 15px 0px rgba(1, 198, 255, 0.25),
    0px 10px 50px 0px rgba(1, 198, 255, 0.25);
  border-radius: 18px;
  margin-bottom: -5px;
  font-size: 24px;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  color: #ffffff;
  // -webkit-text-stroke: 0.5px #03CC8C;
  // text-stroke: 0.5px #03CC8C;
  cursor: pointer;
}

#videContainer {
  width: 100%;
  height: 100%;
  margin-top: -48px;
}
</style>
