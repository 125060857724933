<template>
  <div class="oneTitle">
    <div class="backShadow"></div>
    <div class="imgAndTitle">
      <img src="@/assets/dc/357.png" alt="" />
      <!-- <Icons name="dingwei" class="icon3" /> -->
      <div class="nowTitle">{{ title }}</div>
    </div>
    <div class="underline"></div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, onMounted } from "vue";
import Icons from "./Icons";
export default {
  props: ['title'],
  components: {
    Icons,
  },
  setup() {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
    });

    onMounted(() => {
      _this.chooseCss()
    });
    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.icon3 {
  width: 24px;
  height: 30px;
  color: var(--oneItem);
  margin-right: 10px;
}

.oneTitle {
  display: flex;
  flex-direction: column;
  position: relative;

  .backShadow {
    position: absolute;
    left: 0;
    top: 60%;
    transform: translate(0, -50%);
    width: 100%;
    height: 14px;
    z-index: -1;
    background: var(--linearGradient16);
  }

  .imgAndTitle {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      object-fit: contain;
      margin-right: 10px;
    }

    .nowTitle {
      font-size: 30px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: rgba(1, 180, 255, 1);
      background: linear-gradient(to right, #22E2FF 0%, #A6FFEF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .underline {
    width: 250px;
    height: 1px;
    background: linear-gradient( to right, #22E2FF 0%, #A6FFEF 100%);
  }
}
</style>