<template>
  <div :class="[party ? 'typep' : 'types', wrap ? width ? 'wrapwidth' : 'wrap' : '']">
    <button :style="{
      flexGrow: grow ? '0' : '1',
      borderRadius: round ? '24px' : '',
    }" v-for="(val, key) in array" :key="key + val" :class="[val.id === type ? 'true' : '', icons ? 'dis' : '']"
      @click="type = val.id">
      <img v-if="icons" :src="icons[key]" />
      {{ val.name || val.dictValue }}
    </button>
  </div>
</template>

<script>
export default {
  name: "TypeButton",
  data() {
    return {
      arr: ['高位全景', '小区监控', "平安城市", "雪亮工程"]
    };
  },
  setup(props, ctx) {
    const typeButton = (data) => {
      ctx.emit("typeButton", data);
    };
    return { typeButton };
  },
  props: {
    array: Array,
    type: String,
    round: {
      type: Boolean,
      default: false,
    },
    grow: {
      type: Boolean,
      default: false,
    },
    icons: {
      type: Boolean || Array,
      default: false,
    },
    wrap: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Boolean,
      default: false,
    },
    party: {
      type: Boolean,
      default: false,
    },
    numbers: {
      type: Array,
      default: [],
    },
  },
  watch: {
    type(val) {
      this.typeButton(val);
    },
  },
  mounted() { this.chooseCss() },
  methods: {
    focusing() {
      this.flag = true;
      this.$nextTick(() => {
        document.getElementById("search_input").focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// 通用类型组
.types {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  flex-shrink: 0;

  button {
    flex-grow: 1;
    margin-left: 4px;
    margin-bottom: 4px;
    border-radius: 5px;
    text-align: center;
    height: 24px;
    line-height: 20px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    width: 25%;
    opacity: 0.3;
    cursor: pointer;
    color: rgba(203, 237, 233, 1);
    background: rgba(34, 226, 255, 0.8);
    // border: 1px solid var(--disBtn);
  }

  button:first-of-type {
    margin-left: 0;
  }

  button:hover {
    color: var(--buttonhover);
    background: var(--disBtn);
    border: 1px solid var(--servicesPinTypeclass1);
  }

  .true {
    color: rgba(203, 237, 233, 1) !important;
    background: rgba(1, 180, 255, 0.8);
    opacity: 1;
  }
}

.wrap {
  justify-content: flex-start;
  flex-wrap: wrap;

  button {
    width: calc(25% - 4px) !important;
    flex-grow: 0 !important;
  }

  button:first-of-type {
    margin-left: 4px !important;
  }
}

.wrapwidth {
  justify-content: flex-start;
  flex-wrap: wrap;

  button {
    width: auto;
    padding: 0 10px;
    flex-grow: 0 !important;
  }

  button:first-of-type {
    margin-left: 4px !important;
  }
}

.dis {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px !important;

  img {
    margin-right: 5px;
  }
}

.typep {
  display: flex;
  width: 100%;
  margin-top: 10px;
  flex-shrink: 0;

  button {
    flex-grow: 1;
    margin-left: 4px;
    margin-bottom: 4px;
    border-radius: 5px;
    text-align: center;
    height: 24px;
    line-height: 20px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    // width: 25%;

    cursor: pointer;
    color: var(--typepbutton);
    background: var(--typepbutton1);
    border: 1px solid var(--typepbutton2);
  }

  button:first-of-type {
    margin-left: 0;
  }

  button:hover {
    color: var(--typepbutton);
    background: var(--buttonhover1);
    border: 1px solid var(--buttonhover2);
  }

  .true {
    color: var(--buttontrue) !important;
    background: var(--contentBox) !important;
    border: 1px solid var(--buttontrue1) !important;
  }
}
</style>
