<template>
  <div v-if="show" :id="id" class="amcharts"></div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);

export default {
  name: "Amcharts",
  props: {
    id: String,
    show: Boolean,
    data: Array,
    colors: {
      default: false,
      type: Array,
    },
  },
  watch: {
    show(val, oldVal) {
      this.drawing();
    },
  },
  mounted() {
    this.chooseCss()

    this.drawing();
  },
  methods: {
    drawing() {
      if (!this.show) return false;
      this.$nextTick(() => {
        const chart = am4core.create(this.id, am4charts.PieChart3D);
        chart.hiddenState.properties.opacity = 1; // this creates initial fade-in

        chart.data = this.data;

        chart.innerRadius = am4core.percent(40);

        chart.depth = 15;
        chart.angle = 25;
        chart.startAngle = -270;
        chart.endAngle = 90;
        chart.paddingLeft = `50%`;
        chart.paddingTop = 10;
        chart.paddingBottom = 10;

        chart.contextMenuDisabled = true;

        var series = chart.series.push(new am4charts.PieSeries3D());
        series.dataFields.value = "litres";
        series.dataFields.depthValue = "litres";
        series.dataFields.category = "country";
        var colors = [
          am4core.color("rgba(255, 230, 21, 1)"),
          am4core.color("rgba(19, 201, 130, 1)"),
          am4core.color("rgba(69, 205, 245, 1)"),
          am4core.color("rgba(0, 126, 255, 1)"),
          am4core.color("rgba(255, 117, 33, 1)"),
        ];
        if (this.colors) {
          colors = [];
          this.colors.forEach((e) => {
            colors.push(am4core.color(e));
          });
        }

        series.colors.list = colors
        // series.colors.step = 3;
        series.ticks.template.disabled = true;
        series.labels.template.disabled = true;

        let eles = document.querySelectorAll("[aria-labelledby$=-title]");
        eles.forEach((ele) => {
          ele.style.visibility = "hidden";
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.amcharts {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}
</style>
