<template>
    <div class="scenicTitle">
        <img class="backImg" src="@/assets/img/D115.png" alt="">
        <img class="leftIcon" src="@/assets/img/b17.png" alt="">
        <div class="textName">
            {{ title }}
        </div>
    </div>
</template>
  
<script>
export default {
    name: "Title",
    props: {
        title: String,
    },
    data() {
        return {

        };
    },
};
</script>
  
<style lang="scss" scoped>
.scenicTitle {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;

    .backImg {
        // width: 100%;
        object-fit: contain;
        position: absolute;
        left: 0;
        bottom: -10px;
    }

    .leftIcon {
        object-fit: contain;
        margin: 0 0px 5px 8px;
    }

    .textName {
        margin-bottom: 5px;
        font-size: 32px;
        font-family: HYKuiSu;
        // font-weight: bold;
        color: #0ABDA4;
        line-height: 41px;
        text-shadow: 0px 2px 2px #FFFFC7, 0px 5px 15px rgba(10, 189, 164, 0.3);
    }
}
</style>
  