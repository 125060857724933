<template>
  <div>
    <div class="rtCiecle"></div>
    <div class="ltCiecle"></div>
    <div class="rbCiecle"></div>
    <div class="lbCiecle"></div>
    <div class="tLine"></div>
    <div class="bLine"></div>
    <div class="rLine"></div>
    <div class="lLine"></div>
  </div>
</template>
