<template>
  <div class="detailButtons">
    <div :class="state.item == item ? 'activeButton' : 'activeButton disActiveButton'" v-for="(item, idx) in address"
      :key="idx + 'fdsfhgt'" @click="chooseThisBtn(item)">
      {{ item }}
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch,onMounted,getCurrentInstance } from "vue";

export default {
  props: ["address"],
  setup(props, ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      item: false,
    });
    watch(
      () => props.address,
      (val) => {
        state.item = val[0]
      }, {
      immediate: true
    }
    );
    onMounted(() => {
            _this.chooseCss()
        });
    const chooseThisBtn = (item) => {
      state.item = item;
    };
    return {
      state,
      chooseThisBtn,
    };
  },
};
</script>

<style lang="scss" scoped>
.detailButtons {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 12px;
  cursor: pointer;

  .activeButton {
    background: var(--activeButton);
    box-shadow: 0px 1px 0px 0px var(--activeButton1);
    border-radius: 10px;
    padding: 5px 9px;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: var(--servicesPin-typeBtn1);
    margin-right: 6px;
  }

  .disActiveButton {
    opacity: 0.3;
  }
}
</style>