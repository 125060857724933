<template>
    <div class="scenicTitle">
        <img class="backImg" src="@/assets/img/b1.png" alt="">
        <!-- <lottie v-if="dataJson" style="width:auto;height:auto;position:absolute;left:0px;bottom:0px;z-index:0;"
            :options="{
                animationData: dataJson,
                loop: true,
            }" /> -->
        <img class="leftIcon" src="@/assets/img/b17.png" alt="">
        <div class="textName">
            {{ title }}
        </div>
    </div>
</template>
  
<script>
import lottie from 'vue-lottie'
import dataJson from '@/assets/jsons/07景点列表.json'
export default {
    components: {
        lottie
    },
    name: "Title",
    props: {
        title: String,
    },
    data() {
        return {
            dataJson
        };
    },
};
</script>
  
<style lang="scss" scoped>
.scenicTitle {
    // width: 100%;
    // height: auto;
    // position: relative;
    // display: flex;
    // align-items: center;

    width: 100%;
    height: 46px;
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    flex-shrink: 0;


    .backImg {
        width: 100%;
        object-fit: contain;
        position: absolute;
        left: 0;
        bottom: 0;
    }

    .leftIcon {
        object-fit: contain;
        margin: 0 10px 5px 8px;
    }

    .textName {
        margin-bottom: 5px;
        font-size: 24px;
        font-family: SourceHanSerifSC;
        font-weight: 800;
        color: #0ABDA4;
        line-height: 41px;
        text-shadow: 0px 2px 2px #FFFFC7, 0px 5px 15px rgba(10, 189, 164, 0.3);
    }
}
</style>
  