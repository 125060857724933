<template>
  <!-- 移动视角 -->
  <div class="view_live">
    <div class="view">
      <div class="view_yuan">
        <div class="view_sxzy" @mousedown="mouseDownPTZControl(4)" @mouseup="mouseUpPTZControl()">
          <svg class="iconIn" style="transform: rotate(45deg); right: 0px; bottom: 0px" viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
            <path fill="currentColor" d="M672 192 288 511.936 672 832z"></path>
          </svg>
        </div>
        <div class="view_sxzy" @mousedown="mouseDownPTZControl(0)" @mouseup="mouseUpPTZControl()">
          <svg svg class="iconIn" style="
              transform: translate(-50%, -50%) rotate(90deg);
              left: 50%;
              bottom: 0;
            " viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
            <path fill="currentColor" d="M672 192 288 511.936 672 832z"></path>
          </svg>
        </div>
        <div class="view_sxzy" @mousedown="mouseDownPTZControl(6)" @mouseup="mouseUpPTZControl()">
          <svg svg class="iconIn" style="transform: rotate(135deg); left: 0; bottom: 0" viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
            <path fill="currentColor" d="M672 192 288 511.936 672 832z"></path>
          </svg>
        </div>
        <div class="view_sxzy" @mousedown="mouseDownPTZControl(2)" @mouseup="mouseUpPTZControl()">
          <svg svg class="iconIn" style="
              transform: translate(-50%, -50%) rotate(0deg);
              top: 50%;
              left: 40%;
            " viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
            <path fill="currentColor" d="M672 192 288 511.936 672 832z"></path>
          </svg>
        </div>
        <div class="view_sxzy" @click="clickGoPreset(1)">
          <div class="shuaxing">
            <div class="shuaxing-div">
              <svg class="shuaxing-div-img" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
                <path fill="currentColor"
                  d="M771.776 794.88A384 384 0 0 1 128 512h64a320 320 0 0 0 555.712 216.448H654.72a32 32 0 1 1 0-64h149.056a32 32 0 0 1 32 32v148.928a32 32 0 1 1-64 0v-50.56zM276.288 295.616h92.992a32 32 0 0 1 0 64H220.16a32 32 0 0 1-32-32V178.56a32 32 0 0 1 64 0v50.56A384 384 0 0 1 896.128 512h-64a320 320 0 0 0-555.776-216.384z">
                </path>
              </svg>
            </div>
          </div>
        </div>
        <div class="view_sxzy" @mousedown="mouseDownPTZControl(3)" @mouseup="mouseUpPTZControl()">
          <svg svg class="iconIn" style="
              transform: translate(-50%, -50%) rotate(180deg);
              top: 50%;
              left: 60%;
            " viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
            <path fill="currentColor" d="M672 192 288 511.936 672 832z"></path>
          </svg>
        </div>
        <div class="view_sxzy" @mousedown="mouseDownPTZControl(5)" @mouseup="mouseUpPTZControl()">
          <svg svg class="iconIn" style="
              transform: rotate(-45deg); right: 0; top: 0
            " viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
            <path fill="currentColor" d="M672 192 288 511.936 672 832z"></path>
          </svg>
        </div>
        <div class="view_sxzy" @mousedown="mouseDownPTZControl(1)" @mouseup="mouseUpPTZControl()">
          <svg svg class="iconIn" style="
              transform: translate(-50%, 50%) rotate(-90deg);
              left: 50%;
              top: 0;
            " viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
            <path fill="currentColor" d="M672 192 288 511.936 672 832z"></path>
          </svg>
        </div>
        <div class="view_sxzy" @mousedown="mouseDownPTZControl(7)" @mouseup="mouseUpPTZControl()">
          <svg svg class="iconIn" style="
              transform: rotate(-135deg); left: 0; top: 0
            " viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
            <path fill="currentColor" d="M672 192 288 511.936 672 832z"></path>
          </svg>
        </div>
      </div>
      <div class="view_button">
        <div @mousedown="mouseDownPTZControl(9)" @mouseup="mouseUpPTZControl()" class="jian">
          <svg class="iconIn" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
            <path fill="currentColor" d="M128 544h768a32 32 0 1 0 0-64H128a32 32 0 0 0 0 64z"></path>
          </svg>
        </div>
        <div @mousedown="mouseDownPTZControl(8)" @mouseup="mouseUpPTZControl()" class="jia">
          <svg class="iconIn" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa="">
            <path fill="currentColor"
              d="M480 480V128a32 32 0 0 1 64 0v352h352a32 32 0 1 1 0 64H544v352a32 32 0 1 1-64 0V544H128a32 32 0 0 1 0-64h352z">
            </path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ptzStart, ptzStop, presetMove } from "@/api";
import { Throttle, Debounce } from "@/utils";
export default {
  name: "View",
  data() {
    return {
      iPTZIndex: false,
    };
  },
  props: ["cameraInfo", "token"],
  watch: {},
  mounted() {
    this.chooseCss()
    console.log(this.cameraInfo)
  },
  methods: {
    mouseDownPTZControl: Throttle(function (iPTZIndex) {
      // console.log(this.token,'this.token');
      // console.log(this.cameraInfo,'this.cameraInfo');
      this.iPTZIndex = iPTZIndex;
      ptzStart({
        accessToken: this.token,
        deviceSerial: this.cameraInfo.serialNumber || this.cameraInfo.deviceNumber,
        channelNo: Number(this.cameraInfo.channelNumber),
        direction: iPTZIndex,
        speed: 1,
      })
        .then((res) => { });
    }, 800),
    mouseUpPTZControl: Throttle(function () {
      ptzStop({
        accessToken: this.token,
        deviceSerial: this.cameraInfo.serialNumber || this.cameraInfo.deviceNumber,
        channelNo: Number(this.cameraInfo.channelNumber),
        direction: this.iPTZIndex,
      })
        .then((res) => {
          this.iPTZIndex = false;
        });
    }, 800),
    // 调用预置点
    clickGoPreset(i) {
      presetMove({
        accessToken: this.token,
        deviceSerial: this.cameraInfo.serialNumber || this.cameraInfo.deviceNumber,
        channelNo: Number(this.cameraInfo.channelNumber),
        index: i,
      })
        .then((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.view_live {
  position: absolute;
  height: 170px;
  bottom: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid var(--farmers-number-type-label1);
  border-radius: 10px;
  overflow: hidden;
  z-index: 98;
  transition: all 0.5s;
  // width: 140px;
  display: flex;

  .view {
    width: 140px;
    height: 100%;

    .view_yuan {
      width: 100px;
      height: 100px;
      margin: 16px auto;
      margin-bottom: 10px;
      border-radius: 50%;
      background: rgba(239, 230, 218, 0.4);
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;

      .view_sxzy {
        width: calc(100% / 3);
        position: relative;
        height: calc(100% / 3);
        cursor: pointer;

        .shuaxing {
          width: 48px;
          height: 48px;
          left: 50%;
          top: 50%;
          position: absolute;
          border-radius: 50%;
          background: transparent;
          border: 1px solid rgba(252, 224, 175, 0.4);
          display: flex;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;
          box-shadow: 0px 0px 20px 0px rgba(0, 10, 89, 0.54);
          transition: all 0.5s;
          -webkit-transition: all 0.5s;
          -moz-transition: all 0.5s;
          -ms-transition: all 0.5s;
          -o-transition: all 0.5s;
          transform: translate(-50%, -50%) rotate(0deg);
          -webkit-transform: translate(-50%, -50%) rotate(0deg);
          -moz-transform: translate(-50%, -50%) rotate(0deg);
          -ms-transform: translate(-50%, -50%) rotate(0deg);
          -o-transform: translate(-50%, -50%) rotate(0deg);
          z-index: 9;

          .shuaxing-div {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(239, 230, 218, 1);

            .shuaxing-div-img {
              color: rgba(170, 148, 124, 1);
              width: 60%;
              height: 60%;
            }
          }
        }

        .shuaxing:hover {
          transform: translate(-50%, -50%) rotate(360deg);
          -webkit-transform: translate(-50%, -50%) rotate(360deg);
          -moz-transform: translate(-50%, -50%) rotate(360deg);
          -ms-transform: translate(-50%, -50%) rotate(360deg);
          -o-transform: translate(-50%, -50%) rotate(360deg);
        }

        i {
          position: absolute;
          color: rgba(239, 230, 218, 1);
          font-size: 20px;
        }
      }

      .view_sxzy:hover {
        i {
          position: absolute;
          color: rgba(114, 197, 131, 1);
          font-size: 20px;
        }
      }
    }

    .view_button {
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .jian,
      .jia {
        width: 30px;
        height: 30px;
        cursor: pointer;
        position: relative;
        background: rgba(239, 230, 218, 0.4);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          color: rgba(239, 230, 218, 1);
        }
      }

      .jian:hover,
      .jia:hover {
        i {
          color: rgba(114, 197, 131, 1);
          font-weight: 900;
        }
      }
    }
  }
}

.view_live1 {
  display: flex;
  flex-grow: 1;
  width: 60px;
  flex-direction: column;

  .view_live_button {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid var(--farmers-number-type-label1);
    box-sizing: border-box;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .view_live_button:hover {
    background: rgba(255, 255, 255, 0.1);
  }
}

.iconIn {
  color: rgba(239, 230, 218, 1);
  width: 70%;
  height: 70%;
  position: absolute;
}

.iconIn:hover {
  color: unset;
}
</style>
