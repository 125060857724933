<template>
  <div class="menus">
    <div
      @click="clickThis(globalData.menuArr[idx])"
      v-for="(item, idx) in globalData.menuArr"
      :key="idx + 'dfljsnfjs'"
    >
      <img
        :src="
          title == item.title
            ? require(`@/assets/dc/${idx}${idx}.png`)
            : require(`@/assets/dc/${idx}.png`)
        "
        alt=""
      />
      <span class="name">{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  inject: ["globalData"],
  data() {
    return {
      title: "",
      routeArr: [
        "/Overview",
        "/VillageDivision",
        "/NowScenic",
        "/PersonagesRecords",
        "/GrassrootsOrganizations",
        "/Education",
        "/LifeStyle",
        "/ChronicleEvent",
      ],
    };
  },
  computed: {
    path() {
      return this.$route.path;
    },
  },
  watch: {
    path(val) {
      if (!this.routeArr.includes(val)) {
        return (this.title = "");
      }
      let obj = this.globalData.menuArr.find((e) => {
        return e.url == val;
      });
      this.title = obj.title;
    },
  },
  created() {
    // getBGMenu().then((res) => {
    // this.globalData.menuArr = res.data;
    if (!this.routeArr.includes(this.path)) {
      return (this.title = "");
    }
    let href = window.location.href;
    let titleArr = this.globalData.menuArr.find((e) => {
      return href.indexOf(e.url) != -1;
    });
    this.title = titleArr.title;
    // });
  },
  mounted() {},
  methods: {
    clickThis(val) {
      if (this.title == val.title) return;
      this.title = val.title;
      this.$router.push(val.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.menus {
  position: fixed;
  left: 30px;
  top: 180px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  > div {
    font-size: 24px;
    font-family: SourceHanSerifSC;
    font-weight: 800;
    color: rgba(236, 255, 252, 1);
    line-height: 41px;
    text-shadow: 0px 1px 2px rgba(36, 98, 112, 0.2);
    position: relative;
  }

  .name {
    position: absolute;
    z-index: 1;
    left: 87px;
    top: 40%;
    transform: translate(0, -50%);
  }
}
</style>
