<template>
  <div class="oneCountSmallBox" :style="boxWidth ? `width:${boxWidth}px;` : ``">
    <img class="leftIcon" v-if="image" :src="image" alt="" />
    <div class="smallTextBox">
      <div class="numberAndName">
        <div class="number">{{ num }}</div>
        <div class="name">{{ name }}</div>
      </div>
      <div class="progressBox">
        <div class="percent">{{ progress }}</div>
        <div class="progressBack" :style="progressWidth ? `width:${progressWidth}px;` : ``">
          <div :style="`width:` + progress" :class="'progress' + ' progress' + path"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, computed, getCurrentInstance,onMounted } from "vue";

export default {
  props: ["num", "name", "progress", 'image', 'progressWidth', 'boxWidth'],
  setup() {
    const _this = getCurrentInstance().proxy;
    const path = computed(() => _this.$route.path.split("/")[1]);
    const state = reactive({
      count: 0,
    });
    onMounted(() => {
            _this.chooseCss()
        });
    return {
      state,
      path
    };
  },
};
</script>

<style lang="scss" scoped>
.progressImportantMan {
  background: linear-gradient(to right, var(--progressBack) 0%, var(--progressImportantMan) 100%) !important;
}
.progressImportantPart{
  background: linear-gradient(to right, var(--ssprogress1) 0%, var(--progressImportantPart) 100%) !important;
}
.progressImportantPlace{
  background: linear-gradient(to right, var(--ssprogress1) 0%, var(--ssbtn6) 100%) !important;
}

</style>