<template>
  <div class="allInfo">
    <div class="intr">
      <div>紧急联系人/与本人关系：</div>
      <div>
        {{ list.contactName || "--" }}
        /
        {{ list.contactRelation || "--" }}
      </div>
    </div>
    <div class="intr">
      <div>联系人电话：</div>
      <div>{{ list.contactPhone || "--" }}</div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs,onMounted ,getCurrentInstance} from "vue";

export default {
  props: ["list"],
  setup() {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
    });
    onMounted(() => {
            _this.chooseCss()
        });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>

.allInfo {
  width: 100%;
  display: flex;
  margin-top: 14px;
  flex-direction: column;
}
.intr {
  width: fit-content;
  margin-right: 35px;
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 24px;
  div:nth-child(1) {
    color: var(--ssintr);
    flex-shrink: 0;
  }
  div:nth-child(2) {
    color: var(--ssallInfo);
  }
}
</style>