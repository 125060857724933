<template>
  <div
    class="navigation"
    :style="
      navradio == 4
        ? 'bottom: 20px;top: unset;left: 50%;transform: translate(-50%, 0px);width:auto;'
        : ''
    "
  >
    <img class="positionImg" src="@/assets/images/a06.png" alt="" />
    <div class="posi" :style="navs.length > 2 ? '' : 'direction: unset;'">
      <span
        v-for="(nav, idx) in navs.length > 2
          ? navs.slice().reverse()
          : navs.slice()"
        :key="idx + nav"
        class="navSpan"
        :style="
          (navs.length > 2 && idx == 0) ||
          (navs.length <= 2 && idx == navs.length - 1)
            ? 'color:#cbede9'
            : ''
        "
        >{{ nav.label }}
        <span v-if="idx != navs.length - 1"
          >{{ navs.length > 2 ? "<" : ">" }}
        </span>
      </span>
    </div>
    <img
      class="returnImg"
      v-if="navs.length > 1"
      @click="returnTo()"
      src="@/assets/images/a07.png"
      alt=""
    />
  </div>
</template>

<script>
// VUE
import { reactive, getCurrentInstance, onMounted, computed } from "vue";
export default {
  components: {},
  name: "Navigation",
  computed: {
    listType() {
      return this.$store.state.index.listType;
    },
    navradio() {
      return this.$store.state.index.navradio;
    },
  },
  data() {
    return {
      returnImg: require("@/assets/images/a07.png"),
    };
  },
  props: {
    navs: {
      default: [
        {
          label: "测试1",
        },
        {
          label: "测试2",
        },
      ],
    },
  },
  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    let state = reactive({}); // 数据

    const returnNav = (val) => {
      context.emit("returnNav", val);
    };
    const returnTo = () => {
      context.emit("returnTo", true);
    };
    const switchListType = () => {
      if (_this.$route.path == "/CockpitFir") {
        _this.$router.push("/DeepGrid");
      }
      if (_this.$route.path == "/DeepGrid") {
        _this.$router.push("/CockpitFir");
      }
    };

    onMounted(() => {});

    return {
      // 变量
      state,

      // 方法
      returnNav,
      switchListType,
      returnTo,
    };
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  position: fixed;
  left: 20px;
  top: 30px;
  height: 40px;
  width: 360px;
  padding: 10px 20px;
  box-sizing: border-box;
  z-index: 25;
  border: 1px solid rgba(166, 255, 239, 1);
  border-image: linear-gradient(0deg, rgba(1, 180, 255, 0.15), rgba(166, 255, 239, 0.075)) 10 10;
  background: linear-gradient(
    90deg,
    rgba(1, 180, 255, 0.15) 0%,
    rgba(166, 255, 239, 0.075) 100%
  );

  // opacity: 0.75;
  // border-radius: 10px;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: rgba(1, 180, 255, 1);
  cursor: pointer;

  // transition: all 0.3;
  .posi {
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    direction: rtl;
    .navSpan:nth-child(1) {
      color: #cbede9;
    }
  }

  .positionImg {
    width: 17px;
    object-fit: contain;
    margin-right: 10px;
  }

  .returnImg {
    width: 20px;
    height: 20px;
    margin-left: 20px;
  }
}
</style>
