<template>
  <el-drawer
    v-model="show"
    :with-header="false"
    direction="btt"
    :append-to-body="true"
    size="100%"
  >
    <div class="allContent divFlex alignCenter flexCenter" v-if="info">
      <div class="box_camera">
        <svg
          @click="closeThis"
          class="box_camera_clear"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 393.664L407.936 353.6a38.4 38.4 0 1 0-54.336 54.336L457.664 512 353.6 616.064a38.4 38.4 0 1 0 54.336 54.336L512 566.336 616.064 670.4a38.4 38.4 0 1 0 54.336-54.336L566.336 512 670.4 407.936a38.4 38.4 0 1 0-54.336-54.336L512 457.664z"
          ></path>
        </svg>
        <div class="topLine"></div>
        <!-- 顶部菜单 -->
        <div class="cameraInfo divFlex alignCenter">
          <div class="imgNameCollect divFlex alignEnd">
            <img src="@/assets/detection/2045.png" alt="" />
            <div class="cameraName">{{ title }}</div>
          </div>
        </div>
        <div class="div10"></div>

        <!-- 预警事件 -->

        <transition name="el-zoom-in-top">
          <div class="box_camera_content_camera bet">
            <div class="warningCenter column flexBet grow">
              <div class="liveBox">
                <img
                  style="width: 100%; height: 100%"
                  v-if="video_Url && info.photoUrl.indexOf(video_Url) != -1"
                  :src="video_Url"
                  alt=""
                />
                <live-player
                  v-else
                  :video-url="video_Url"
                  live="true"
                  autoplay="true"
                  fluent
                  stretch
                  id="play001"
                  aspect="fullscreen"
                ></live-player>
              </div>
              <div class="allImageList" v-if="info.showImages">
                <SwiperImage
                  type="视频图片"
                  perview="8"
                  :images="info.showImages"
                  @setVideoUrl="
                    (url) => {
                      video_Url = url;
                    }
                  "
                />
              </div>
            </div>

            <div class="warningRight column height100">
              <div class="eventName">
                {{ info.createTime }}
                <!-- <span class="statusBtn">{{ info.typeName }}</span> -->
              </div>
              <div class="divFlex flexWrap">
                <div class="eventDesc">
                  <span>识别人员：</span>
                  <span>{{ info.username }}</span>
                </div>
                <div class="eventDesc">
                  <span>人员类型：</span>
                  <span>{{ info.typeName }}</span>
                </div>
                <div class="eventDesc">
                  <span>站点：</span>
                  <span>{{ info.monitorName }}</span>
                </div>
                <div class="eventDesc">
                  <span>网格：</span>
                  <span>{{ info.gridName }}</span>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { SwiperImage } from "@/components";
var player = null;
export default {
  components: {
    SwiperImage,
  },
  props: {
    title: {
      default: "无人机预警详情",
    },
    info: {},
  },
  name: "UvaYsy",
  data() {
    return {
      show: true,
      listIdx: 0,
      video_Url: "",
    };
  },
  watch: {},

  async mounted() {
    setTimeout(() => {
      console.log(this.info);
      if (this.info.videoUrl.length > 0) {
        this.video_Url = this.info.videoUrl[0];
      } else {
        if (this.info.showImages.length > 0) {
          this.video_Url = this.info.showImages[0];
        }
      }
    }, 500);
  },
  methods: {
    closeThis() {
      console.log(11111111);
      this.$emit("showType", false);
    },
    chooseThisItem(item, idx) {
      console.log(item, idx);
      this.listIdx = idx;
      this.video_Url = item.showImages[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.leftArrow {
  position: absolute;
  object-fit: contain;
  left: 332px;
  z-index: 3;
  cursor: pointer;
  transition: all 0.3s;
}

.icon3 {
  width: 24px;
  height: 30px;
  color: var(--oneItem);
  margin-right: 10px;
}

.allContent {
  width: 100%;
  height: 940px;
  padding: 0 40px;
  box-sizing: border-box;
  position: fixed;
  top: 100px;
}

.liveVideo {
  width: 1520px;
  height: 960px;
  background: rgba(7, 28, 49, 1);
  border-radius: 20px;
  transition: all 0.3s;
  box-sizing: border-box;
  padding: 10px;
  position: relative;
  margin-right: 10px;
  box-sizing: border-box;

  .closeBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    width: 20px;
    object-fit: contain;
  }
}

.box_camera {
  width: 1840px;
  height: 940px;
  background: rgba(2, 11, 18, 0.8);
  border: 1px solid rgba(2, 152, 251, 0.64);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  padding: 20px;

  .topLine {
    width: 100%;
    height: 1px;
    background: #0298fb;
    margin-bottom: 18px;
    position: absolute;
    left: 0;
    top: 10px;
  }

  .cameraInfo {
    background: rgba(2, 11, 18, 1);
    z-index: 2;
    width: calc(100% - 40px);
    height: 50px;
    position: absolute;
    border-bottom: 1px solid rgba(2, 152, 251, 0.64);

    .imgNameCollect {
      margin-right: 643px;

      img {
        object-fit: contain;
      }

      .cameraName {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #a6ffef;
        background: linear-gradient(0deg, #a6ffef 0%, #22e2ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        line-height: 24px;
        margin: 0 14px 0 6px;
      }

      .focusPoint {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738ea2;
        cursor: pointer;
      }
    }
  }

  .eventBtn {
    width: 160px;
    height: 50px;
    background: linear-gradient(to Bottom, rgba(9, 45, 92, 0) 0%, #092d5c 100%);
    cursor: pointer;

    img {
      margin-right: 8px;
    }

    span {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(0deg, #8ac7ff 0%, #ffffff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .box_camera_content {
    width: 100%;
    height: 840px;
    background: rgba(2, 11, 18, 0.8);
    border-radius: 3px;
    // overflow: hidden;
    position: relative;
    cursor: pointer;
    margin-top: 50px;

    .rightArrow {
      position: absolute;
      left: 132px;
      z-index: 2;
      bottom: 58px;
      cursor: pointer;
    }
  }
}

.rox_camera {
  width: 100%;
  height: 640px;
  transition: all 0.3s;
  background: rgba(7, 28, 49, 1);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  // position: fixed;
  // left: 0;
  // bottom: 200px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 10px;

  .box_camera_content {
    width: 100%;
    height: 520px;
    background: #000;
    border-radius: 14px;
    // overflow: hidden;
    position: relative;
  }
}

.box_camera_datas {
  margin: 42px auto 0 auto;
  max-width: 1500px;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  // padding-right: 100px;
  .box_camera_name {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #a6ffef;
    background: linear-gradient(0deg, #a6ffef 0%, #22e2ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }

  .box_camera_data {
    font-size: 18px;
    font-weight: 400;
    color: rgba(91, 119, 147, 1);
    line-height: 26px;

    span {
      color: rgba(193, 222, 224, 1);
    }
  }
}

.box_camera_clear {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 9;
  color: rgba(236, 87, 78, 1);
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px rgba(235, 84, 56, 0.5);
  transition: all 0.3s;
}

.box_camera_button {
  width: 120px;
  height: 40px;
  background: linear-gradient(
    to right,
    rgba(34, 226, 255, 1) 0%,
    rgba(166, 255, 239, 1) 100%
  );
  // box-shadow: 0px 5px 15px 0px rgba(1, 180, 255, 0.25),
  //   0px 10px 50px 0px rgba(1, 180, 255, 0.25);
  border-radius: 20px;
  margin-bottom: -5px;
  -webkit-text-stroke: 0.5px rgba(1, 180, 255, 0.25);
  font-size: 24px;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  color: #071c31;

  cursor: pointer;
}

#videContainer {
  width: 100%;
  height: 100%;
}

// -------------------------云闪播

.closeBtn {
  z-index: 2;
  width: 30px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 5;
  cursor: pointer;
}

.towBox {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;

  .oneItem {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.addressInfo {
  margin-top: 20px;
  margin-left: 4px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;

  .addressName {
    color: var(--ssaddress);
  }

  .address {
    color: var(--ssprogress2);
  }
}

.infoDetailBox {
  width: 100%;
  height: auto;
  // background: var(--specialBack);
  border-radius: 5px;
  padding: 0 10px 20px 10px;
  box-sizing: border-box;
}

.intr {
  width: fit-content;
  padding: 0 10px;
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 30px;

  div:nth-child(1) {
    color: var(--ssintr);
    flex-shrink: 0;
  }

  div:nth-child(2) {
    color: var(--ssallInfo);
  }
}

.familyDetail {
  border: 1px solid;
  // border-image: linear-gradient(
  //     0deg,
  //     var(--servicesPin-button3),
  //     var(--infolist2)
  //   )
  //   10 10;
  background: linear-gradient(
    90deg,
    var(--servicesPin-button3) 0%,
    var(--infolist2) 100%
  );
  border-radius: 5px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: var(--oneItem);
  position: absolute;
  right: 18px;
  top: 108px;
  z-index: 38;
  padding: 0 4px;
  box-sizing: border-box;
  cursor: pointer;
}

.timeBox {
  width: fit-content;
  display: flex;
  align-items: center;
  height: 20px;
  background: var(--servicesPin2);
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: var(--servicesPin3);
  line-height: 20px;
  margin-bottom: 15px;

  span {
    margin-left: 10px;
  }
}

.timeBox::before {
  content: "";
  display: block;
  position: absolute;
  width: 5px;
  height: 20px;
  background: var(--servicesPin3);
  border-radius: 0px 4px 4px 0px;
}

.overview_card {
  width: 146px;
  height: 130px;
  background: rgba(114, 113, 102, 0.1);
  border: 1px solid rgba(89, 255, 162, 0.2);
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 6px;
  margin-right: 3px;
  cursor: pointer;

  .overview_card_images {
    height: 90px;
    overflow: hidden;
    position: relative;

    > img {
      object-fit: cover;
      height: 90px;
      width: 100%;
    }

    .video_button {
      position: absolute;
      left: calc(50% - 25px);
      top: calc(50% - 25px);
      width: 50px;
      height: 50px;
      z-index: 3;
    }

    .divRadius {
      border: 10px solid #555555;
      border-left: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top-right-radius: 4px;
      content: "";
      position: relative;
      width: 0;
    }

    .timeBox1 {
      height: 18px;
      background: rgba(0, 0, 0, 0.75);

      border-radius: 3px;
      padding: 2px 7px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: var(--servicesPin-typeBtn1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: 6px;
      bottom: 6px;

      .text {
        margin-left: 4px;
      }
    }
  }

  .overview_card_text {
    height: 20px;
    line-height: 25px;
    box-sizing: border-box;
    padding-left: 7px;
    position: relative;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--ssallInfo);
  }

  .overview_card_text1 {
    height: 20px;
    line-height: 20px;
    box-sizing: border-box;
    padding-left: 7px;
    position: relative;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--ssintr);
  }
}

.overview_card:nth-child(even) {
  margin-right: 0;
}

.allDesc {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .dataDesc {
    display: flex;
    align-items: flex-end;
    width: 50%;

    .nameTitle {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #72c583;
    }

    .numBox {
      width: 80px;
      height: 24px;
      margin-left: 10px;
      background: linear-gradient(
        90deg,
        rgba(114, 113, 102, 0.3) 0%,
        rgba(114, 113, 102, 0) 100%
      );
      display: flex;
      align-items: flex-end;

      .numSpan {
        font-size: 30px;
        font-family: Square722;
        font-weight: bold;
        font-style: italic;
        color: var(--servicesPin3);
        line-height: 26px;
      }

      .unitSpan {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #aa947c;
        line-height: 12px;
        margin: 0 0 2px 4px;
      }
    }
  }
}

.allView {
  // width: 34px;
  padding: 2px 5px;
  box-sizing: border-box;
  // height: 18px;
  background: var(--servicesPin3);
  border-radius: 0px 3px 0px 3px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #1c2025;
  z-index: 2;
}

.yunText {
  position: relative;
}

.yunText:nth-child(even) {
  margin-right: 4px;
}

.date-picker {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: 26px;
  background: var(--date-picker);
  border: 1px solid var(--date-picker1);
  border-radius: 3px;
  flex-shrink: 0;

  .date-picker-label {
    margin-left: 5px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--date-picker-label);
    line-height: 24px;
    white-space: nowrap;
  }
}

.count {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .allCount {
    display: flex;
    align-items: flex-end;

    > span {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: var(--date-picker-label);
      white-space: nowrap;
    }

    .numberBox {
      width: 80px;
      height: 18px;
      margin-left: 5px;
      display: flex;
      align-items: flex-end;
      background: linear-gradient(
        to right,
        var(--videoList) 0%,
        var(--levelNames) 100%
      );

      .num {
        font-size: 24px;
        font-family: Square722;
        font-weight: bold;
        font-style: italic;
        color: var(--servicesPin3);
        margin-bottom: -4px;
      }

      .unit {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: var(--ssallInfo);
        margin-left: 2px;
      }
    }
  }
}

.shanbo_right {
  width: 380px;
  height: 940px;
  // border: 1px solid rgba(0, 198, 255, 0.3);
  // background: rgba(7, 28, 49, 0.6);
  // box-shadow: 0px 10px 40px 0px rgba(51, 102, 153, 0.5);
  padding: 10px 15px;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  position: relative;
  flex-direction: column;

  .shanbo {
    height: 200px;
    width: 100%;
    background: linear-gradient(to top, black 0%, transparent 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 5;

    .box_camera_button {
      width: 120px;
      height: 40px;
      background: linear-gradient(
        to right,
        rgba(34, 226, 255, 1) 0%,
        rgba(166, 255, 239, 1) 100%
      );
      border-radius: 20px;
      margin-bottom: -5px;
      -webkit-text-stroke: 0.5px rgba(1, 180, 255, 0.25);
      font-size: 24px;
      font-family: YouSheBiaoTiHei;
      font-weight: 400;
      color: #071c31;
      cursor: pointer;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0px);
      bottom: 3px;
    }
  }
}

.box_camera_content_camera {
  width: 100%;
  height: 810px;
  background: rgba(2, 11, 18, 0.8);
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  margin-top: 80px;

  .detectionLiveBox {
    width: 1400px;
    height: 810px;
    background: #020b12;
    border: 1px solid #092d5c;
    padding: 21px;
    box-sizing: border-box;

    .live_box {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }

  .cutLine {
    width: 1px;
    height: 100%;
    background: rgba(2, 152, 251, 0.8);
  }

  .cameraRecordList {
    height: 810px;

    .camerabtns {
      width: 151px;
      height: 40px;
      border: 1px solid #092d5c;
      background: linear-gradient(
        to bottom,
        rgba(9, 45, 92, 0) 0%,
        #092d5c 100%
      );

      span {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 48px;

        background: linear-gradient(0deg, #8ac7ff 0%, #ffffff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .shanboCountBox {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #7d98b3;
      line-height: 14px;

      .shanboCount {
        width: 80px;
        height: 24px;
        background: linear-gradient(
          to right,
          rgba(1, 198, 255, 0.3) 100%,
          rgba(1, 198, 255, 0) 0%
        );
        margin-left: 5px;

        .num {
          font-size: 30px;
          font-family: Square722;
          font-weight: bold;
          // font-style: italic;
          color: #01c6ff;
          line-height: 24px;
          background: linear-gradient(0deg, #a6ffef 0%, #22e2ff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .unit {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #e1f2e0;
          margin-left: 5px;
        }
      }
    }

    .listItem {
      width: 300px;
      // background: linear-gradient(to bottom, rgba(9, 45, 92, 0) 0%, rgba(9, 45, 92, 0.5) 100%);

      // border-bottom: 1px solid rgba(2, 152, 251, 0.8);
      .listItemVideo {
        background: linear-gradient(
          to bottom,
          rgba(9, 45, 92, 0) 0%,
          rgba(9, 45, 92, 0.5) 100%
        );
        border-bottom: 1px solid rgba(2, 152, 251, 0.8);
        margin-bottom: 10px;
        padding: 10px;
        box-sizing: border-box;
      }

      .timeLine {
        width: fit-content;
        padding: 0 7px;
        line-height: 16px;
        height: 16px;
        background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
        border-radius: 8px;
        font-size: 12px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
        margin: 3px 0 10px 0;
      }

      .videoImgBox {
        width: 120px;
        height: 90px;
        position: relative;
        border-radius: 5px;
        margin-right: 20px;

        .avatar {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .playBtn {
          width: 36px;
          height: 36px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }
      }

      .itemName {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #cde4f8;
      }

      .timeText {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738ea2;
        margin: 12px 0;
      }

      .download {
        margin-right: 16px;
      }
    }
  }

  .warningList {
    width: 347px;
    height: 100%;
    border-right: 1px solid rgba(2, 152, 251, 0.8);
    padding-right: 20px;
    box-sizing: border-box;

    .backColor {
      background: linear-gradient(
        to bottom,
        rgba(9, 45, 92, 0) 0%,
        rgba(9, 45, 92, 0.5) 100%
      );
      border-bottom: 1px solid rgba(2, 152, 251, 0.8);
    }

    .selectRow {
      padding: 0 10px;
      box-sizing: border-box;
    }

    .listItem {
      // width: 275px;
      padding: 10px 10px 0 10px;
      box-sizing: border-box;
      // margin-bottom: 10px;

      .timeLine {
        width: fit-content;
        padding: 0 7px;
        line-height: 16px;
        height: 16px;
        background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
        border-radius: 8px;
        font-size: 12px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
      }

      .statusText {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #eb5438;
        margin-left: 13px;
      }

      .处理中 {
        background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
      }

      .待处理 {
        background: linear-gradient(
          90deg,
          rgba(235, 84, 56, 1) 0%,
          #12162a 100%
        );
      }

      .已转报 {
        background: linear-gradient(
          90deg,
          rgba(255, 205, 6, 1) 0%,
          #12162a 100%
        );
      }

      .已办结 {
        background: linear-gradient(
          90deg,
          rgba(15, 243, 238, 1) 0%,
          #12162a 100%
        );
      }

      .进入 {
        background: linear-gradient(
          90deg,
          rgba(255, 138, 74, 1) 0%,
          rgba(18, 22, 42, 1) 100%
        );
      }

      .离开 {
        background: linear-gradient(
          90deg,
          rgba(2, 152, 251, 1) 0%,
          rgba(18, 22, 42, 1) 100%
        );
      }

      .carTime {
        font-size: 12px;
        font-family: DINPro;
        font-weight: 500;
        color: #ffffff;
      }

      .eventName {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #cde4f8;
        margin: 14px 0 10px 0;
      }

      .eventDesc {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738ea2;
        line-height: 18px;
        margin-right: 30px;

        span:nth-child(1) {
          color: #738ea2;
        }

        span:nth-child(2) {
          color: rgba(161, 184, 205, 1);
        }
        .未确定 {
          color: rgba(255, 138, 74, 1) !important;
        }

        .无效预警 {
          color: rgba(235, 84, 56, 1) !important;
        }

        .有效预警 {
          color: rgba(0, 191, 127, 1) !important;
        }

        .无车牌 {
          color: rgba(169, 180, 200, 1) !important;
        }
      }
    }
  }

  .carList {
    .listItem {
      // width: 275px;
      padding: 10px 10px 0 10px;
      box-sizing: border-box;
      // margin-bottom: 10px;

      .timeLine {
        width: fit-content;
        padding: 0 7px;
        line-height: 16px;
        height: 16px;
        background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
        border-radius: 8px;
        font-size: 12px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
      }

      .statusText {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #eb5438;
        margin-left: 13px;
      }

      .处理中 {
        background: linear-gradient(90deg, #0298fb 0%, #12162a 100%);
      }

      .待处理 {
        background: linear-gradient(
          90deg,
          rgba(235, 84, 56, 1) 0%,
          #12162a 100%
        );
      }

      .已转报 {
        background: linear-gradient(
          90deg,
          rgba(255, 205, 6, 1) 0%,
          #12162a 100%
        );
      }

      .已办结 {
        background: linear-gradient(
          90deg,
          rgba(15, 243, 238, 1) 0%,
          #12162a 100%
        );
      }

      .进入 {
        background: linear-gradient(
          90deg,
          rgba(255, 138, 74, 1) 0%,
          rgba(18, 22, 42, 1) 100%
        );
      }

      .离开 {
        background: linear-gradient(
          90deg,
          rgba(2, 152, 251, 1) 0%,
          rgba(18, 22, 42, 1) 100%
        );
      }

      .carTime {
        font-size: 12px;
        font-family: DINPro;
        font-weight: 500;
        color: #ffffff;
        margin-left: 10px;
      }

      .eventName {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #cde4f8;
        margin: 14px 0 10px 0;
      }

      .toSvg {
        width: 12px;
        height: 9px;
        color: rgba(255, 255, 255, 0.5);
        position: absolute;
        right: 16px;
        top: 50%;
        z-index: 1;
        transform: translate(0, -50%);
      }

      .carImg {
        width: 60px;
        height: 60px;
        margin-right: 8px;
      }

      .eventDesc {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738ea2;
        line-height: 18px;
        margin-right: 30px;

        span:nth-child(1) {
          color: #738ea2;
        }

        span:nth-child(2) {
          color: rgba(161, 184, 205, 1);
        }
      }
    }
  }

  .carNumBox {
    position: relative;
    margin-top: 12px;
    .carNum {
      padding: 2px;
      height: 30px;
      background: #ffcd06;
      border-radius: 3px;
      box-sizing: border-box;
      width: fit-content;
      .carNumBefore {
        height: 26px;
        border: 1px solid #020b12;
        border-radius: 2px;
        font-size: 16px;
        padding: 6px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        box-sizing: border-box;
        color: #020b12;
        white-space: nowrap;
        img {
          width: 20px;
          height: 16px;
          margin-left: 15px;
        }
      }
    }

    .phone {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #a1b8cd;
      margin-left: 9px;
    }

    .未确定 {
      background: #ffcd06 !important;
    }

    .无效预警 {
      background: rgba(235, 84, 56, 1) !important;
    }

    .有效预警 {
      background: rgba(0, 191, 127, 1) !important;
    }

    .无车牌 {
      background: rgba(169, 180, 200, 1) !important;
    }

    .enter_out {
      margin-left: 10px;
      span:nth-child(1) {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #738ea2;
        line-height: 20px;
      }

      span:nth-child(2) {
        font-size: 24px;
        font-family: Square722;
        font-weight: 400;
        color: rgba(255, 138, 74, 1);
        line-height: 24px;
        font-style: italic;
      }
    }
  }

  .recordText {
    // height: 13px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #cde4f8;
    width: 100%;
    padding-left: 8px;
    box-sizing: border-box;
    position: relative;
  }

  .recordText::before {
    content: "";
    width: 4px;
    height: 14px;
    background: #0298fb;
    border-radius: 2px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0px, -50%);
  }

  .warningCenter {
    width: 1140px;
    height: 100%;
    margin: 0 20px 0 10px;

    .liveBox {
      width: 100%;
      height: 680px;
      background: #020b12;
      border: 1px solid #092d5c;
      position: relative;
    }

    .allImageList {
      width: 100%;
      height: 120px;
      position: relative;
    }
  }

  .warningRight {
    width: 280px;
    height: 100%;

    .backColor {
      background: linear-gradient(
        to bottom,
        rgba(9, 45, 92, 0) 0%,
        rgba(9, 45, 92, 0.5) 100%
      );
      border-bottom: 1px solid rgba(2, 152, 251, 0.8);
    }

    .eventName {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
      line-height: 24px;
      margin-bottom: 20px;

      .statusBtn {
        width: 46px;
        height: 18px;
        background: #0ff3ee;
        border-radius: 3px;
        line-height: 18px;
        text-align: center;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #020b12;
        margin-bottom: 2px;
        margin-left: 4px;
      }

      .已办结 {
        background: #0ff3ee;
        color: #020b12;
      }
      .待处理 {
        background: rgba(255, 69, 55, 0.2);
        color: rgba(235, 84, 56, 1);
      }
      .处理中 {
        background: rgba(253, 226, 37, 0.2);
        color: rgba(253, 226, 37, 1);
      }
    }

    .timeText {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #0ff3ee;

      .status {
        width: fit-content;
        height: 16px;
        background: rgba(255, 69, 55, 0.2);
        border-radius: 3px;
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #eb5438;
        line-height: 16px;
        text-align: center;
        margin-left: 10px;
      }

      .待处理 {
        background: rgba(255, 69, 55, 0.2);
        color: #eb5438;
      }

      .已转报 {
        background: rgba(253, 226, 37, 0.2);
        color: rgba(253, 226, 37, 1);
      }

      .处理中 {
        background: rgba(2, 152, 251, 0.2);
        color: rgba(2, 152, 251, 1);
      }

      .已办结 {
        background: rgba(15, 243, 238, 0.2);
        color: rgba(15, 243, 238, 1);
      }
    }

    .eventDesc {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #738ea2;
      line-height: 18px;
      margin-right: 30px;

      span:nth-child(1) {
        color: #738ea2;
      }

      span:nth-child(2) {
        color: rgba(161, 184, 205, 1);
      }

      .未确定 {
        color: rgba(255, 138, 74, 1) !important;
      }

      .无效预警 {
        color: rgba(235, 84, 56, 1) !important;
      }

      .有效预警 {
        color: rgba(0, 191, 127, 1) !important;
      }

      .无车牌 {
        color: rgba(169, 180, 200, 1) !important;
      }
    }
  }
}

.gapDiv {
  width: 110px;
  height: 100%;
  border-right: 1px solid rgba(2, 152, 251, 0.8);
}

.openLeft {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #2062a5;
  line-height: 46px;

  background: linear-gradient(to right, #0bceff 0%, #9be4ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 10px;
}

.openLeft:hover {
  opacity: 0.8;
}
</style>
