<template>
  <div class="areaAndList grow">
    <!-- <el-scrollbar style="max-height: 360px; height: auto; flex-shrink: 0">
        <div class="areaList grow" v-if="areaList.length > 0">
          <div class="areaName" v-for="(name, index) in areaList" :key="index + 'dd'" :style="
            area == name.id
              ? party
                ? 'color:rgba(255, 230, 21, 1)'
                : 'color:rgba(0, 197, 255, 1)'
              : 'color:#E9FBFE'
          " @click="getArea(name.id)">
            {{ name.showname || name.name }}
          </div>
        </div>
      </el-scrollbar> -->

    <transition name="el-fade-in-linear">
      <el-scrollbar class="peopleList grow" v-if="state.isMounted">
        <div v-infinite-scroll="getList" :infinite-scroll-immediate="false">
          <div
            :class="
              item.address == state.nowHouseAdd
                ? 'onePeopleItem choose111'
                : 'onePeopleItem'
            "
            @click="getInfo(item)"
            v-for="(item, index) in unique(array.length > 0 ? array : [])"
            :style="
              item.address == state.nowHouseAdd &&
              id &&
              !state.pathArr.includes(model)
                ? `height: 70px;left:10px;width:calc(100% - 10px);`
                : `height: auto;`
            "
            :key="index + 'ddd'"
          >
            <!-- 底图 -->
            <img
              class="backImg"
              :data-id="item.id"
              :id-id="id"
              :item-id="item.gridId"
              :src="
                id === item.id || id == item.gridId
                  ? require('../assets/images/lv1.png')
                  : require('../assets/images/hei.png')
              "
            />

            <!-- 左边的线        v-if="item.address == state.nowHouseAdd" -->
            <div
              class="colorLine"
              v-if="
                item.address == state.nowHouseAdd &&
                id &&
                !state.pathArr.includes(model)
              "
            ></div>
            <!-- <信息框部分> -->
            <!-- 信息 信息格子-->
            <div class="photoAndInfo" v-if="state.modelArr.includes(model)">
              <el-image class="photo" :src="item.avatar" fit="cover">
                <!-- <img src="@/assets/images/d6.png" alt=""> -->
              </el-image>
              <div class="peopleInfo">
                <div class="nameAndSexAndAge">
                  <div class="name">
                    {{ item.name }}
                  </div>
                  <div
                    class="sexAndAge"
                    v-if="item.age || item.gender || item.sex"
                  >
                    <span v-if="item.gender || item.sex">{{
                      item.gender || item.sex
                    }}</span>
                    <span v-if="item.age && (item.gender || item.sex)">
                      /
                    </span>
                    <span v-if="item.age">{{ item.age }}岁</span>
                  </div>
                </div>
                <div class="address">
                  {{ item.address || item.address || item.domiciles }}
                </div>
              </div>
            </div>
            <div class="photoAndInfo" v-if="model == '房屋列表'">
              <el-image
                class="photo"
                style="width: 70px"
                v-if="item.icon"
                :src="item.icon"
                fit="cover"
              ></el-image>
              <div class="houseInfo">
                <div class="name">{{ item.houseName || "--" }}</div>
                <div class="address" :title="item.address">
                  {{ item.address || item.domiciles }}
                </div>
                <!-- <div class="peopleNumAndLink">
                  <div class="btn">{{ item.type == 1 ? '楼栋' : '民房' }}</div>
                  <div class="btn">{{ item.hsCount }}户</div>
                  <div class="btn">{{ item.rkCount }}人</div>
                </div> -->
              </div>
            </div>
            <div class="photoAndInfo" v-if="model == '主体列表'">
              <el-image
                class="photo"
                style="width: 70px"
                :src="item.photo"
                fit="cover"
              ></el-image>
              <div class="houseInfo">
                <div class="name">{{ item.simpleName || "--" }}</div>
                <div class="peopleNumAndLink">
                  <div class="btn1" v-if="item.nature">
                    {{ item.nature || "--" }}
                  </div>
                  <div class="btn2" v-if="item.type">
                    {{ item.type || "--" }}
                  </div>
                </div>
              </div>
            </div>
            <div class="photoAndInfo" v-if="model == '改造列表'">
              <div class="peopleInfo">
                <div class="nameAndSexAndAge">
                  <div class="name">
                    {{ item.name }}
                  </div>
                </div>
                <div class="allInfo column">
                  <div class="intr">
                    <div>责任人/电话：</div>
                    <div>责任人/1515555555</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="photoAndInfo" v-if="model == '改造项目'">
              <div class="peopleInfo">
                <div class="nameAndSexAndAge">
                  <div class="name">
                    {{ item.name }}
                  </div>
                </div>
                <div class="div10"></div>
                <div class="allInfo flexWrap">
                  <div
                    class="intr"
                    style="line-height: 21px"
                    v-for="(desc, idx) in item.desc"
                    :key="idx + 'fdshkldssfd'"
                  >
                    <div>{{ idx }}：</div>
                    <div>{{ desc }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="photoAndInfo" v-if="model == '核酸监测点'">
              <div class="peopleInfo">
                <div class="nameAndSexAndAge">
                  <div class="name">
                    {{ item.gridName || "--" }}
                  </div>
                </div>
                <div class="allInfo">
                  <div class="intr">
                    <div>所属：</div>
                    <div>{{ item.jurisdiction || "--" }}</div>
                  </div>
                  <div class="intr">
                    <div>人口：</div>
                    <div>{{ item.rkCount || "--" }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="photoAndInfo" v-if="model == '红色阵地'">
              <div class="houseInfo">
                <div class="name houseInfoColor">
                  {{ item.name }}
                </div>
                <div class="addressAndBtn">
                  <div class="peopleCountyoo address">
                    <span>{{ item.adress }}</span>
                  </div>
                  <div class="openBtn">
                    <div class="btn houseInfoColor houseInfoColor1">
                      {{ item.type || "--" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="photoAndInfo" v-if="model == '部件' || model == '场所'">
              <div class="houseInfo">
                <div class="name photoAndInfohouseInfo">
                  {{ item.partName }}
                </div>
                <div class="addressAndBtn">
                  <div class="address peopleCountyoo">
                    <span>{{ item.detailedAddress }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="photoAndInfo" v-if="model == '老人体育队伍'">
              <div class="houseInfo">
                <div class="name photoAndInfohouseInfo">
                  {{ item.teamName || "--" }}
                </div>
                <div class="addressAndBtn">
                  <div class="address peopleCountyoo">
                    <span
                      >领队/联系电话：{{ item.leaderName || "--" }} /
                      {{ item.leaderContact || "--" }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="photoAndInfo column" v-if="model == '海上社区'">
              <div class="seaTop divFlex flexBet">
                <div class="name">{{ item.图斑编号 }}</div>
                <div class="divFlex">
                  <div class="disBtn">
                    {{ item.养殖类型 }}
                  </div>

                  <div class="acBtn">
                    {{ item.养殖品种 }}
                  </div>
                </div>
              </div>
              <div class="divFlex flexWrap">
                <div class="seaBot">
                  面积(公顷)：
                  <span> {{ Number(item.养殖面积).toFixed(2) || "--" }}</span>
                </div>
                <div class="seaBot">
                  网箱数： <span> {{ item.养殖数量 || "--" }}</span>
                </div>
              </div>
            </div>
            <!-- <标签部分> -->

            <!-- 深度网格标签 -->
            <!-- <div class="peopleNumAndLink" v-if="!model"> -->
            <swiper
              class="peopleNumAndLink"
              v-if="!model"
              :direction="'vertical'"
              :slides-per-view="2.3"
              :space-between="3"
            >
              <swiper-slide
                v-for="(val, idx) in item.hostRelation
                  ? [item.hostRelation]
                  : item.type"
                :key="idx + 'fas'"
              >
                <div :class="party ? 'btnParty' : 'btn'">
                  {{ val }}
                </div>
              </swiper-slide>
            </swiper>
            <!-- </div> -->
            <!-- <div class="peopleNumAndLink" v-if="model == '重点服务对象'">
              <div class="btn epidemicBtn444 ssallInfo" v-for="( val, idx ) in  item.type " :key="idx + 'fgas'"
                style="line-height: 14px;">
                {{ val }}
              </div>
            </div> -->

            <swiper
              class="peopleNumAndLink"
              v-if="model == '重点服务对象'"
              :direction="'vertical'"
              :slides-per-view="2.3"
              :space-between="3"
            >
              <swiper-slide v-for="(val, idx) in item.type" :key="idx + 'fgas'">
                <div
                  class="btn epidemicBtn444 ssallInfo"
                  style="line-height: 14px"
                >
                  {{ val }}
                </div>
              </swiper-slide>
            </swiper>

            <!-- <div class="peopleNumAndLink" v-if="model == '养老地图'">
              <div :class="'btn' + val.slice(0, 4)" v-for="( val, idx ) in  item.type " :key="idx + 'fgddddas'">
                {{ val.slice(0, 4) }}
              </div>
            </div> -->

            <swiper
              class="peopleNumAndLink"
              v-if="model == '养老地图'"
              :direction="'vertical'"
              :slides-per-view="2.3"
              :space-between="3"
            >
              <swiper-slide
                v-for="(val, idx) in item.type"
                :key="idx + 'fgddddas'"
              >
                <div :class="'btn' + val.slice(0, 4)">
                  {{ val }}
                </div>
              </swiper-slide>
            </swiper>

            <!-- <div class="peopleNumAndLink" style="position: absolute;right:15px;;height:20px;" v-if="model == '改造项目'">
              <div class="btn">
                {{ item.label }}
              </div>
            </div> -->

            <swiper
              class="peopleNumAndLink"
              style="position: absolute; right: 15px; height: 20px"
              v-if="model == '改造项目'"
              :direction="'vertical'"
              :slides-per-view="2.3"
              :space-between="3"
            >
              <swiper-slide
                v-for="(val, idx) in item.label"
                :key="idx + 'hghgu'"
              >
                <div class="btn">
                  {{ val }}
                </div>
              </swiper-slide>
            </swiper>

            <!-- <div class="peopleNumAndLink" v-if="model == '重点人员'">
              <div class="btn ssallInfo ssbtn5" v-for="( val, idx ) in  item.type " :key="idx + 'fgddas'">
                {{ val }}
              </div>
            </div> -->
            <div class="column alignEnd" v-if="model == '重点人员'">
              <div class="blueNum divFlex alignCenter" v-if="item.faceAlgorithmCount">
                <img src="@/assets/dc/b.png" alt="">
                <span>{{item.faceAlgorithmCount}}</span>
              </div>
              <swiper
                style="flex-shrink: 0; height: 100%;flex-grow:1"
                class="peopleNumAndLink"
                :direction="'vertical'"
                :slides-per-view="2.3"
                :space-between="3"
              >
                <swiper-slide
                  v-for="(val, idx) in item.type"
                  :key="idx + 'fgddas'"
                >
                  <!-- <div v-if="typeof(val) =='number'" class="btn ssallInfo ssbtn5">
                  {{ val }}
                </div> -->

                  <div class="btn ssallInfo ssbtn5">
                    {{ val }}
                  </div>
                </swiper-slide>
              </swiper>
            </div>

            <div class="epidemicBtn" v-if="model == '人员管控'">
              <div
                v-for="(x, idx) in [item.gkcs, item.gkzt]"
                :key="idx + 'dsadsa'"
                :class="[
                  'btn' +
                    (x == '集中医学观察'
                      ? '1'
                      : x == '居家医学观察'
                      ? '2'
                      : x == '居家健康监测'
                      ? '3'
                      : x == '健康监测'
                      ? '4'
                      : x == '自我健康监测'
                      ? '5'
                      : x == '管控中'
                      ? '6'
                      : x == '解除管控'
                      ? '7'
                      : ''),
                ]"
              >
                {{ x }}
              </div>
            </div>
            <div class="preventBtn" v-if="model == '疫苗接种'">
              <div
                v-for="(x, idx) in [item.frequency]"
                :key="idx + 'dsadsa'"
                :class="[
                  'btn' +
                    (x == '未接种'
                      ? '1'
                      : x == '一剂'
                      ? '2'
                      : x == '二剂'
                      ? '3'
                      : x == '加强剂'
                      ? '4'
                      : ''),
                ]"
              >
                {{ x }}
              </div>
            </div>
            <div class="zhongdianBtn" v-if="model == '部件' || model == '场所'">
              <div :class="model == '部件' ? 'btn' : 'btn1'">
                {{ item.partType }}
              </div>
            </div>
            <div class="peopleNumAndLink" v-if="model == '老人体育队伍'">
              <div class="btn peopleNumAndLinkBtn">
                {{ item.number || "--" }}人
              </div>
            </div>
          </div>
          <div class="daodi" v-if="array.length === 0">- 暂无数据 -</div>
          <div class="daodi" v-else-if="page > last_page">- 到底了 -</div>
        </div>
      </el-scrollbar>
    </transition>
  </div>
</template>

<script>
import SwiperCore, { Autoplay, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Autoplay, Pagination, Scrollbar, A11y]);
// VUE
import {
  h,
  ref,
  reactive,
  watch,
  computed,
  getCurrentInstance,
  onMounted,
} from "vue";

export default {
  props: {
    // 模块
    model: {
      type: String,
      default: "",
    },
    // 列表
    array: {
      type: Array,
      default: [],
    },
    // 小区数组
    areaList: {
      type: Array,
      default: ["全部"],
    },
    // 选中小区
    area: {
      type: String,
      default: 1,
    },
    // 页码
    page: {
      type: Number,
      default: 1,
    },
    // 最大页码
    last_page: {
      default: 10,
    },
    // 选中ID
    id: {
      type: Number,
      default: false,
    },
    // 选中类型
    type: {
      type: String,
      default: "人员",
    },
    party: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props, context) {
    const _this = getCurrentInstance().proxy;
    let state = reactive({
      nowHouseAdd: false,
      modelArr: [
        "人员管控",
        "疫苗接种",
        "",
        "重点服务对象",
        "重点人员",
        "养老地图",
      ],
      pathArr: [
        "红色阵地",
        "房屋列表",
        "核酸监测点",
        "主体列表",
        "部件",
        "场所",
        "老人体育队伍",
        "志愿者服务队伍",
        "改造项目",
        "海上社区",
      ],
      isMounted: false,
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 30,
        autoplay: {
          delay: 1000,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
        value2: false,
      },
    }); // 数据

    const path = computed(() => {
      return _this.$route.path;
    });
    const getList = () => {
      context.emit("getList");
    };
    const getArea = (val) => {
      context.emit("getArea", val);
    };
    const otherInfo = (val) => {
      context.emit("otherInfo", val);
    };
    const unique = (arr) => {
      if (arr.length == 0) return arr;
      const res = new Map();
      return arr.filter(
        (arr) =>
          !res.has(arr.id ? arr.id : arr.gridId) &&
          res.set(arr.id ? arr.id : arr.gridId, 1)
      );
    };
    // console.log(unique(props.array.length > 0 ? props.array : []) )
    const getInfo = (val) => {
      if (state.nowHouseAdd == val.address && val.id == props.id) {
        state.nowHouseAdd = false;
      } else {
        state.nowHouseAdd = val.address;
        setTimeout(() => {
          $(".colorLine").eq(0).css({ "border-radius": "2px 2px 0 0" });
          $(".colorLine")
            .eq($(".colorLine").length - 1)
            .css({ "border-radius": "0px 0px 2px 2px", height: "70px" });
        }, 0);
      }
      context.emit("getInfo", val);
    };
    watch(
      () => props.id,
      (val) => {
        if (!val) return;
        _this.$nextTick(() => {
          state.nowHouseAdd = false;
          setTimeout(() => {
            let obj = props.array.find((e) => {
              return e.id == val;
            });
            if (!state.pathArr.includes(props.model) && obj) {
              state.nowHouseAdd = obj.address;
            }
          }, 200);
        });
      },
      { deep: true, immediate: true }
    );
    watch(
      () => props.array,
      (val, oldVal) => {
        if (oldVal && oldVal.length > 0) return;
        state.isMounted = false;
        _this.$nextTick(() => {
          setTimeout(() => {
            state.isMounted = true;
          }, 300);
        });
      },
      { deep: true, immediate: true }
    );
    onMounted(() => {
      _this.chooseCss();
      _this.$nextTick(() => {
        state.isMounted = true;
      });
    });
    return {
      path,
      state,
      getList,
      getInfo,
      getArea,
      otherInfo,
      unique,
    };
  },
};
</script>

<style lang="scss" scoped>
.peopleNumAndLinkBtn {
  background: var(--ssbtn4) !important;
}

.oldBtn {
  // width: 60px;
  height: 16px;
  background: var(--oldManBtn2);
  border-radius: 5px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: var(--oldManBtn3);
  line-height: 15px;
  text-align: center;
  padding: 0 3px;
  box-sizing: border-box;
}

.bring {
  height: 16px;
  background: var(--epidemicBtn8);
  border-radius: 5px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: var(--bring);
  line-height: 12px;
  text-align: center;
  padding: 1px 4px;
  box-sizing: border-box;
}

.evCheck {
  display: flex;
  margin-top: 7px;

  img {
    width: 13px;
    height: 16px;
    margin-right: 10px;
  }
}

.wisdomCheck {
  height: 70%;
  /* margin-left: 10px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  /* flex-shrink: 0; */
  position: absolute;
  right: 10px;
  top: 15px;

  .wisdomType {
    width: 80px;
    height: 18px;
    background: var(--wisdomType);
    border-radius: 3px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--wisdomType1);
    line-height: 18px;
    text-align: center;
  }

  .wisdomIcon {
    display: flex;

    img {
      margin-left: 6px;
      // width: 16px;
      // height: 16px;
    }
  }
}

.wrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.checkBtn {
  width: 50px;
  height: 18px;
  background: var(--checkBtn);
  border-radius: 3px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: var(--checkBtn1);
  line-height: 21px;
  text-align: center;
}

.checkBtn1 {
  width: 50px;
  height: 18px;
  background: var(--checkBtn2);
  border-radius: 3px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: var(--checkBtn3);
  line-height: 21px;
  text-align: center;
}
</style>
