<template>
  <div class="searchInput" @click="focusing()">
    <div class="searchText" v-if="!flag && searchValue == ''">
      <span>{{ text }}...</span>
      <i class="el-icon-search"></i>
    </div>
    <input style="padding: 0 20px; text-align: center" v-if="flag || searchValue != ''" id="search_input"
      autofocus="autofocus" @blur="flag = false" type="text" v-model="searchValue" />
    <div v-if="flag || searchValue != ''" @click="clearValue()" class="clearText">
      清除
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchInput",
  data() {
    return {
      flag: false,
      searchValue:""
    };
  },
  // setup(props, ctx) {
  //   const valueInput = (data) => {
  //     ctx.emit("valueInput", data);
  //   };
  //   return { valueInput };
  // },
  props: {
    text: String,
    value: String,
  },
  computed: {

  },
  watch: {
    searchValue(val) {
      console.log(val)
      this.valueInput(val);
    },
    value: {
      handler(val, oldVal) {
        this.searchValue = val;
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    valueInput(data){
      // console.log(data)
      this.$emit("valueInput", data);
    },
    clearValue() {
      this.valueInput("");
    },
    focusing() {
      this.flag = true;
      this.$nextTick(() => {
        document.getElementById("search_input").focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  border-radius: 40px;
  flex-shrink: 0;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  margin-top: 14px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  background: var(--searchInput);
  border: 1px solid var(--ssaddress);
  border-radius: 10px;

  .searchText {
    display: flex;
    align-items: center;
    height: 40px;
    // opacity: 0.3;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--date-picker1);
    justify-content: space-around;
    width: 100%;

    .el-icon-search {
      // margin-left: 5px;
      margin-top: 2px;
    }
  }

  .clearText {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--valligeCountPinbigText);
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 2;
    cursor: pointer;
  }

  .clearText:hover {
    opacity: 0.8;
  }

  #search_input {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    background: transparent;
    border: 0;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--searchInput1);
  }
}

.searchInput::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg,
      var(--searchInputBefore) 0%,
      var(--searchInputBefore) 20%,
      var(--searchInputBefore1) 50%,
      var(--searchInputBefore) 80%,
      var(--searchInputBefore) 100%);
  left: 0;
  top: -1px;
}

.searchInput::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  background: linear-gradient(90deg,
      var(--searchInputBefore) 0%,
      var(--searchInputBefore) 20%,
      var(--searchInputBefore1) 50%,
      var(--searchInputBefore) 80%,
      var(--searchInputBefore) 100%);
  left: 0;
  bottom: -1px;
}
</style>
