<template>
  <div id="Studio" :style="zuixiao ? 'background: rgba(1, 2, 14, 0);width:0;height:0;' : ''">
    <!-- 标题 -->

    <!-- 右上角按钮栏 -->
    <div class="right_top_button" v-show="!zuixiao">
      <img @click="zuixiao = true" src="@/assets/dy/最小化.png" />
      <img @click="clear" src="@/assets/images/d6.png" />
    </div>
    <!-- 内容 -->
    <div class="studio_content">
      <div v-dialogdrag="zuixiao" :class="['studio_video', zuixiao ? 'dialogdrag' : 'lt0']">
        <OpenVideo ref="child" v-if="roomId" :info="info" :zuixiao="zuixiao" />
        <van-icon v-if="zuixiao" name="cross" color="#ffffff" class="close" @click="clear" />
        <img v-if="zuixiao" src="@/assets/dy/放大.png" class="fangda" @click="zuixiao = false" />
      </div>
      <div class="studio_list list" v-show="!zuixiao">
        <MoreTitle :titles="['网格联动室']" />
        <div style="height:20px;flex-shrink: 0;"></div>
        <el-scrollbar style="flex:1">
          <div class="studio_data" style=" cursor: pointer;" :key="key + val" v-for="(val, key) in videoList">
            <div class="studio_data_image" @click="videoInfo(val, '视频联动')" :data-od="val.id + '-' + info.id"
              :data-id="val.reportId + '-' + info.reportId">
              <img v-if="val.cover" :src="val.cover" class="studio_data_image_src" />
              <text class="show_true" v-if="val.reportId == info.reportId">播放中</text>
            </div>
            <div class="studio_data_title">
              视频联动中...
            </div>
          </div>
        </el-scrollbar>
      </div>

    </div>
  </div>
</template>

<script>
import { videolinkagePage, getMonitoringEquipment } from "@/api";
import { MoreTitle } from "@/componentss";
import OpenVideo from "./OpenVideo.vue";

export default {
  name: "Studio",
  components: {
    MoreTitle,
    OpenVideo,
  },

  computed: {
    path() {
      return this.$route.path;
    },
    roomId() {
      return this.$store.state.index.roomId;
    },
    videoEvent() {
      return this.$store.state.index.videoEvent;
    },
  },
  watch: {
    // path(val) {
    //   this.modules.forEach((e, i) => {
    //     if (this.path == e.url) this.i = i;
    //     e.modules.forEach((el) => {
    //       if (this.path == el.url) this.i = i;
    //     });
    //   });
    // },
  },
  data() {
    return {
      zuixiao: false,
      cameraList: [],
      missionList: [],
      videoList: [],
      type: false,
      info: false,
    };
  },
  mounted() {
    videolinkagePage({ current: 1, size: 99999, status: 1 }).then((res) => {
      this.videoList = res.data.records;
      this.info = res.data.records.find(e => {
        return e.reportId == this.videoEvent.id
      })
    });
    getMonitoringEquipment().then((res) => {
      this.cameraList = res.data
    });
  },
  watch: {
    // info(val) {
    //   if (!val) {
    //     // if (this.type == '视频联动') {
    //       this.$refs.child.closeRoom();
    //     // }
    //     this.$store.commit("index/set", { type: "roomId", val: false });
    //     return false;
    //   }
    //   // if (this.type == '视频联动') {
    //     this.$store.commit("index/set", { type: "roomId", val: val.id });
    //   // }
    // }
  },
  methods: {
    async clear() {
      if (this.$refs.child) await this.$refs.child.jieshu();
    },
    async videoInfo(val, type) {
      this.info = false;
      await this.$refs.child.closeRoom();
      setTimeout(() => {
        this.type = type;
        this.info = val;
        this.$store.commit("index/set", { key: "roomId", val: val.reportId });
      }, 500)
    }
  },
  destroy() {
    if (this.$refs.child) this.$refs.child.closeRoom();

  }
};
</script>

<style lang="scss" scoped>
#Studio {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background: rgba(1, 2, 14, 0.95);
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .right_top_button {
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 99;

    img {
      width: 40px;
      height: 40px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .studio_content {
    width: 100%;
    flex-grow: 1;
    display: flex;
    margin-top: 10px;
    overflow: hidden;

    .studio_list {
      width: 230px;
      height: 100%;
      overflow: hidden;
      margin-left: 30px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;

      .studio_data {
        width: 100%;
        margin-bottom: 20px;

        .studio_data_image {
          width: 100%;
          height: 124px;
          overflow: hidden;
          border-radius: 5px;
          margin-bottom: 6px;
          position: relative;

          background: #3d3b3b;

          .studio_data_image_src {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .studio_data_image_button {
            width: 50px;
            height: 50px;
            position: absolute;
            left: calc(50% - 25px);
            top: calc(50% - 25px);
          }

          .show_true {
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            height: 100%;
            border: 2px solid rgba(255, 117, 33, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
          }
        }

        .studio_data_title {
          width: 100%;
          background: rgba(255, 166, 64, 0.2);
          height: 20px;
          line-height: 20px;
          box-sizing: border-box;
          padding-left: 12px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #FFA640;
          position: relative;

        }

        .studio_data_title::before {
          content: "";
          position: absolute;
          left: 0;
          top: 3px;
          width: 8px;
          height: 14px;
          border-radius: 0 14px 14px 0;

          background: rgba(255, 166, 64, 1);
        }
      }
    }

    .studio_video {
      flex-grow: 1;
      overflow: hidden;
      position: relative;
      background: #313131;
      left: 0;
      top: 0;

      .overBtn {
        position: absolute;
        bottom: 20px;
        right: 20px;
      }
    }
  }
}

.dialogdrag {
  width: calc(1920px * 0.2) !important;
  height: calc(1080px * 0.2) !important;
  padding: 2px !important;
  overflow: hidden !important;
  border-radius: 5px !important;
  position: fixed !important;
  background: rgba(0, 0, 0, 0.9) !important;
  z-index: 999 !important;

  .uav_video {
    width: 100% !important;
    height: 100% !important;
    background: #262626 !important;
  }
}

.close {
  position: absolute !important;
  right: 10px !important;
  top: 10px !important;
  cursor: pointer !important;
}

.fangda {
  position: absolute !important;
  right: 40px !important;
  top: 9px !important;
  cursor: pointer !important;
  width: 18px;
  height: 18px;
}

* {
  transition: all 0.5s, left 0s, top 0s;
}

.lt0 {
  left: 0 !important;
  top: 0 !important;

}
</style>
