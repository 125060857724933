<template>
  <div
    :class="[className, 'types', wrap ? 'wrap' : '', width ? 'wrapwidth' : '']"
    v-if="array.length > 1"
  >
    <button
      v-for="(val, key) in array"
      :key="key + val"
      :style="
        className && type == val.id
          ? `color:rgba(0, 255, 204, 1) !important;font-weight: bold;width:` +
            width
          : `width:` + width
      "
      :class="[type == val.id ? 'true' : '']"
      @click="typeButton(val.id)"
    >
      {{ val.name }}
    </button>
  </div>
</template>

<script>
export default {
  name: "TypeButton",
  // setup(props, ctx) {
  //   const typeButton = (data) => {
  //     ctx.emit("typeButton", data);
  //   };
  //   return { typeButton };
  // },
  props: {
    array: {
      type: Array,
      default: [],
    },
    type: String,
    wrap: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
  },
  mounted() { this.chooseCss() },
  methods: {
    typeButton(data) {
      this.$emit("typeButton", data);
    },
  },
};
</script>

<style lang="scss" scoped>
// 通用类型组
.types {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  padding: 5px 10px 0px 10px;
  box-sizing: border-box;
  flex-shrink: 0;
  background: var(--typesBackground);
  button {
    flex-grow: 1;
    // margin-left: 6px;
    margin-bottom: 6px;
    border-radius: 5px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    border: 1px solid var(--servicesPin-button2);
    // border-image: linear-gradient(
    //     0deg,
    //     var(--servicesPin-button3),
    //     var(--infolist2)
    //   )
    //   10 10;
    background: linear-gradient(90deg, rgba(34, 226, 255, 0.2) 0%, rgba(166, 255, 239, 0.2) 100%) !important;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(1, 180, 255, 1);
  }
  button:first-of-type {
    margin-left: 0;
  }
  button:hover {
    color: var(--typesBackgroundbuttonhover);
    background: linear-gradient(
      180deg,
      var(--typesBackgroundbutton1) 0%,
      var(--typesBackgroundbutton2) 100%
    );
    border: 1px solid var(--typesBackgroundbuttonhover1);
  }
  .true {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: rgba(255, 255, 255, 1) !important;
    background: linear-gradient(90deg, rgba(34, 226, 255, 0.75) 0%, rgba(166, 255, 239, 0.75) 100%) !important;
    box-shadow: 0px 4px 10px 0px var(--topLine1) !important;
    border-radius: 10px !important;
  }
}
.wrap {
  justify-content: flex-start;
  flex-wrap: wrap;
  button {
    width: calc(100% / 3 - 4px) !important;
    flex-grow: 0 !important;
  }
  button:first-of-type {
    // margin-left: 6px !important;
  }
}
.wrapwidth {
  // justify-content: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  button {
    width: auto !important;
    padding: 0;
    flex-grow: 0 !important;
  }
  button:first-of-type {
    // margin-left: 6px !important;
  }
}

.newTypeClass {
  button {
    min-width: 100px !important;
    height: 36px !important;
    background: linear-gradient(
      0deg,
      var(--newTypeClassbutton) 0%,
      var(--newTypeClassbutton1) 100%
    );
    border-radius: 15px !important;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--newTypeClassbutton2);
  }
}
.newTypeClass11 {
  button {
    min-width: 100px !important;
    height: 36px !important;
    background: linear-gradient(
      0deg,
      var(--newTypeClassbutton) 0%,
      var(--newTypeClassbutton1) 100%
    );
    border-radius: 15px !important;
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: var(--newTypeClassbutton3);
  }
}
</style>
