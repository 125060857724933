<template>
  <div class="allBtn">
    <div
      @click="clickBtn(btn)"
      :class="nowBtn == btn.id ? 'activeBtn' : 'disBtn'"
      v-for="(btn, idx) in btnArrs"
      :key="btn + idx"
    >
      {{ btn.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    btnArrs: [],
  },
  data() {
    return {
      nowBtn: false,
    };
  },
  watch: {
    btnArrs: {
      handler(newVal, oldVal) {
        if (newVal.length > 0) {
          this.nowBtn = newVal[0].id;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // console.log(this.btnArrs);
    // if (this.btnArrs.length > 0) {
    //   this.nowBtn = this.btnArrs[0].id;
    //   console.log(this.nowBtn);
    // }
  },
  methods: {
    clickBtn(btn) {
      this.nowBtn = btn.id;
      this.$emit("clickBtn", btn.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.allBtn {
  width: 100%;
  display: flex;
  // justify-content: center;
  align-items: center;
  // margin-top: 10px;
  flex-wrap: wrap;
  flex-shrink: 0;
  cursor: pointer;
  .activeBtn {
    margin-left: 10px;
    padding: 0 16px;
    box-sizing: border-box;
    height: 32px;
    background: #22e2ff;
    box-shadow: 0px 5px 15px 0px rgba(34, 226, 255, 0.3);
    border-radius: 15px;
    font-size: 18px;
    font-family: SourceHanSerifSC;
    font-weight: 800;
    color: #282828;
    line-height: 29px;
  }

  .disBtn {
    margin-left: 10px;
    padding: 0 16px;
    box-sizing: border-box;
    height: 32px;
    background: #282828;
    border: 1px solid #22e2ff;
    border-radius: 15px;

    font-size: 18px;
    font-family: SourceHanSerifSC;
    font-weight: 800;
    color: #22e2ff;
    line-height: 29px;
  }
}
</style>
