<template>
  <div v-if="show" :id="id" class="echarts"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { GlobeComponent } from 'echarts-gl/components';
import {
  BarChart,
  LineChart,
  PieChart,
  PictorialBarChart,
  RadarChart,
  ScatterChart,
  // SurfaceChart
} from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  PolarComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  PieChart,
  BarChart,
  LineChart,
  PictorialBarChart,
  RadarChart,
  ScatterChart,
  DataZoomComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  CanvasRenderer,
  ToolboxComponent,
  PolarComponent,
  // SurfaceChart
  GlobeComponent
]);
export default {
  name: "Echarts",
  props: {
    id: String,
    option: Object,
    show: Boolean,
  },
  data() {
    return {
      arr: ['farmerl4','farmerl3','important333','important25258','important111','natureType','important','important1','important2','farmerl3', 'farmerl4', 'important444', 'farmerl555','farmerlopReformPlan','farmerlopReform','farmerlop','farmerl3oo','farmerl4oo','farmerlop1','importantProject']
    }
  },
  watch: {
    show(val, oldVal) {
      this.drawing();
    },
  },
  mounted() { this.chooseCss()
    this.drawing();
  },
  methods: {
    drawing() {
      if (!this.show) return false;
      this.$nextTick(() => {
        // console.log($('#farmerl555').height())

        var myChart = echarts.init(document.getElementById(this.id));
        if (
          this.arr.includes(this.id)
        ) {
          // let height = $('#' + this.id).height()
          let height = $('#' + this.id).parents('.el-scrollbar__wrap').height()
          let autoHeight = this.option.series[0].data.length * 32;
          // console.log(height,autoHeight)
          autoHeight > height ? myChart.resize({ height: autoHeight }) : myChart.resize({ height });
        }

        if (
          [ 'warningList', 'warningList1', 'warningList11', 'warningList111','warningList1212'].includes(this.id)
        ) {
          // let height = $('#' + this.id).height()

          let height = $('#' + this.id).parents('.el-scrollbar__wrap').height()
          // console.log()
          let autoHeight = this.option.series[0].data.length * 60;
          autoHeight > height ? myChart.resize({ height: autoHeight }) : "";
        }
        myChart.clear();
        myChart.showLoading();
        myChart.setOption(this.option);
        myChart.hideLoading();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}
</style>
