<template>
    <div class="x" v-if="isMounted">
        <div :id="uid" class="svgaBox1"></div>
    </div>
</template>
<script>
import SVGA from "svgaplayerweb";
export default {
    props: ["uid"],
    data() {
        return { isMounted: false };
    },
    created() { },
    watch: {
        uid: {
            handler() {
                this.$nextTick(() => {
                    this.isMounted = true
                    setTimeout(() => {
                        var player = new SVGA.Player("#" + this.uid);
                        var parser = new SVGA.Parser("#" + this.uid);
                        //注意，这里必须是服务器地址，否则报错
                        parser.load(process.env.NODE_ENV === 'production' ? window.location.origin + window.location.pathname + 'images/动效01.svga' : "/sva/images/动效01.svga", function (videoItem) {

                            // console.log(process.env.NODE_ENV)
                            // console.log(window.location.origin + window.location.pathname)
                            player.setVideoItem(videoItem);
                            player.startAnimation();
                        });
                    }, 100);
                })
            },
            immediate: true,
            deep: true
        }
    },
    mounted() { this.chooseCss()


    }
};
</script>
<style lang="scss" scoped>
.x {
    pointer-events: none;
}

.svgaBox1 {
    width: 200px;
    height: 200px;
}
</style>
