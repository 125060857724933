<template>
  <div class="headers">
    <img
      class="topBack"
      :src="require('@/assets/static/背景上.png')"
      alt=""
    />
    <img
      class="bottomBack"
      :src="require('@/assets/static/背景下.png')"
      alt=""
    />
    <transition name="diy-slide-top">
      <div class="header-info" v-if="show">
        <div class="topI">
          <div class="header-info-weather">
            <img class="header-info-weather-icon" v-if="icon" :src="icon" />
            <div>
              <p class="header-info-weather-temperature">{{ temperature }}</p>
              <p class="header-info-weather-direction">{{ direction }}</p>
            </div>
          </div>
          <!-- <span class="tec">技术支持：{{ userInfo.support }}</span> -->
        </div>
        <div class="bottomInfo">
          <span class="header-info-datetime-time" v-if="hour != ''">
            {{ hour }}:{{ minute }}:{{ second }}
          </span>
          <div class="header-info-datetime">
            <span class="header-info-datetime-date" v-if="year != ''">
              <!-- <span class="header-info-datetime-date-direction">{{
                  direction
              }}</span> -->
              <span>{{ month }}月{{ date }}日</span>
              <span class="header-info-datetime-date-week">{{ day }}</span>
            </span>
          </div>
        </div>
      </div>
    </transition>
    <!-- 标题 -->
    <!-- <span class="tec">{{ userInfo.support }}</span> -->
    <transition name="diy-slide-top">
      <!-- == 4 -->
      <div
        class="header-title"
        :style="navradio == 4 ? 'top:15px;left:20px;transform:unset;' : ''"
      >
        <!-- <img class="backLogo" :src="backLogo" v-if="show" /> -->
        <!-- <div class="topName">
          数字东冲••智慧平台
        </div> -->
        <img  src="@/assets/dc/logo.png" v-if="show" />
      </div>
    </transition>
    <!-- <img class="topBack" :style="!arr.includes(path) ? '' : 'top:-35px'" :src="
      !arr.includes(path)
        ? require('@/assets/images/0上阴影.png')
        : require('@/assets/static/背景上.png')
    " alt="" />
    <img class="bottomBack" :src="
      !arr.includes(path)
        ? require('@/assets/images/0下阴影.png')
        : require('@/assets/static/背景下.png')
    " alt="" /> -->
  </div>
</template>

<script>
// import { getWeather } from "@/api";
export default {
  props: ["secMenu"],
  name: "Headers",
  computed: {},
  data() {
    return {
      // 时间天气
      year: "",
      month: "",
      date: "",
      day: "",
      hour: "",
      minute: "",
      second: "",
      city: "",
      direction: "",
      temperature: "",
      icon: "",
      header: false,
      show: false,
      // arr: ["/BriefIntroduction", "/PartyDesc", "/Leaders", '/VideoInfo', '/SocietyShipCare'],
    };
  },
  computed: {
    backLogo() {
      return JSON.parse(
        decodeURIComponent(this.getCookie("userInfo"))
          .replace(/%22/g, '"')
          .replace(/%2C/g, ",")
      ).gridIcon;
    },
    userInfo() {
      return JSON.parse(
        decodeURIComponent(this.getCookie("userInfo"))
          .replace(/%22/g, '"')
          .replace(/%2C/g, ",")
      );
    },
    navradio() {
      return this.$store.state.index.navradio;
    },
    path() {
      return this.$route.path;
    },
    titleRadio() {
      return this.$store.state.index.titleRadio;
    },
  },
  created() {
    // this.header = logo;
  },
  activated() {
    setTimeout(() => {
      this.show = true;
    }, 300);
  },
  deactivated() {
    this.show = false;
  },
  mounted() {
    this.time();
    setInterval(this.time, 1000);
    // getWeather().then((res) => {

    //   var weather = res.data.temperature + "°C";
    //   this.temperature = weather;
    //   var type = res.data.condtxt; //天气状态
    //   this.direction = res.data.winddir + "  " + type;
    //   this.icon = require(`@/assets/weather/${type}.png`);
    // });

    var weather = "20°C";
    this.temperature = weather;
    var type = "小雨"; //天气状态
    this.direction = "微风" + "  " + type;
    this.icon = require(`@/assets/weather/${type}.png`);
  },
  methods: {
    // 时间 日期
    time() {
      var date = new Date();
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.date = date.getDate();
      this.day = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      )[date.getDay()];
      this.hour =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      this.minute =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      this.second =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    },
  },
};
</script>

<style lang="scss" scoped>
.topBack {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
}

.bottomBack {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
}
.headers {
  position: relative;
  z-index: 10;
  pointer-events: none;
  width: 100%;

  .tec {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(161, 194, 187, 1);
    opacity: 0.3;
    position: fixed;
    left: 20px;
    top: 7px;
  }

  .topBack {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
  }

  .bottomBack {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
  }

  .header-title {
    position: fixed;
    left: 50%;
    top: 7px;
    transform: translate(-50%, 0);
    display: flex;
    align-items: center;
    // transition: all 0.3;
    // width: 100vw;
    // height: 75px;
  }

  .header-info {
    position: fixed;
    z-index: 99;
    right: 150px;
    top: 15px;
    // height: 52px;
    // width: 380px;
    display: flex;
    // flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    .topI {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      // flex-direction: column;
      // height: 42px;
      // background: goldenrod;

      .header-info-weather {
        text-align: left;
        display: flex;
        // align-items: center;
        width: 100%;
        height: 100%;

        .header-info-weather-temperature {
          display: block;
          font-size: 20px;
          font-family: Square722;
          font-weight: 400;
          color: #ffffff;
          color: rgba(255, 255, 255, 1);
          line-height: 30px;
        }

        .header-info-weather-direction {
          display: block;
          font-size: 16px;
          line-height: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(161, 194, 187, 1);
          margin-top: 2px;
        }

        .header-info-weather-icon {
          height: 20px;
          margin: 6px 6px 0 0;
        }
      }
    }

    .bottomInfo {
      // background: goldenrod;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      .header-info-datetime-time {
        line-height: 31px;
        margin-left: 20px;
        letter-spacing: 3px;
        font-size: 22px;
        font-family: Square722;
        font-weight: 400;
        color: #ffffff;
      }

      .header-info-datetime {
        text-align: center;
        display: flex;
        align-items: center;

        .header-info-datetime-date {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(161, 194, 187, 1);
          line-height: 20px;
          margin-left: 20px;
          letter-spacing: 1px;
          display: flex;
          align-items: center;
          // margin-top: 3px;

          .header-info-datetime-date-week {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(161, 194, 187, 1);
            line-height: 20px;
            white-space: nowrap;
            margin-left: 5px;
            letter-spacing: 1px;
          }

          .header-info-datetime-date-direction {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: rgba(161, 194, 187, 1);
            line-height: 20px;
            white-space: nowrap;
            margin-right: 24px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}

.left-jioback,
.right-jioback {
  position: fixed;
  top: 0;
  z-index: 5;
  pointer-events: none;
}

.left-jioback {
  left: 0;
}

.right-jioback {
  right: 0;
  transform: rotateY(180deg);
}

.backLogo {
  width: 54px;
  height: 54px;
  object-fit: contain;
  margin-right: 10px;
}

.topName {
  font-size: 52px;
  font-family: YouSheBiaoTiHei;
  font-weight: 400;
  color: #FFFFFF;
  text-shadow: 0 4px 4px rgba(16,105,255,0.1), 0 0 0px rgba(255, 255, 255, 1),
    0px 4px 4px rgba(16, 105, 255, 0.2), 0px 5px 20px rgba(16, 105, 255, 0.4);
  // -webkit-text-stroke: 1px #FBC545;
  // text-stroke: 1px #FBC545;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9) 64.794921875%,
    #C7EEFF 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
