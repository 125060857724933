<template>
  <div class="allInfo" v-if="list">
    <div class="allIcon">
      <OneIcon @click.native="choose(idx)" :name="idx" v-for="(li,idx) in list.attributeList" :key="li+idx" />
    </div>
    <div class="intr" v-for="(item, idx) in list.attributeList[state.title]" :key="item + idx">
      <div>{{ item.fieldName }}：</div>
      <div>{{ item.fieldValue || '--' }}</div>
    </div>
  </div>
</template>

<script>
import {
  h,
  ref,
  reactive,
  watch,
  getCurrentInstance,
  onMounted,
  onActivated,
  onDeactivated,
  onUnmounted,
} from "vue";
import OneIcon from "./OneIcon.vue";
export default {
  components: { OneIcon },
  props: ["list"],
  setup(props,ctx) {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      title: false,
    });
    watch(
      () => props.list,
      (val) => {
        if (val) {
          console.log(val)
         let arr = Object.keys(val.attributeList);
         console.log(arr)
         state.title = arr[0]
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    onMounted(() => {
            _this.chooseCss()
        });
    const choose = (idx) => {
      state.title = idx
      // console.log(idx)
    }

    return {
      state,
      choose
    };
  },
};
</script>

<style lang="scss" scoped>
.allIcon {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

}

.allInfo {
  width: 100%;
  display: flex;
  margin-top: 14px;
  flex-direction: column;
}

.intr {
  width: fit-content;
  // margin-right: 35px;
  width: 100%;
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  line-height: 24px;

  div:nth-child(1) {
    color: var(--ssintr);
    flex-shrink: 0;
  }

  div:nth-child(2) {
    color: var(--ssallInfo);
  }
}
</style>