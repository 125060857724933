<template>
  <div v-if="show" :id="id" class="echarts"></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  BarChart,
  LineChart,
  PieChart,
  PictorialBarChart,
  RadarChart,
} from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  PolarComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";
echarts.use([
  PieChart,
  BarChart,
  LineChart,
  PictorialBarChart,
  RadarChart,

  DataZoomComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  CanvasRenderer,
  ToolboxComponent,
  PolarComponent,
]);
export default {
  name: "Echarts",
  props: {
    id: String,
    option: Object,
    show: Boolean,
  },
  watch: {
    show(val, oldVal) {
      this.drawing();
    },
  },
  mounted() {
    this.drawing();
  },
  methods: {
    drawing() {
      if (!this.show) return false;
      this.$nextTick(() => {
        var myChart = echarts.init(document.getElementById(this.id));
        if (
          this.id == "farmer253gfh5uyt6756" 
        ) {
          let autoHeight = this.option.series[0].data.length * 32;
          myChart.resize({ height: autoHeight });
        }
        if (
          this.id == "hesuan5555" ||
          this.id == "hesuan55665"
        ) {
          let autoHeight = this.option.series[0].data.length * 33;
          myChart.resize({ height: autoHeight });
        }
        myChart.clear();
        myChart.showLoading();
        myChart.setOption(this.option);
        myChart.hideLoading();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.echarts {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
}
</style>
