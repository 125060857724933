<template>
  <div class="iframeBox" id="i1" :style="`z-index:` + zIndex">
    <iframe :src="url" frameborder="0"></iframe>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  computed,
  watch,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";

export default {
  props: ["url", "zIndex"],
  setup() {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
    });
    onMounted(async () => {

    });
    return {
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.iframeBox {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>