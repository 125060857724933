<template>
    <div class="divFlex alignCenter boatStaticItem">
        <img :src="src" alt="">
        <div class="column boatItem">
            <span>{{ name }}</span>
            <div class="divFlex alignEnd boatStatic">
                <span :class="classStr">{{ num }}</span>
                <span class="boatStaticUnit">{{ unit }}</span>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: 'JourneyData',
    props: {
        src: {
            default: false,
        },
        name: {
            default: false,
        },
        num: {
            default: false,
        },
        unit: {
            default: false,
        },
        classStr: {
            default: 'boatStaticNum',
        }

    }

};
</script>
  
<style lang="scss" scoped>
.boatStaticItem {
    img {
        margin-right: 5px;
    }

    .boatItem {
        span:nth-child(1) {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #A6E5FF;
        }

        .boatStatic {
            width: 100px;
            background: linear-gradient(to right, rgba(5, 78, 129, 0.3) 0%, rgba(5, 78, 129, 0) 100%);

            .boatStaticNum {
                font-size: 30px;
                line-height: 30px;
                font-family: Square722;
                font-weight: bold;
                color: #E5FFFF;

                background: linear-gradient(to left, #1069FF 0%, #01B4FF 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .boatStaticNumFisher {
                font-size: 30px;
                line-height: 30px;
                font-family: Square722;
                font-weight: bold;
                color: #E5FFFF;

                background: linear-gradient(to left, rgba(166, 255, 239, 1) 0%, rgba(34, 226, 255, 1) 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .boatStaticNum1 {
                font-size: 30px;
                line-height: 30px;  
                font-family: Square722;
                font-weight: bold;
                color: #E5FFFF;

                background: linear-gradient(to left, #00CABC 0%, #02F3C3 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            

            .boatStaticUnit {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #A6E5FF;
                line-height: 22px;
            }
        }
    }
}
</style>
  