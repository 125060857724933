<template>
  <div
    class="typeBtns column"
    :style="globalData.rightStatus ? `right:667px;` : (right?``:`right:0px;`) "
  >
    <div class="topLine"></div>
    <div class="divFlex" style="margin-left: 15px">
      <div
        :class="
          type == item.id
            ? 'activeBtn column alignCenter relative'
            : 'disBtn column alignCenter relative'
        "
        v-for="item in btnArr"
        :key="item"
        @click="clickThis(item)"
      >
        <img v-if="type != item.id" src="@/assets/dc/1724.png" alt="" />
        <img v-else src="@/assets/dc/1722.png" alt="" />

        <div class="column alignCenter">
          <img v-if="type != item.id" src="@/assets/dc/17222.png" alt="" />
          <img v-else src="@/assets/dc/1721.png" alt="" />
          <span class="text">{{ item.title || item.name }}</span>
        </div>

        <img v-if="type != item.id" src="@/assets/dc/1725.png" alt="" />
        <img v-else src="@/assets/dc/1723.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Title",
  inject: ["globalData"],
  props: {
    btnArr: [],
    type: "",
  },
  computed: {
    right() {
      return this.$store.state.index.right;
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    clickThis(val) {
      this.$emit("clickThis", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.typeBtns {
  display: flex;
  position: fixed;
  top: 81px;
  right: 388px;
  z-index: 11;
  font-size: 20px;
  font-family: SourceHanSerifSC;
  font-weight: 800;
  cursor: pointer;
  transition: all 0.3s;
  .topLine {
    width: 310px;
    height: 1px;
    background: linear-gradient(
      to right,
      rgba(34, 226, 255, 0) 0%,
      rgba(34, 226, 255, 0.83) 27%
    );
  }
  .activeBtn {
    position: relative;
    width: 36px;
    height: 125px;
    margin: 0 7px;

    .text {
      color: rgba(40, 40, 40, 1);
      position: absolute;
      left: 50%;
      bottom: -16px;
      transform: translate(-50%, 0);
      z-index: 2;
      writing-mode: vertical-lr;
      /*垂直展示*/
      writing-mode: tb-lr;
      /*兼容IE*/
    }
  }

  .disBtn {
    width: 36px;
    height: 125px;
    position: relative;
    margin: 0 7px;

    .text {
      color: rgba(34, 226, 255, 1);
      position: absolute;
      left: 50%;
      bottom: -16px;
      transform: translate(-50%, 0);
      z-index: 2;
      writing-mode: vertical-lr;
      /*垂直展示*/
      writing-mode: tb-lr;
      /*兼容IE*/
    }
  }
}
</style>
