<template>
  <!--视频组件容器-->
  <div class="content">
    <!--右边视频盒子-->
    <div class="left1">
      <div :class="[('user', 'user' + (list.length > 7 ? 8 : list.length))]" id="local_stream"></div>

      <!--远端流-->
      <div v-for="(item, idx) in list" :class="[('user', 'user' + (list.length > 7 ? 8 : list.length))]"
        @click="activeId = item.id" :key="idx + 'i'">
        <div :id="item.id" class="distant-stream" v-html="item.remoteStream"></div>
      </div>
    </div>

    <div class="bottom_title" v-if="!zuixiao">
      <img src="@/assets/dy/联动中.png" />
      <div style="font-size: 18px;color: #FF7521;margin-left: 20px;">
        {{ info.reportName }} - 视频联动中...
      </div>
      <div class="users_datas" v-if="user && user.length > 0">
        <div v-for="(item, index) in user" class="show_false" :key="index+'dsfds'">
          <img :src="
            item.avatar
              ? $image + item.avatar
              : 'http://xpsq.3dy.me/assets/img/avatar.png'
          " />
          {{ item.name }}
        </div>
      </div>
    </div>

    <img v-if="!zuixiao && is_pc" src="@/assets/dy/结束联动.png" class="jieshu" @click="jieshu" />
  </div>
</template>

<script>
import {
  startMixedFlow,
  endMixedFlow,
  getVideoLinkageOver,
} from "@/api";
import LibGenerateTestUserSig from "../../assets/js/lib-generate-test-usersig.min.js";
import TRTC from "trtc-js-sdk";
export default {
  data() {
    return {
      activeId: 0, // 当前视频的播放id
      index: 0,
      list: [], // 远端流列表
      client: "", // 客户端服务
      remoteStream: "", // 远方播放流
      remoteStreamArr: [],
      localStream: "", // 本地流,
      isVedio: true, // 当前是否打开摄像头
      isAudio: true, // 当前是否打开麦克风
      members_: new Map(), // 成员
      isHave: false, //当前列表是否有该成员
      userId: JSON.parse( this.getCookie('userInfo')).user_id,

      user: [],
      is_pc: false,
      // roomId:2
    };
  },
  props: ["info", "zuixiao"],
  components: {},
  computed: {
    roomId() {
      return this.$store.state.index.roomId;
    },
  },
  created() {
    let that = this;
    $(document).on("click", ".mkf", function () {
      that.muteLocalAudio();
    });
    $(document).on("click", ".sxt", function () {
      that.muteLocalVideo();
    });
    // getVideoLinkRoomUserList({ id: this.roomId }).then((res) => {
    //   this.is_pc = res.data.is_pc;
    //   this.user = res.data.list;
    // });
  },
  mounted() {
    this.list = [];
    this.createClient("ceshi_20200" + parseInt(Math.random() * 100));
    /* eslint-disable no-global-assign */
    /* global $ TRTC Presetting deviceTestingInit cameraId micId */
    const presetting = new Presetting();
    presetting.init();
    rtcDetection().then((detectionResult) => {
      console.log("执行这里");
      detectionResult && deviceTestingInit();
    });

    // check if browser is compatible with TRTC
    TRTC.checkSystemRequirements().then((result) => {
      if (!result) {
        alert("您的浏览器不兼容此应用！\n建议下载最新版Chrome浏览器");
        window.location.href = "http://www.google.cn/chrome/";
      }
    });

    // setup logging stuffs
    TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.DEBUG);
    TRTC.Logger.enableUploadLog();

    TRTC.getDevices()
      .then((devices) => {
        devices.forEach((item) => {
          console.log(
            "device: " + item.kind + " " + item.label + " " + item.deviceId
          );
        });
      })
      .catch((error) => console.error("getDevices error observed " + error));

    // populate camera options
    TRTC.getCameras().then((devices) => {
      devices.forEach((device) => {
        if (!cameraId) {
          cameraId = device.deviceId;
        }
        let div = $("<div></div>");
        div.attr("id", device.deviceId);
        div.html(device.label);
        div.appendTo("#camera-option");
      });
    });

    // populate microphone options
    TRTC.getMicrophones().then((devices) => {
      devices.forEach((device) => {
        if (!micId) {
          micId = device.deviceId;
        }
        let div = $("<div></div>");
        div.attr("id", device.deviceId);
        div.html(device.label);
        div.appendTo("#mic-option");
      });
    });
    // }
  },
  methods: {
    // 开启混流
    start_mixed() {
      var data = {}, 
      user_id = JSON.parse( this.getCookie('userInfo')).user_id, 
      userId = "", 
      report_id = 0;
      userId += "u_"; //前缀 
      userId += report_id; //事件ID 
      userId += ("_" + user_id); //用户ID 
      userId += ("_" + Math.floor(Math.random() * 100)) //随机数 
      data.roomId = this.roomId; 
      data.StreamId = 1400568445 + "_" + userId + "_main";
      console.log(data)
        startMixedFlow(data).then((res) => {
          console.log(res, "开启混流");
        });
      },
       // 关闭混流
    end_mixed () {
      var data = {
        roomId: this.roomId,
      };
      endMixedFlow(data).then((res) => {
        console.log("结束混流");
      });
    },
    
    async jieshu() {
      this.$confirm("是否结束当前视频会议?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
         await endMixedFlow({
            roomId: this.roomId,
            report_id: this.info.report_id,
            user_id: 0,
          }).then((res) => {
            this.$message({
              message: "结束成功！",
              type: "success",
            });
            this.$store.commit("index/set", { key: "room", val: false });
            this.closeRoom();
          });
        })
        .catch(() => { });
    },
    close() {
      this.isVedio = true;
      this.isAudio = true;
      this.isShow = false;
      this.$emit("close");
    },
    // 创建链接
    createClient(userId) {
      // 获取签名
      const config = this.genTestUserSig(userId);
      const sdkAppId = config.sdkAppId;
      const userSig = config.userSig;
      this.client = TRTC.createClient({
        mode: "rtc",
        sdkAppId,
        userId,
        userSig,
      });
      console.log(this.client);
      // 初始化后才能加入房间
      this.joinRoom();
    },
    // 加入房间
    async joinRoom() {
      console.log(this.roomId);
      await this.client
        .join({ roomId: Number(this.roomId) }) // Number(this.videoInfo.roomNumber)
        .then(() => {
          // 创建本地流
          this.createStream();
          // 播放远端流
          this.playStream();
        })
        .catch((error) => {
          console.error("进房失败 " + error);
        });

      // 注册远程监听，要放在加入房间前--这里用了发布订阅模式
      this.subscribeStream();
    },
   
    // 创建本地音视频流
    createStream() {
      this.localStream = TRTC.createStream({
        userId: this.userId,
        audio: true,
        video: true,
      });

      this.localStream
        .initialize()
        .then(() => {
          console.log("初始化本地流成功");
          // 创建好后才能播放 本地流播放 local_stream 是div的id
          this.localStream.play("local_stream");
          this.$nextTick(() => {
            // 创建好后才能发布
            this.publishStream();
            this.start_mixed();
          });
          this.updateStream();
        })
        .catch((error) => {
          console.error("初始化本地流失败 " + error);
        });
    },
    // 发布本地音视频流
    publishStream() {
      this.client
        .publish(this.localStream)
        .catch((error) => {
          console.error("本地流发布失败 " + error);
        })
        .then(() => {
          $("#local_stream")
            .addClass("mainVedio")
            .parent()
            .addClass("active");
          console.log("本地流发布成功");
          // this.$axios.get('http://zhzc.3dy.me/update_keysites_detail', { params: {report_id} }).then((res) => {})
        });
    },
    // 远端监听
    updateStream() {
      this.client.on("stream-removed", (event) => {
        const remoteStream = event.stream;
        var i = this.list.findIndex(
          (v) => v.userId === remoteStream.getUserId()
        );
        this.list.splice(i, 1);
      });
      // 关闭摄像头
      this.client.on("mute-video", (evt) => {
        let streamId = getUidByStreamId(evt.userId);
      });
      // 打开摄像头
      this.client.on("unmute-video", (evt) => {
        let streamId = getUidByStreamId(evt.userId);
      });
      // 关闭语音
      this.client.on("mute-audio", (evt) => {
        let streamId = getUidByStreamId(evt.userId);
      });
      // 打开语音
      this.client.on("unmute-audio", (evt) => {
        let streamId = getUidByStreamId(evt.userId);
      });
    },
    // 根据id获取uid
    getUidByStreamId(streamId) {
      for (let [uid, stream] of this.members_) {
        if (stream.getUserId() == streamId) {
          return uid;
        }
      }
    },
    // 订阅远端流--加入房间之前
    subscribeStream() {
      this.client.on("stream-added", (event) => {
        const remoteStream = event.stream;
        this.isHave = false;
        for (let [uid, stream] of this.members_) {
          if (stream.getUserId() === remoteStream.getUserId()) {
            $("#user_" + stream.getId()).remove();
            this.members_.delete(stream.getId());
            this.isHave = true;
          }
        }
        this.remoteStreamArr.push(remoteStream);
        this.members_.set(remoteStream.getId(), remoteStream);
        console.log("远端流增加: ", remoteStream.getUserId());
        // 订阅远端流
        this.client.subscribe(remoteStream);
      });
    },
    // 播放远端流
    playStream() {
      this.client.on("stream-subscribed", (event) => {
        const remoteStream = event.stream;
        console.log("远端流订阅成功：" + remoteStream.getId());
        // 创建远端流标签，因为id是动态的，所以动态创建，用了v-html

        const id = remoteStream.getId();
        this.nowId = id;
        this.remoteStream = `<div id=`+"remote_stream-" + id+  ` style="width:100%;height:100%"></div>`;
        if (!this.isHave) {
          this.list.push({
            id,
            userId: remoteStream.getUserId(),
            remoteStream: this.remoteStream,
            origin: remoteStream,
          });
        } else {
          this.list.splice(
            this.list.findIndex((v) => v.userId === remoteStream.getUserId()),
            1,
            {
              id,
              userId: remoteStream.getUserId(),
              remoteStream: this.remoteStream,
              origin: remoteStream,
            }
          );
        }
        // 做了dom操作 需要使用$nextTick(),否则找不到创建的标签无法进行播放
        this.$nextTick(() => {
          // 播放
          remoteStream.play("remote_stream-" + id);
        });
      });
    },
    leaveRoom(client) {
      client
        .leave()
        .then(() => {
          console.log("退房成功");
          //       // 停止本地流，关闭本地流内部的音视频播放器
          this.localStream.stop();
          //       // 关闭本地流，释放摄像头和麦克风访问权限
          this.localStream.close();
          this.localStream = null;
          this.client = null;
          // 退房成功，可再次调用client.join重新进房开启新的通话。
        })
        .catch((error) => {
          console.error("退房失败 " + error);
          //       // 错误不可恢复，需要刷新页面。
        });
    },
    async closeRoom() {
      this.client.off("*");
      await this.client.unpublish(this.localStream);
      await this.client.leave();
      await getVideoLinkageOver({roomId:this.roomId}).then(res=>{
        })
      this.localStream.stop();
      this.localStream.close();
      this.localStream = null;
      // this.client = null;
      // this.isJoined_ = false;
      $("#local_stream").remove();
      console.log(2222222)
      this.$store.commit("index/set", { key: "roomId", val: false });
      
    },
    // 获取用户签名--前端测试用
    genTestUserSig(userID) {
      const SDKAPPID = 1400752022;
      const EXPIRETIME = 604800;
      const SECRETKEY =
      '92541ae995448d57f9067f8939234c16e9310c6e321615dfbb2785bb60201413';

      // a soft reminder to guide developer to configure sdkAppId/secretKey
      if (SDKAPPID === "" || SECRETKEY === "") {
        return false;
      }
      const generator = new LibGenerateTestUserSig(
        SDKAPPID,
        SECRETKEY,
        EXPIRETIME
      );
      const userSig = generator.genTestUserSig(userID);
      return {
        sdkAppId: SDKAPPID,
        userSig: userSig,
      };
    },
    // 关闭/打开摄像头
    muteLocalVideo() {
      if (this.isVedio) {
        this.isVedio = false;
        this.localStream.muteVideo();
      } else {
        this.isVedio = true;
        this.localStream.unmuteVideo();
      }
    },
    // 关闭/打开麦克风
    muteLocalAudio() {
      if (this.isAudio) {
        this.isAudio = false;
        this.localStream.muteAudio();
      } else {
        this.isAudio = true;
        this.localStream.unmuteAudio();
      }
    },
    quitAllMember() {
      this.remoteStreamArr.forEach((element) => {
        // console.log(element);
        element.stop();
        element.close();
      });
    },
    closeAddPersonRes(arr = []) {
      this.isAddPerson = false;
    },
  },
};
</script>

<style scoped lang="scss">
* {
  font-size: 0.16rem;
}

.jieshu {
  position: absolute;
  right: 30px;
  bottom: 80px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0px 5px 20px 0px rgba(249, 61, 59, 0.5),
    0px 0px 10px 0px rgba(249, 60, 59, 0.5);
  z-index: 2;
  cursor: pointer;
}

.bottom_title {
  background: rgba(0, 0, 0, 0.75);
  padding: 15px 22px;
  box-sizing: border-box;
  color: rgba(255, 117, 33, 1);
  font-size: 18px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;

  .users_datas {
    position: absolute;
    left: 0;
    bottom: 60px;
    display: flex;
    flex-wrap: wrap;

    >div {
      width: 100px;
      height: 30px;
      background: rgba(13, 15, 22, 0.8);
      border-radius: 15px;
      text-align: center;
      color: rgba(128, 147, 163, 1);
      margin: 12px 0 12px 25px;
      line-height: 30px;
      position: relative;
      font-size: 16px;
      cursor: pointer;
      box-sizing: border-box;
      border: 1px solid rgba(128, 137, 163, 0.1);
      padding-left: 20px;

      >img {
        width: 40px;
        height: 40px;
        position: absolute;
        left: -10px;
        top: -6px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .show_true {
      border: 1px solid rgba(40, 200, 255, 0.5);
      color: rgba(40, 200, 255, 1);
    }

    .show_false {
      color: rgba(40, 200, 255, 1);
      cursor: initial;
    }

    .clearUser {
      position: absolute;
      right: -5px;
      left: auto;
      top: -5px;
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
  }
}

.content {
  width: 100%;
  height: 100%;
  background: rgba(11, 11, 28, 0.95);
  display: flex;
  padding: 2px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  .left1 {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: auto;
    background: #3d3b3b;
    align-content: flex-start;
    margin-bottom: 10px;
    flex-wrap: wrap;
    justify-content: space-between;

    // -------------------------------------------------------------
    .user {
      >div {
        width: 100% !important;
        height: 100% !important;
      }
    }

    .user0 {
      width: 100% !important;
      height: 100% !important;
    }

    .user1 {
      width: calc(50% - 5px) !important;
      height: 100% !important;
    }

    .user2 {
      width: calc(50% - 5px) !important;
      height: calc(50% - 5px) !important;
      flex-grow: 1;
    }

    .user3 {
      width: calc(50% - 5px) !important;
      height: calc(50% - 5px) !important;
    }

    .user4 {
      width: calc(100% / 3 - 5px) !important;
      height: calc(50% - 5px) !important;
      flex-grow: 1;
    }

    .user5 {
      width: calc(100% / 3 - 5px) !important;
      height: calc(50% - 5px) !important;
    }

    .user6 {
      width: calc(100% / 3 - 5px) !important;
      height: calc(100% / 3 - 5px) !important;
      flex-grow: 1;
    }

    .user7 {
      width: calc(100% / 3 - 5px) !important;
      height: calc(100% / 3 - 5px) !important;
      flex-grow: 1;
    }

    .user8 {
      width: calc(100% / 3 - 5px) !important;
      height: calc(100% / 3 - 5px) !important;
      flex-grow: 1;
    }

    //-----------------------------------------------------
    // justify-content: center;
    .userlist {
      border-radius: 1px;
      margin-top: 10px;
      border: 1px solid #2a2e32;
      position: static;
      display: flex;
      flex-direction: column;
      margin-top: auto;
      // background: url(../../static/dy/lyf.jpg) no-repeat;
      background: rgba(255, 255, 255, 0.3);
      background-position: center 0px;
      background-size: 100%;
      margin-top: 0.1rem;

      &:nth-child(2n) {
        margin-left: 6px;
      }

      p {
        color: #fff;
        font-size: 14px;
        text-align: center;
      }

      .img {
        width: 100%;
        height: 130px;
        background: #fff;
      }
    }
  }

  .right1 {
    // width: calc(~'1920px - 210px');
    flex-grow: 1;
    height: calc(0.93 * 1080px);
    background-color: #3d3b3b;
    border-radius: 1px;
    margin: 0 10px 10px 10px;
    padding: 0 10px;
    margin-left: 4.55rem;

    .container {
      width: 100%;
      height: 820px;
      background: #191f24;

      video {
        height: 100% !important;
      }
    }

    .maskshow {
      display: block;
    }

    .operator {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      text-align: center;
      position: absolute;
      bottom: 0.5rem;
      left: 46%;
      z-index: 9;

      .item {
        display: flex;
        flex-direction: column;
        width: 80px;

        &:nth-child(1) .img-warp .img {
          width: 23px;
        }

        &:nth-child(2) .img-warp .img {
          width: 40px;
        }

        &:nth-child(3) .img-warp {
          background: #e63517;

          .img {
            width: 44px;
          }
        }

        .img-warp {
          width: 100%;
          height: 45px;
          text-align: center;
          line-height: 45px;
          background: #e3e3e3;
          border-radius: 10px;
          position: relative;

          .img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &:not(:first-child) {
          margin-left: 20px;
        }

        .item-icon {
          width: 80px;
          height: 45px;
          line-height: 45px;
          background-color: #e3e3e3;
          border-radius: 10px;
        }

        span {
          font-size: 16px;
          text-align: center;
          color: #fff;
          margin-top: 10px;
        }
      }
    }
  }
}

.distant-stream {
  width: 100%;
  height: 100%;
  // flex-grow: 1;
}

.footer {
  display: flex;
  justify-content: center;
  background: #fff;

  .btn {
    width: 140px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 4px;
    border: solid 1px #e3e3e3;
    line-height: 40px;
    text-align: center;
    justify-content: center;
    user-select: none;
    cursor: pointer;

    &:not(:first-child) {
      margin-left: 20px;
      background: #1e8ef4;
      color: #fff;
    }

    &:nth-child(2) {
      background: #e63517;
      color: #fff;
    }

    &:last-child {
      border: solid 1px #e63517;
      background: #fff;
      color: #e63517;
    }
  }
}

#local_stream {
  // flex-grow: 1;
  width: 100%;
  height: 100%;
}

.info {
  width: 100%;
  height: 20%;
  line-height: 35px;
  color: rgb(255, 255, 255);
  background: rgb(60, 61, 64);
  position: absolute;
  bottom: 0px;
  display: flex;
  align-items: center;
  z-index: 99;
  justify-content: space-between;
}

.mainVedio {
  // position: absolute;
  // width: 12rem !important;
  // left: 4.6rem;
  // top: 3vh;
}

#warp,
#user-warp {
  display: flex;
  flex-direction: column;
}

.maskable {
  position: absolute;
  width: calc(100% - 410px);
  right: 1.26rem;
  height: 80%;
  color: #fff;
  text-align: center;
  line-height: 900px;
  background: url(../../../static/images/08摄像头已关闭@2x.png) center no-repeat;
}

// ------------------------------------------
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

button {
  margin: 0 !important;
  color: #fff !important;
  background-color: #006eff !important;
}

// div {
//   display: flex;
//   align-items: center;
// }

.row-div {
  flex-direction: row;
}

.col-div {
  flex-direction: column;
}

#root {
  width: 100%;
  height: 100%;
  background-color: rgb(250, 250, 250);
  position: absolute;
  display: block;
}

#login-root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#login-card {
  width: 450px;
  height: 500px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  background-color: white;
}

.login-card {
  width: 360px;
  height: 450px;
  background-color: white;
  margin: 0 auto;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.popover {
  min-width: 300px;
  max-width: 1000px;
  border: 0;
  white-space: nowrap;
  /* overflow: hidden; */
}

.popover-body {
  flex-direction: column;
  padding: 0;
}

.popover-body>div {
  width: 100%;
  height: 35px;
  justify-content: center;
  cursor: default;
}

.popover-body>div:hover {
  background-color: #f7f7f7;
}

.icon-gray {
  color: #bfbfbf;
}

.icon-normal {
  color: #515151;
}

.icon-blue {
  color: #006eff;
}

.device-testing-btn {
  color: #515151;
  cursor: pointer;
  margin-top: -14px;
  opacity: 0.8;
}

.device-connect-list {
  width: 310px;
  height: 70px;
  position: absolute;
  bottom: 50px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  justify-content: space-around;
  padding: 0 10px;
}

.device-connect-list::before {
  content: "";
  width: 0;
  height: 0;
  border: 8px transparent solid;
  border-top-color: rgba(0, 0, 0, 0.2);
  opacity: 0.6;
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
}

.device-connect-list::after {
  content: "";
  width: 0;
  height: 0;
  border: 7px transparent solid;
  border-top-color: #fff;
  position: absolute;
  bottom: -14px;
  left: 50%;
  transform: translateX(-50%);
}

.connect {
  width: 28px;
  height: 64px;
  font-size: 28px;
  text-align: center;
  position: relative;
  opacity: 0.8;
}

.device-icon {
  width: 20px;
  height: 20px;
  margin-right: 3px;
  display: block;
  text-align: center;
  font-size: 20px;
  line-height: 20px;
}

#device-testing-root {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-self: center;
  background: rgba(0, 0, 0, 0.1);
}

.device-testing-card {
  width: 640px;
  height: 480px;
  background: #f7f7f7;
  border-radius: 10px;
  position: relative;
  display: block;
}

.device-testing-prepare,
.device-testing,
.device-testing-report {
  display: block;
}

.testing-title {
  font-size: 34px;
  justify-content: center;
  margin-top: 55px;
  color: #201e1ee5;
}

.testing-prepare-info {
  font-size: 16px;
  justify-content: center;
  margin-top: 25px;
  color: #585656e5;
}

.device-testing-close-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  font-size: 20px;
}

.device-display {
  margin-top: 40px;
  justify-content: center;
}

.device {
  width: 46px;
  height: 46px;
  position: relative;
  justify-content: center;
  font-size: 38px;
}

.device:not(:first-child) {
  margin-left: 60px;
}

.device:not(:first-child).safari {
  margin-left: 100px;
}

.device::before {
  content: "";
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: -34px;
  left: 50%;
  transform: translateX(-50%);
}

.connect-success::before {
  background: url("../../assets/dy/success.png") no-repeat;
  background-size: 100% 100%;
}

.connect-fail::before {
  background: url("../../assets/dy/fail.png") no-repeat;
  background-size: 100% 100%;
}

@keyframes device-loading {
  0% {
    width: 0%;
    border-radius: 6px 0 0 6px;
  }

  50% {
    width: 50%;
    border-radius: 6px 0 0 6px;
  }

  100% {
    width: 100%;
    border-radius: 6px;
  }
}

.loading-background {
  width: 350px;
  height: 3px;
  border-radius: 6px;
  margin: 20px auto 0;
  background: #bfbfbf;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.device-loading {
  height: 3px;
  background-color: #808080;
  animation: device-loading 2s;
  animation-fill-mode: both;
}

.connect-info {
  margin-top: 60px;
  display: flex;
  height: 48px;
  justify-content: center;
}

.connect-attention-container {
  position: relative;
  margin-left: 3px;
}

.connect-attention-icon {
  font-size: 20px;
  color: red;
}

.connect-attention-info {
  padding: 8px 12px;
  min-width: 120px;
  min-height: 50px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  color: #fff;
  position: absolute;
  right: 0;
  bottom: 100%;
  transform: translate(20px, -10px);
  display: block;
  white-space: nowrap;
  font-size: 12px;
}

.connect-attention-info::after {
  content: "";
  width: 0;
  height: 0;
  border: 10px transparent solid;
  border-top-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 100%;
  top: 100%;
  transform: translateX(-40px);
}

.testing-btn-display {
  justify-content: center;
  margin-top: 30px;
}

.test-btn {
  width: 200px;
  height: 44px;
  background: #006eff;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  justify-content: center;
  cursor: pointer;
}

.start-gray {
  background: #dddddd;
  color: #fff;
  cursor: not-allowed;
}

.device-testing-title {
  justify-content: center;
  width: 100%;
  margin-top: 40px;
}

.testing {
  width: 26px;
  height: 26px;
  position: relative;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  line-height: 24px;
}

.testing:not(:first-child) {
  margin-left: 90px;
}

.testing:not(:first-child)::before {
  content: "";
  width: 70px;
  height: 2px;
  position: absolute;
  top: 50%;
  left: -80px;
  background: #bfbfbf;
}

.testing:not(:first-child).safari {
  margin-left: 150px;
}

.testing:not(:first-child).safari::before {
  width: 130px;
  left: -140px;
}

.testing.complete {
  cursor: pointer;
}

.testing.complete:not(:first-child)::before {
  background: #006eff;
}

.testing-body {
  width: 100%;
  display: block;
}

.device-list {
  margin-left: 140px;
  margin-top: 30px;
}

.device-select {
  width: 260px;
  height: 30px;
  margin-left: 20px;
  padding: 0 10px;
  border-radius: 4px;
}

.camera-video {
  width: 300px;
  height: 180px;
  display: block;
  margin: 30px auto 0;
}

.testing-info-container {
  display: block;
  position: absolute;
  bottom: 50px;
  margin-top: 24px;
  left: 50%;
  transform: translateX(-50%);
}

.testing-info {
  width: 100%;
  text-align: center;
  display: block;
}

.button-list {
  margin-top: 20px;
  width: 300px;
  justify-content: space-around;
}

.fail-button {
  border: 1px solid #006eff;
  border-radius: 8px;
  color: #006eff;
  padding: 6px 14px;
  cursor: pointer;
}

.success-button {
  border: 1px solid #006eff;
  border-radius: 8px;
  background: #006eff;
  color: #fff;
  padding: 6px 14px;
  cursor: pointer;
}

.audio-control {
  width: 320px;
  display: block;
  margin: 40px auto 0;
}

.audio-control-info {
  margin: 0px auto 20px 6px;
  color: #5f5f5f;
}

.mic-testing-container {
  display: block;
  margin-top: 30px;
}

.mic-testing-info {
  margin-left: 140px;
  color: #bbbbbb;
  font-size: 14px;
}

.mic-bar-container {
  justify-content: center;
  margin-top: 10px;
}

.mic-bar {
  width: 10px;
  height: 30px;
  border: 1px solid #cccccc;
  border-radius: 1px;
}

.mic-bar:not(:first-child) {
  margin-left: 3px;
}

.mic-bar.active {
  background: #006eff;
}

.testing-index-list {
  margin-top: 40px;
  display: block;
}

.testing-index-group {
  width: 55%;
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
  margin: 10px auto 0;
}

@keyframes loading-circle {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.network-loading::before {
  content: "";
  width: 16px;
  height: 16px;
  background: url("../../assets/dy/loading.png");
  background-size: 100% 100%;
  animation: loading-circle 2s linear infinite;
}

.testing-footer {
  margin-top: 70px;
  justify-content: center;
}

.device-report-list {
  display: block;
  margin-top: 40px;
}

.device-report {
  width: 60%;
  margin: 20px auto 0;
  justify-content: space-between;
}

.report-icon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
  justify-content: center;
  font-size: 22px;
  line-height: 22px;
  color: #515151;
}

.device-name {
  width: 280px;
  height: 24px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.device-report-footer {
  margin-top: 50px;
  justify-content: center;
}

.device-report-btn {
  width: 160px;
  height: 40px;
  border: 1px solid;
  border-radius: 6px;
  justify-content: center;
  cursor: pointer;
}

.testing-agin {
  border-color: #006eff;
  color: #006eff;
}

.testing-finish {
  background: #006eff;
  color: #fff;
  margin-left: 60px;
}

video {
  background-color: #d8d8d8;
}

/* @media screen and (min-width:960px) {
    video{
        width: auto !important;
        height: 100% !important;
        position: inherit !important;
    }
    .video-div{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@media screen and (max-width:960px) {
    video{
        width: 100% !important;
        height: auto !important;
        position: inherit !important;
    }
    .video-div{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
} */

#room-root {
  width: 100%;
  height: 100%;
  min-width: 1500px;
  min-height: 700px;
  display: none;
  align-items: flex-start;
  background-color: #f0f0f0;
}

.member {
  cursor: default;
  border-bottom-style: solid;
  border-width: 1px;
  border-bottom-color: #f0f0f0;
}

#video-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas: "m m m a b" "m m m c d" "e f g h i";
}

#video-grid>div {
  position: relative;
  padding: 10px;
}

#main-video {
  width: 100%;
  height: 100%;
  padding: 10px;
  grid-area: 1/1/3/4;
}

.video-box {
  width: 100%;
  height: 100%;
}

.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 14px;
  color: #888888;
  z-index: 9;
  justify-content: center;
}

.job1 {
  font-size: 0.18rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00c5ff;
}

div[id^="player"] {
  background-color: #d8d8d8 !important;
}

.huiyi {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
</style>
